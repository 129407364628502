import { Typography } from '@material-ui/core';
import {
  AllInbox as OrdersIcon,
  AssessmentOutlined as ReportsIcon,
  Business as CustomersIcon,
  DashboardRounded as DashboardIcon,
  LocationOn as TerminalsIcon,
  Notifications,
  OpenInNew,
} from '@material-ui/icons';
import { Color, Inline } from '@superdispatch/ui';
import { NavbarItemOptions } from '@superdispatch/ui-lab';
import { parseURITemplate } from '@superdispatch/uri';
import { Location } from 'history';
import { snakeCase } from 'lodash-es';
import { HTMLAttributes, ReactNode, useMemo } from 'react';
import { NumericFormat } from 'react-number-format';
import { useLocation } from 'react-router';
import { Link, matchPath, NavLinkProps, useHistory } from 'react-router-dom';
import { ManageCarriersIcon } from 'shared/icons/ManageCarriersIcon';
import { PaymentIcon } from 'shared/icons/PaymentIcon';
import { useOrderCounts } from '../api/OrderCountAPI';
import { PRICING_INSIGHTS_URL } from '../config/Constants';
import { useUserState } from '../data/AppUserState';
import { useUnreadNotifications } from '../data/notifications/NotificationAPI';
import { useProductTiers } from '../data/TiersUtils';
import { canOrderSubmitAllowed, usePermission } from '../data/UserPermissions';
import { trackEvent, trackEventLegacy } from '../helpers/AnalyticsHelpers';
import { PricingInsightsIcon } from '../icons/PricingInsightsIcon';
import { setCurrentTab } from './AppNavbar';
import { NavbarNotificationItem } from './NavbarNotificationItem';

function extractPath(link: NavLinkProps['to'], location: Location) {
  switch (typeof link) {
    case 'string':
      return link;
    case 'object':
      return link.pathname;
    case 'function': {
      const descriptor = link(location);
      return typeof descriptor === 'string' ? descriptor : descriptor.pathname;
    }
  }
}
interface NavbarLinkItem extends Omit<NavbarItemOptions, 'label'> {
  label: string;
  link: NavLinkProps['to'];
}

interface NavbarAnchorItem extends Omit<NavbarItemOptions, 'label'> {
  key: string;
  label: ReactNode;
  href: NavLinkProps['href'];
  target?: string;
}

export const useNavbarItems = () => {
  const { user } = useUserState();
  const { data: counts, refetch: refetchCounts } = useOrderCounts();
  const history = useHistory();
  const { data: { objects: notifications = [] } = {} } =
    useUnreadNotifications();

  const shipperType = user?.shipper.shipper_type;
  const isOrderSubmitAllowed = canOrderSubmitAllowed(user);

  const isDeliveryVerifiedTabEnabled =
    user?.shipper.super_pay_settings?.superpay_flow === 'MANUAL';

  const canGenerateReport = usePermission('REPORT_GENERATION', 'canExecute');
  const location = useLocation();

  const showSuperpayNavbar =
    shipperType === 'BROKER' && user?.shipper.had_superpay_access;

  const { isBasicTier } = useProductTiers();

  return useMemo<NavbarItemOptions[]>(() => {
    function makeOrderItem(
      route: {
        label: string;
        status: string;
      } & Pick<NavbarItemOptions, 'hide' | 'ident' | 'variant'>,
    ): NavbarItemOptions {
      const count =
        route.status === 'all' ? counts?.total : counts?.[route.status];

      return makeLinkItem({
        ...route,
        onClick() {
          refetchCounts();
        },
        link: ({ search, pathname }) => {
          const params = new URLSearchParams(search);

          // Remove intercom params between pages
          if (params.has('product_tour_id')) {
            params.delete('product_tour_id');
          }

          return {
            pathname:
              route.status === 'all' ? '/orders' : `/orders/${route.status}`,
            search: pathname.startsWith('/orders') ? params.toString() : '',
          };
        },
        badge:
          count === undefined ||
          (count === 0 && route.status === 'order_canceled') ? null : (
            <NumericFormat
              displayType="text"
              thousandSeparator={true}
              value={count}
            />
          ),
      });
    }

    function makeLinkItem({
      link,
      label,
      onClick,
      ...rest
    }: Omit<NavbarLinkItem, 'key'>): NavbarItemOptions {
      const match = matchPath(location.pathname, {
        path: extractPath(link, location),
        exact: true,
        strict: true,
      });
      return {
        ...rest,
        label,
        active: !!match,
        key: label,
        onClick: (event) => {
          onClick?.(event);
          trackEventLegacy('Viewed Tab', { tab: label });
          setCurrentTab(label);
        },
        component: function NavbarComponent(
          props: HTMLAttributes<HTMLElement>,
        ) {
          return (
            <Link
              aria-label={label}
              {...props}
              to={link}
              data-cy={`${snakeCase(label)}_sidebar_item`}
            />
          );
        },
      };
    }

    function makeAnchorItem({
      key,
      label,
      href,
      target,
      onClick,
      ...rest
    }: NavbarAnchorItem): NavbarItemOptions {
      return {
        ...rest,
        label,
        key,
        onClick,
        component: function NavbarComponent(
          props: HTMLAttributes<HTMLElement>,
        ) {
          return <a {...props} href={href} target={target} />;
        },
      };
    }
    const brokerOrderRoutes: NavbarItemOptions[] = [
      makeOrderItem({ label: 'All', status: 'all' }),
      makeOrderItem({ label: 'New', status: 'new' }),
      makeOrderItem({
        label: 'On Hold',
        status: 'on_hold',
        hide: isBasicTier,
      }),
      makeOrderItem({ label: 'Posted to Loadboards', status: 'posted_to_lb' }),
      makeOrderItem({
        ident: 1,
        label: 'Requests',
        status: 'requests',
        variant: counts?.has_new_load_request ? 'primary' : undefined,
      }),
      makeOrderItem({ label: 'Pending', status: 'pending' }),
      makeOrderItem({
        label: 'Declined',
        status: 'declined',
        variant: counts?.declined ? 'danger' : undefined,
      }),
      makeOrderItem({ label: 'Accepted', status: 'accepted' }),
      makeOrderItem({ label: 'Picked up', status: 'picked_up' }),
      makeOrderItem({ label: 'Delivered', status: 'delivered' }),
      makeOrderItem({ label: 'Invoiced', status: 'invoiced' }),
      makeOrderItem({
        label: 'Delivery Verified',
        status: 'delivery_verified',
        hide: !isDeliveryVerifiedTabEnabled,
      }),
      makeOrderItem({ label: 'Paid', status: 'paid' }),
      makeOrderItem({ label: 'Flagged', status: 'flagged', hide: isBasicTier }),
      makeOrderItem({
        label: 'Canceled',
        status: 'order_canceled',
        variant: counts?.order_canceled ? 'danger' : undefined,
      }),
      makeOrderItem({ label: 'Archived', status: 'archived' }),
      makeOrderItem({ label: 'Deleted', status: 'inactive' }),
    ];

    const brokerRoutes: NavbarItemOptions[] = [
      makeLinkItem({
        groupKey: 'carrier',
        label: 'Manage Carriers',
        icon: <ManageCarriersIcon />,
        link: ({ search, pathname }) => ({
          pathname: '/manage-carriers',
          search: pathname.startsWith('/manage-carriers') ? search : '',
        }),
        hide: isBasicTier,
      }),
      makeLinkItem({
        groupKey: 'counterparty',
        link: '/customers',
        icon: <CustomersIcon />,
        label: 'Customers',
        hide: isBasicTier,
      }),
      makeLinkItem({
        groupKey: 'counterparty',
        link: '/terminals',
        icon: <TerminalsIcon />,
        label: 'Terminals',
      }),
      makeLinkItem({
        groupKey: 'stats',
        link: '/dashboard',
        icon: <DashboardIcon />,
        label: 'Dashboard',
        hide: isBasicTier,
      }),
      makeLinkItem({
        groupKey: 'stats',
        link: '/reports',
        icon: <ReportsIcon />,
        label: 'Reports',
        hide: !canGenerateReport || isBasicTier,
      }),
    ];

    const superpayOrderRoutes: NavbarItemOptions[] = [
      makeOrderItem({
        label: 'Delivery Verified',
        status: 'superpay_delivery_verified',
        hide: !isDeliveryVerifiedTabEnabled,
      }),
      makeOrderItem({
        label: 'Charge Scheduled',
        status: 'superpay_charge_scheduled',
      }),
      makeOrderItem({ label: 'SuperPay Charged', status: 'superpay_charged' }),
      makeOrderItem({ label: 'Payment on Hold', status: 'superpay_on_hold' }),
      makeOrderItem({
        label: 'Payment Initiated',
        status: 'superpay_initiated',
      }),
      makeOrderItem({
        label: 'Payment Failed',
        status: 'superpay_failed',
        variant: counts?.superpay_failed ? 'danger' : undefined,
      }),
      makeOrderItem({ label: 'Paid', status: 'superpay_paid' }),
    ];

    const customerOrderRoutes: NavbarItemOptions[] = [
      makeOrderItem({ label: 'All', status: 'all' }),
      makeOrderItem({
        label: 'New',
        status: 'new',
        hide: !isOrderSubmitAllowed,
      }),
      makeOrderItem({ label: 'Submitted', status: 'submitted' }),
      makeOrderItem({ label: 'Scheduled', status: 'scheduled' }),
      makeOrderItem({ label: 'Picked up', status: 'picked_up' }),
      makeOrderItem({ label: 'Delivered', status: 'delivered' }),
      makeOrderItem({ label: 'Completed', status: 'completed' }),
      makeOrderItem({
        label: 'Canceled',
        status: 'order_canceled',
      }),
      makeOrderItem({
        label: 'Deleted',
        status: 'inactive',
        hide: !isOrderSubmitAllowed,
      }),
    ];
    const customerRoutes: NavbarItemOptions[] = [
      makeLinkItem({
        link: '/terminals',
        icon: <TerminalsIcon />,
        label: 'Terminals',
        hide: !isOrderSubmitAllowed,
      }),
      makeLinkItem({
        link: '/reports',
        label: 'Reports',
        icon: <ReportsIcon />,
        hide: !canGenerateReport,
      }),
    ];
    const orderRoutes =
      shipperType === 'BROKER'
        ? brokerOrderRoutes
        : shipperType === 'CUSTOMER'
        ? customerOrderRoutes
        : [];

    const routes: NavbarItemOptions[] =
      shipperType === 'BROKER'
        ? brokerRoutes
        : shipperType === 'CUSTOMER'
        ? customerRoutes
        : [];

    return [
      {
        key: 'notifications',
        label: 'Notifications',
        component: NavbarNotificationItem,
        icon:
          notifications.length > 0 ? (
            <Notifications htmlColor="#ee3806" />
          ) : (
            <Notifications htmlColor={Color.Silver500} />
          ),
      },
      {
        key: 'Orders',
        icon: <OrdersIcon />,
        label: 'Orders',
        items: orderRoutes,
        onClick: () => {
          const hasActiveChildren = orderRoutes.some((item) => item.active);

          if (!hasActiveChildren) {
            history.push('/orders');
          }
        },
      },
      makeAnchorItem({
        key: 'Pricing Insights',
        groupKey: 'pricing-insights',
        href: parseURITemplate(
          `${PRICING_INSIGHTS_URL}{?utm_medium,utm_source}`,
          {
            utm_medium: 'Navigation Bar',
            utm_source: 'Web STMS',
          },
        ),
        icon: <PricingInsightsIcon />,
        label: (
          <Inline space="xxsmall" verticalAlign="center">
            <Typography>Pricing Insights</Typography>
            <OpenInNew fontSize="small" color="action" />
          </Inline>
        ),
        hide: shipperType === 'CUSTOMER',
        target: '_blank',
        onClick: () => {
          trackEvent('Shipper Clicked Pricing Insights link', {
            utm_medium: 'Navigation Bar',
            utm_source: 'Web STMS',
          });
        },
      }),

      showSuperpayNavbar && {
        key: 'SuperPay Payments',
        icon: <PaymentIcon />,
        label: 'SuperPay Payments',
        items: superpayOrderRoutes,
        onClick: () => {
          const hasActiveChildren = superpayOrderRoutes.some(
            (item) => item.active,
          );

          if (!hasActiveChildren) {
            history.push('/orders/superpay_charged');
          }
        },
      },
      ...routes,
    ].filter((s): s is NavbarItemOptions => Boolean(s));
  }, [
    counts,
    notifications,

    shipperType,
    canGenerateReport,
    isOrderSubmitAllowed,
    isDeliveryVerifiedTabEnabled,
    isBasicTier,
    showSuperpayNavbar,

    history,
    location,
  ]);
};
