import {
  Box,
  Card,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Popover,
  Typography,
} from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { Button, Color } from '@superdispatch/ui';
import { useNavbarContext } from '@superdispatch/ui-lab';
import { HTMLAttributes, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  useMarkAsAllReadMutation,
  useMarkAsReadMutation,
  useUnreadNotifications,
  useUnreadNotificationsCache,
} from 'shared/data/notifications/NotificationAPI';
import { useSnackbarNotification } from 'shared/data/notifications/SnackbarNotification';
import { isNotificationSupported } from 'shared/helpers/BrowserHelpers';
import notificationIcon from 'shared/icons/nonotifications.png';
import styled from 'styled-components';
import { useFeatureToggle } from '../data/FeatureToggle';

const SNACKBAR_NOTIFICATION_TYPES = new Set(['cd_import_completed']);

const StyledList = styled(List)`
  max-height: 400px;
  max-width: 295px;
  overflow: auto;
  background: inherit;
  padding-top: 8px;

  & > li {
    background: inherit;
  }
`;

const StyledListItem = styled(ListItem)`
  padding: 6px 16px;
`;

export function NavbarNotificationItem({
  onClick,
  ...props
}: HTMLAttributes<HTMLElement>) {
  const history = useHistory();
  const { setDrawerOpen } = useNavbarContext();
  const addSnackbarNotification = useSnackbarNotification();
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const { refetch, data: { objects: notifications = [] } = {} } =
    useUnreadNotifications();

  const { setQueryData: setUnreadNotificationsData } =
    useUnreadNotificationsCache();

  const hasNotifications = notifications.length > 0;
  const markAsRead = useMarkAsReadMutation({
    onSuccess() {
      void refetch();
    },
  });
  const markAskReadAll = useMarkAsAllReadMutation({
    onSuccess() {
      setUnreadNotificationsData((prev) => ({
        ...prev,
        objects: [],
      }));
    },
  });

  const hasSuperPayErrorNotification = useFeatureToggle(
    'superpay-error-notifications.access.ui',
  );

  function hideNotifications() {
    setAnchor(null);
  }

  useEffect(() => {
    // User should receive Push notification if permission is granted
    if (isNotificationSupported() && Notification.permission === 'granted') {
      return;
    }

    const notification = notifications.find((item) =>
      SNACKBAR_NOTIFICATION_TYPES.has(item.type),
    );
    if (!notification) {
      return;
    }

    const hideSnackbarNotification = addSnackbarNotification(
      notification.title,
      {
        actionText: 'Open',
        key: notification.guid,
        body: notification.body,
        onClose: () => {
          markAsRead.mutate(notification.guid);
        },
        onClick: () => {
          void markAsRead.mutateAsync(notification.guid).then(() => {
            hideSnackbarNotification();

            if (notification.order_guid) {
              history.push(`/orders/view/${notification.order_guid}`);
            } else {
              window.location.assign(notification.link);
            }
          });
        },
      },
    );
  }, [notifications, addSnackbarNotification]);

  return (
    <>
      <div
        {...props}
        onClick={(event) => {
          event.preventDefault();
          setAnchor(event.currentTarget);
          onClick?.(event);
        }}
      />

      <Popover
        open={!!anchor}
        anchorEl={anchor}
        onClose={hideNotifications}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{ 'aria-label': 'notifications' }}
      >
        <Card>
          {!hasNotifications ? (
            <Box p={2} textAlign="center">
              <img src={notificationIcon} alt="No Notifications" />

              <Typography variant="h4" color="textSecondary">
                No new notifications
              </Typography>
            </Box>
          ) : (
            <StyledList
              subheader={
                <ListSubheader>
                  <Box mr={-2} display="flex" justifyContent="space-between">
                    <Box mr={1} display="flex" alignItems="center">
                      <Typography variant="h5">NOTIFICATIONS</Typography>
                    </Box>

                    <Button
                      color="primary"
                      variant="text"
                      isLoading={markAskReadAll.isLoading}
                      onClick={() => markAskReadAll.mutate()}
                    >
                      Mark all as read
                    </Button>
                  </Box>
                </ListSubheader>
              }
            >
              {notifications.map((notification) => (
                <StyledListItem
                  key={notification.guid}
                  button={true}
                  disabled={markAsRead.isLoading}
                  onClick={() => {
                    markAsRead.mutate(notification.guid);

                    if (notification.order_guid) {
                      history.push(`/orders/view/${notification.order_guid}`);
                    } else {
                      window.location.assign(notification.link);
                    }

                    hideNotifications();
                    setDrawerOpen(false);
                  }}
                >
                  <ListItemText
                    primary={notification.title}
                    secondary={notification.body}
                  />
                  {hasSuperPayErrorNotification &&
                    notification.type === 'super_pay_charge_failed' && (
                      <ListItemSecondaryAction>
                        <Error htmlColor={Color.Red300} fontSize="small" />
                      </ListItemSecondaryAction>
                    )}
                </StyledListItem>
              ))}
            </StyledList>
          )}
        </Card>
      </Popover>
    </>
  );
}
