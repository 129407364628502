import { IconButton, Link, Typography } from '@material-ui/core';
import { Add, Check, Edit } from '@material-ui/icons';
import { CardButton, Column, Columns, Stack } from '@superdispatch/ui';
import {
  Box,
  DescriptionItem,
  LinkedText,
  TextBox,
} from '@superdispatch/ui-lab';
import { Route, useHistory } from 'react-router';
import { useTryBack } from 'shared/helpers/RouteHelpers';
import { MultilineText } from 'shared/ui/MultilineTextView';
import { ShipperProfileDTO } from '../data/ShipperProfileDTO';
import { SectionLayout } from '../SectionLayout';
import { CarrierRequirementsDetails } from './CarrierRequirementsDetails';
import { CargoInsuranceMinimumDetails } from './core/CargoInsuranceMinimumDetails';
import { CargoInsuranceMinimumTooltip } from './core/CargoInsuranceMinimumTooltip';
import { InsuranceRequirementsEditDrawer } from './InsuranceRequirementsEditDrawer';
import { OtherRequirementsEditDrawer } from './OtherRequirementsEditDrawer';

interface CarrierRequirementsSectionProps {
  profile: ShipperProfileDTO;
}

export function CarrierRequirementsSection({
  profile,
}: CarrierRequirementsSectionProps) {
  const history = useHistory();
  const { tryBack } = useTryBack();

  const openInsuranceDrawer = () =>
    history.push('/profile/carrier-requirements/insurance/edit');
  const openOtherDrawer = () =>
    history.push('/profile/carrier-requirements/other/edit');
  const closeDrawer = () => tryBack({ pathname: '/profile' });

  const {
    ach_payments,
    carrier_requirements,
    carrier_certificate_of_insurance,
    carrier_insurance_requirements,
  } = profile;

  return (
    <>
      <Route path="/profile/carrier-requirements/insurance/edit">
        {({ match }) => (
          <InsuranceRequirementsEditDrawer
            profile={profile}
            open={!!match}
            onClose={closeDrawer}
          />
        )}
      </Route>

      <Route path="/profile/carrier-requirements/other/edit">
        {({ match }) => (
          <OtherRequirementsEditDrawer
            profile={profile}
            open={!!match}
            onClose={closeDrawer}
          />
        )}
      </Route>

      <SectionLayout
        title="Carrier Requirements"
        subtitle={
          <>
            Add your requirements and view how they are displayed to carriers{' '}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.superdispatch.com/en/articles/5049511-how-carrier-requirements-are-displayed-to-carriers"
            >
              here
            </Link>
          </>
        }
      >
        <Stack>
          {(carrier_certificate_of_insurance ||
            (carrier_insurance_requirements &&
              carrier_insurance_requirements.length > 0)) && (
            <Columns>
              <Column width="fluid">
                <Typography variant="h4" color="textSecondary">
                  Insurance
                </Typography>
              </Column>
              <Column width="content">
                <IconButton
                  aria-label="edit insurance requirements"
                  size="small"
                  onClick={openInsuranceDrawer}
                >
                  <Edit />
                </IconButton>
              </Column>
            </Columns>
          )}

          <Stack space="small">
            {carrier_certificate_of_insurance ? (
              <DescriptionItem
                icon={<Check />}
                wrap={true}
                label={
                  <TextBox>
                    {carrier_certificate_of_insurance === 'CERTIFICATE_HOLDER'
                      ? 'List as Certificate Holder'
                      : 'List as Additionally Insured'}
                  </TextBox>
                }
              >
                <Box minWidth="300px">
                  <CarrierRequirementsDetails profile={profile} />
                </Box>
              </DescriptionItem>
            ) : (
              <CardButton
                size="small"
                startIcon={<Add />}
                onClick={openInsuranceDrawer}
              >
                Add Listing for Certificate of Insurance
              </CardButton>
            )}

            {carrier_insurance_requirements?.length ? (
              <Stack>
                <DescriptionItem
                  icon={<Check />}
                  label={
                    <CargoInsuranceMinimumTooltip
                      limits={carrier_insurance_requirements}
                    />
                  }
                />
                <DescriptionItem inset={true}>
                  <CargoInsuranceMinimumDetails
                    limits={carrier_insurance_requirements}
                  />
                </DescriptionItem>
              </Stack>
            ) : (
              <CardButton
                size="small"
                startIcon={<Add />}
                onClick={openInsuranceDrawer}
              >
                Add Cargo Insurance Minimum
              </CardButton>
            )}
          </Stack>
        </Stack>

        {!(ach_payments || carrier_requirements) ? (
          <CardButton
            size="small"
            startIcon={<Add />}
            hint="ACH payment and additional instructions"
            onClick={openOtherDrawer}
          >
            Add Other Requirements
          </CardButton>
        ) : (
          <Stack>
            <Columns>
              <Column width="fluid">
                <Typography variant="h4" color="textSecondary">
                  Other
                </Typography>
              </Column>
              <Column width="content">
                <IconButton
                  aria-label="edit other requirements"
                  size="small"
                  onClick={openOtherDrawer}
                >
                  <Edit />
                </IconButton>
              </Column>
            </Columns>
            <Stack space="small">
              {ach_payments && (
                <DescriptionItem icon={<Check />}>
                  ACH Payment Information
                </DescriptionItem>
              )}
              {carrier_requirements && (
                <Stack>
                  <Typography variant="h6" color="textSecondary">
                    ADDITIONAL INSTRUCTIONS
                  </Typography>
                  <MultilineText>
                    <LinkedText>{carrier_requirements}</LinkedText>
                  </MultilineText>
                </Stack>
              )}
            </Stack>
          </Stack>
        )}
      </SectionLayout>
    </>
  );
}
