import { MenuItem } from '@material-ui/core';
import { Button } from '@superdispatch/ui';
import { FunctionComponent, MouseEvent } from 'react';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';
import ButtonGroupDropdown from 'shared/ui/ButtonGroupDropdown';

const allowedStatuses: Array<Order['status']> = [
  'new',
  'pending',
  'canceled',
  'declined',
  'accepted',
  'delivered',
  'paid',
  'invoiced',
  'order_canceled',
];

type UnpostLoadboardTarget = 'cd' | 'sdlb' | 'private-loadboard' | 'all';

export function getAvailableUnpostTarget(
  orders: Order[],
): UnpostLoadboardTarget | undefined {
  const activeOrders = orders.filter(
    (order) => allowedStatuses.includes(order.status) && !order.is_archived,
  );

  const canUnpostFromCD = activeOrders.some(
    (order) => order.is_posted_to_centraldispatch,
  );
  const canUnpostFromSLB = activeOrders.some(
    (order) =>
      order.is_posted_to_loadboard && !order.is_posted_to_private_loadboard,
  );
  const canUnpostFromPrivateNetwork =
    activeOrders.length > 0 &&
    activeOrders.every((order) => order.is_posted_to_private_loadboard);

  return canUnpostFromCD && canUnpostFromSLB
    ? 'all'
    : canUnpostFromCD
    ? 'cd'
    : canUnpostFromPrivateNetwork
    ? 'private-loadboard'
    : canUnpostFromSLB
    ? 'sdlb'
    : undefined;
}

type UnpostFromLoadboardAction =
  | 'unpost_from_cd'
  | 'unpost_from_sdlb'
  | 'unpost_from_all'
  | 'unpost_from_private_loadboard';

interface UnpostProps {
  isLoading: boolean | undefined;
  target: UnpostLoadboardTarget;
  source: 'orders_list' | 'order_view_page' | 'orders_list_bulk';
  isMenuItem?: boolean;
  onClick: (action: UnpostFromLoadboardAction, event: MouseEvent) => void;
}

export const UnpostFromLoadboardButton: FunctionComponent<UnpostProps> = ({
  target,
  source,
  isMenuItem,
  onClick,
  isLoading,
}) => {
  function handleClick(action: UnpostFromLoadboardAction) {
    return (event: MouseEvent) => {
      onClick(action, event);
    };
  }

  const clicklAnalytics = (type?: 'cd' | 'sdlb' | 'private-loadboard') => {
    switch (type) {
      case 'sdlb':
        trackEventLegacy('Clicked Unpost from Super Loadboard', {
          utm_medium: source,
        });
        break;
      case 'private-loadboard':
        trackEventLegacy('Clicked Unpost from Privet Loadboard', {
          utm_medium: source,
        });
        break;
      case 'cd':
        trackEventLegacy('Clicked Unpost from Central Dispatch', {
          utm_medium: source,
        });
        break;
      default:
        trackEventLegacy('Clicked Unpost from All Load Boards', {
          utm_medium: source,
        });
    }
  };

  if (!isMenuItem) {
    if (target === 'all') {
      return (
        <ButtonGroupDropdown
          color="primary"
          variant="outlined"
          isLoading={isLoading}
          label="Unpost from All Load Boards"
          onClick={(event) => {
            handleClick('unpost_from_all')(event);
            clicklAnalytics();
          }}
        >
          <MenuItem
            onClick={(event) => {
              onClick('unpost_from_sdlb', event);
              clicklAnalytics('sdlb');
            }}
          >
            Unpost from SLB
          </MenuItem>

          <MenuItem
            onClick={(event) => {
              onClick('unpost_from_cd', event);
              clicklAnalytics('cd');
            }}
          >
            Unpost from CD
          </MenuItem>
        </ButtonGroupDropdown>
      );
    }
    return (
      <Button
        color="primary"
        variant="outlined"
        isLoading={isLoading}
        onClick={(event) => {
          handleClick(
            target === 'private-loadboard'
              ? 'unpost_from_private_loadboard'
              : target === 'sdlb'
              ? 'unpost_from_sdlb'
              : 'unpost_from_cd',
          )(event);
          clicklAnalytics(target);
        }}
      >
        {target === 'private-loadboard'
          ? 'Unpost from Private Loadboard'
          : target === 'sdlb'
          ? 'Unpost from SLB'
          : 'Unpost from CD'}
      </Button>
    );
  }

  if (target === 'all') {
    return (
      <>
        <MenuItem
          key="unpost_from_all"
          disabled={isLoading}
          onClick={(event) => {
            handleClick('unpost_from_all')(event);
            clicklAnalytics();
          }}
        >
          Unpost from All Load Boards
        </MenuItem>

        <MenuItem
          key="unpost_from_sdlb"
          onClick={(event) => {
            onClick('unpost_from_sdlb', event);
            clicklAnalytics('sdlb');
          }}
        >
          Unpost from SLB
        </MenuItem>

        <MenuItem
          key="unpost_from_cd"
          onClick={(event) => {
            onClick('unpost_from_cd', event);
            clicklAnalytics('cd');
          }}
        >
          Unpost from CD
        </MenuItem>
      </>
    );
  }

  return (
    <MenuItem
      disabled={isLoading}
      onClick={(event) => {
        handleClick(
          target === 'private-loadboard'
            ? 'unpost_from_private_loadboard'
            : target === 'sdlb'
            ? 'unpost_from_sdlb'
            : 'unpost_from_cd',
        )(event);
        clicklAnalytics(target);
      }}
    >
      {target === 'private-loadboard'
        ? 'Unpost from Private Loadboard'
        : target === 'sdlb'
        ? 'Unpost from SLB'
        : 'Unpost from CD'}
    </MenuItem>
  );
};
