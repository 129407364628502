import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useAPI } from 'shared/api/API';
import { findAPIListQueryItem, useAPIListQuery } from 'shared/api/APIListQuery';
import { useAPIQuery } from 'shared/api/APIQuery';
import { terminalDTO, TerminalDTO } from 'shared/dto/TerminalDTO';

export function useTerminalsCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function invalidateTerminals() {
      void queryClient.invalidateQueries('terminals');
    }

    function findTerminal(guid: string) {
      return findAPIListQueryItem<TerminalDTO>(
        ['terminals', 'list'],
        queryClient,
        (item) => item.guid === guid,
      );
    }

    function replaceTerminal(terminal: TerminalDTO) {
      queryClient.setQueryData(
        ['terminals', 'item', { guid: terminal.guid }],
        terminal,
      );
    }

    return {
      findTerminal,
      invalidateTerminals,
      replaceTerminal,
    };
  }, [queryClient]);
}

interface TerminalParams {
  id?: number | null;
  guid?: string | null;
}

export function useTerminal({ id, guid }: TerminalParams) {
  const { requestResource } = useAPI();
  const { findTerminal, replaceTerminal } = useTerminalsCache();

  return useAPIQuery(
    ['terminals', 'item', { id, guid }],
    () => {
      if (guid) {
        return requestResource(
          '/internal/terminals/guid/{guid}',
          (data) => terminalDTO.cast(data),
          { guid },
        );
      }

      return requestResource(
        '/internal/terminals/{id}',
        (data) => terminalDTO.cast(data),
        { id },
      ).then((response) => {
        replaceTerminal(response);
        return response;
      });
    },
    {
      enabled: !!guid || !!id,
      initialData: () => {
        if (guid) {
          return findTerminal(guid);
        }

        return undefined;
      },
      // Terminals list doesn't have `notes` field
      // Need to refetch each terminal to get it
      initialDataUpdatedAt: 0,
    },
  );
}

export function useTerminalsListQuery(query: string | null) {
  const { requestPage } = useAPI();
  return useAPIListQuery(
    ['terminals', 'list', { query }],
    (page) =>
      requestPage(
        '/internal/terminals{?page,size,query}',
        (data) => terminalDTO.cast(data),
        {
          page,
          size: 20,
          query: query && query.length > 2 ? query : undefined,
        },
      ),
    {},
  );
}

export function useTerminalsAPI() {
  const cache = useTerminalsCache();
  const { request, requestResource } = useAPI();

  return useMemo(
    () => ({
      createTerminal(payload: TerminalDTO) {
        return requestResource(
          'POST /internal/terminals',
          (data) => terminalDTO.cast(data),
          { json: payload },
        ).then((response) => {
          cache.replaceTerminal(response);
          cache.invalidateTerminals();
          return response;
        });
      },
      updateTerminal(terminal: TerminalDTO) {
        return requestResource(
          'PUT /internal/terminals/{id}',
          (data) => terminalDTO.cast(data),
          { id: terminal.id, json: terminal },
        ).then((response) => {
          cache.replaceTerminal(response);
          cache.invalidateTerminals();
          return response;
        });
      },
      deleteTerminal: (id: number) =>
        request('DELETE /internal/terminals/{id}', { id }).then((response) => {
          cache.invalidateTerminals();
          return response;
        }),
      deleteTerminals: (ids: number[]) =>
        request('DELETE /internal/terminals/bulk_delete', { json: ids }).then(
          (response) => {
            cache.invalidateTerminals();
            return response;
          },
        ),
      importTerminals: (file: File) => {
        const body = new FormData();

        body.append('file', file);

        return request('POST /internal/terminals/import', { body }).then(
          (response) => {
            cache.invalidateTerminals();
            return response;
          },
        );
      },
    }),
    [cache, request, requestResource],
  );
}
