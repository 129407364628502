import { Grid, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { ChevronLeft, Create } from '@material-ui/icons';
import {
  Button,
  Color,
  Inline,
  Stack,
  Tag,
  useResponsiveValue,
  useSnackbarStack,
} from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { isSuperpayPayment } from 'core/SuperPayUtils';
import { isPriceNegotiationAvailable } from 'orders/data/dto/PriceNegotiationDTO';
import * as React from 'react';
import {
  FunctionComponent,
  MouseEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { useProductTiers } from 'shared/data/TiersUtils';
import { Protect, usePermission } from 'shared/data/UserPermissions';
import { OrderDTO } from 'shared/dto/Order/OrderDTO';
import { trackEvent, trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { SourceManager } from 'shared/helpers/SourceManager';
import { Order } from 'shared/types/order';
import ButtonGroupDropdown from 'shared/ui/ButtonGroupDropdown';
import styled from 'styled-components';
import { useFeatureToggle } from '../../../shared/data/FeatureToggle';
import { useCanInstantDispatch } from '../../data/InstantDispatchAPI';
import { useOrderPaymentFlags } from '../../data/OrderPaymentFlagsAPI';
import { PricingInsightsButtonWithDrawer } from '../pricing-insights/PricingInsightsButtonWithDrawer';
import { PricingInsightsDrawer } from '../pricing-insights/PricingInsightsDrawer';
import { useAccessRestrictedState } from './AccessRestrictedDialog';
import {
  isPopoverAction,
  OrderActionType,
  useOrderActionsContext,
} from './OrderActionsContext';
import {
  ActionDivider,
  BOLSyncText,
  getBOLUrl,
  getCustomerInvoiceUrl,
  getInvoiceUrl,
  getOnlineBOLUrl,
  isBOLSyncing,
  isDeliveryReceiptUrlSyncing,
  isNewOrderWithLoads,
  isPrimaryAction,
  showBuildLoads,
  showCancelOffer,
  showCancelOrderAction,
  showEditAction,
  showMarkAsAccepted,
  showMarkAsCarrierInvoiced,
  showMarkAsNew,
  showMarkAsOnHold,
  showMarkAsPending,
  showOnlineBOL,
  showRemoveOrder,
  showRollbackAction,
  showSendOffer,
  showSplitOrderAction,
  showSplitOrderIntoLoadsAction,
  showSubmitToBroker,
  showUnmarkAsCarrierInvoiced,
} from './OrderActionUtils';
import {
  getAvailablePostTarget,
  PostLoadboardButton,
} from './PostLoadboardButton';
import {
  SuperPaySingleNowGroupButton,
  SuperPaySingleNowMenuButton,
} from './superpay-now/SuperPaySingleNowButton';
import {
  getAvailableUnpostTarget,
  UnpostFromLoadboardButton,
} from './UnpostFromLoadboardButton';

const ListItem = styled.div`
  margin: 4px;
`;
export interface OrderActionsProps {
  order: Order;
  source: 'orders_list' | 'order_view_page';
  isPricingInsightsAvailable?: boolean;
}

export const OrderActions: FunctionComponent<OrderActionsProps> = ({
  order,
  source,
  isPricingInsightsAvailable,
}) => {
  const [isOpenPricingInsightsDrawer, setIsOpenPricingInsightsDrawer] =
    useState(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);

  const postTarget = useMemo(() => getAvailablePostTarget([order]), [order]);
  const unpostTarget = useMemo(
    () => getAvailableUnpostTarget([order]),
    [order],
  );

  const {
    onAction,
    isSubmitting,
    isValidating,
    popoverAction,
    type: currentActionType,
    order: currentActionOrder,
  } = useOrderActionsContext();

  const orderPaymentFlags = useOrderPaymentFlags(order.id);
  const history = useHistory();
  const match = useRouteMatch();
  const { user } = useUserState();
  const { addSnackbar } = useSnackbarStack();
  const { withAccessRestriction } = useAccessRestrictedState();
  const platform = useResponsiveValue(
    'mobile',
    'tablet',
    'xxtablet',
    'desktop',
  );

  const isParentOrder = order.has_loads && !order.is_load;
  const canUpdateOrder = usePermission('ORDER', 'canUpdate');
  const canSendOffer = usePermission('SEND_OFFER', 'canExecute');
  const isPriceNegotiationEnabled = useFeatureToggle(
    'price.negotiation.enabled',
  );
  const splitOrderEnabled = useFeatureToggle('split-order.enabled.ui');
  const buildLoadsEnabled = useFeatureToggle('build-loads.enabled.ui');
  const splitOrderIntoLoadsEnabled = useFeatureToggle(
    'build-loads.split-order.enabled.ui',
  );
  const customCarrierInvoiceEnabled = useFeatureToggle(
    'carrier-invoice.po-number-and-carrier-invoice-date.enabled',
  );
  const isSystemDeliveryVerificationEnabled = useFeatureToggle(
    'stms.system.delivery.verification.enabled',
  );

  const { isAdvancedTier } = useProductTiers();

  useEffect(() => {
    if (!popoverAction) {
      setMenuAnchor(null);
    }
  }, [popoverAction, setMenuAnchor]);

  const canInstantDispatch =
    useCanInstantDispatch(source === 'order_view_page' ? order : undefined) &&
    !isParentOrder;
  const collapseAllActions = platform !== 'desktop';
  const shipperType = user?.shipper.shipper_type;
  const isSuperPayNowMenuItem =
    orderPaymentFlags?.can_superpay_now && collapseAllActions;
  const isSuperPayMarkDeliveryVerification =
    orderPaymentFlags?.can_mark_as_delivery_verified;
  const isSystemMarkDeliveryVerification =
    isSystemDeliveryVerificationEnabled &&
    order.delivery_verification_status === 'FAILED';
  const isMarkDeliveryVerification =
    isSuperPayMarkDeliveryVerification || isSystemMarkDeliveryVerification;
  const isUnmarkDeliveryVerification =
    orderPaymentFlags?.can_unmark_as_delivery_verified;
  const isDisabled =
    currentActionOrder?.id !== order.id && (isSubmitting || isValidating);
  const isMenuActionLoading =
    currentActionType &&
    !isPrimaryAction(currentActionType) &&
    currentActionOrder?.id === order.id &&
    (isSubmitting || isValidating);

  const showCustomerCollapsedActions =
    shipperType !== 'BROKER' &&
    (showSubmitToBroker(order, user) ||
      isBOLSyncing(order) ||
      getBOLUrl(order, user) ||
      showOnlineBOL(order) ||
      showEditAction(order, user) ||
      showRemoveOrder(order, user));

  const isPaymentPrimaryAction = order.payment?.super_pay?.is_on_hold;

  const showResumePayment = orderPaymentFlags?.can_be_resumed;
  const showInitiatePaymentNow = orderPaymentFlags?.can_be_initiated_now;

  const isSuperPay =
    user?.shipper.had_superpay_access && isSuperpayPayment(order.payment);

  const rollBackActionHandleType =
    isSuperPay &&
    order.status === 'delivered' &&
    !order.has_loads &&
    !order.payment?.super_pay?.is_on_hold
      ? 'unmark_as_delivered'
      : 'rollback_manual_status';

  const isSuperPayNowManual =
    user?.shipper.super_pay_settings?.superpay_flow === 'MANUAL' && isSuperPay;

  const isSuperPaySuspend =
    user?.shipper.super_pay_settings?.suspended_account && isSuperPay;

  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const hideMenu = () => {
    setMenuAnchor(null);
  };

  function handleAction(type: OrderActionType, args?: Record<string, unknown>) {
    return (event: MouseEvent) => {
      if (!isPopoverAction(type)) {
        hideMenu();
      }
      onAction(type, order, event.currentTarget, args);
    };
  }

  function getLoading(type: OrderActionType) {
    return (
      currentActionType === type &&
      currentActionOrder?.id === order.id &&
      (isSubmitting || isValidating)
    );
  }

  function getMenuItemProps(type: OrderActionType) {
    return {
      onClick: handleAction(type),
      selected:
        type === currentActionType && order.id === currentActionOrder?.id,
    };
  }

  function handleSyncingBOLClick() {
    addSnackbar(
      <Typography>
        The document is syncing. <br />
        To speed up the process, ask to keep the driver app open and wait till
        sync finishes.
      </Typography>,
      { key: 'SyncingBOL' },
    );
  }

  function handleAPNValidation() {
    addSnackbar(
      <Stack>
        Set a positive Total Carrier Price to enable Price Negotiation.
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            history.push(`/orders/edit/${order.guid}`);
          }}
        >
          Edit Order
        </Button>
      </Stack>,
      {
        variant: 'error',
      },
    );
  }

  return (
    <Grid container={true} spacing={1} justifyContent="flex-end">
      {order.is_active ? (
        shipperType === 'BROKER' ? (
          <>
            {!collapseAllActions && (
              <>
                {isPaymentPrimaryAction &&
                  (showResumePayment ? (
                    <Grid item={true}>
                      <ListItem>
                        <ButtonGroupDropdown
                          label="Resume Payment"
                          color="primary"
                          variant="contained"
                          isLoading={
                            getLoading('resume_payment') ||
                            getLoading('initiate_payment_now')
                          }
                          onClick={handleAction('resume_payment')}
                        >
                          <MenuItem
                            onClick={handleAction('initiate_payment_now')}
                          >
                            Initiate Payment Now
                          </MenuItem>
                        </ButtonGroupDropdown>
                      </ListItem>
                    </Grid>
                  ) : (
                    showInitiatePaymentNow && (
                      <Grid item={true}>
                        <Button
                          color="primary"
                          variant="contained"
                          isLoading={getLoading('initiate_payment_now')}
                          onClick={handleAction('initiate_payment_now')}
                        >
                          Initiate Payment Now
                        </Button>
                      </Grid>
                    )
                  ))}

                {canInstantDispatch && (
                  <Grid item={true}>
                    <Button
                      color="primary"
                      variant="contained"
                      aria-label="instant dispatch"
                      endIcon={<ChevronLeft />}
                      isLoading={getLoading('instant_dispatch')}
                      onClick={handleAction('instant_dispatch')}
                    >
                      Instant Dispatch Available
                    </Button>
                  </Grid>
                )}

                {showSendOffer(order, user) && (
                  <Protect entity="SEND_OFFER" permission="canExecute">
                    <Grid item={true}>
                      <Button
                        color="primary"
                        variant="outlined"
                        isLoading={getLoading('send_offer')}
                        onClick={(e) => {
                          withAccessRestriction(() => {
                            if (isSuperPaySuspend) {
                              handleAction('superpay_suspend_send_offer')(e);
                              return;
                            }
                            handleAction('send_offer')(e);
                            trackEventLegacy('Opened Send Offer Drawer', {
                              utm_medium: source,
                              order_guid: order.guid,
                            });
                          });
                        }}
                      >
                        Send Offer
                      </Button>
                    </Grid>
                  </Protect>
                )}

                {!isParentOrder && postTarget.length > 0 && (
                  <Grid item={true}>
                    <PostLoadboardButton
                      target={postTarget}
                      source={source}
                      onClick={(event, type, args) => {
                        withAccessRestriction(() => {
                          if (isSuperPaySuspend) {
                            handleAction('superpay_suspend_post_order')(event);
                            return;
                          }
                          handleAction(type, args)(event);
                        });
                      }}
                      isLoading={
                        getLoading('post_to_private_loadboard') ||
                        getLoading('post_to_cd') ||
                        getLoading('post_to_sdlb') ||
                        getLoading('post_to_all')
                      }
                    />
                  </Grid>
                )}

                {!isParentOrder && unpostTarget && (
                  <Grid item={true}>
                    <UnpostFromLoadboardButton
                      target={unpostTarget}
                      source={source}
                      onClick={(type, event) => handleAction(type)(event)}
                      isLoading={
                        getLoading('unpost_from_private_loadboard') ||
                        getLoading('unpost_from_cd') ||
                        getLoading('unpost_from_sdlb') ||
                        getLoading('unpost_from_all')
                      }
                    />
                  </Grid>
                )}

                {isPriceNegotiationEnabled &&
                  isAdvancedTier &&
                  !isParentOrder &&
                  isPriceNegotiationAvailable(order) && (
                    <Grid item={true}>
                      <Tooltip
                        title={
                          <>
                            Configure rules to automate negotiation <br />
                            strategy with Carriers on Super Loadboard
                          </>
                        }
                      >
                        <Button
                          color="primary"
                          variant="outlined"
                          aria-label="price negotiation"
                          onClick={
                            order.price == null || order.price <= 0
                              ? handleAPNValidation
                              : handleAction('price_negotiation')
                          }
                        >
                          <Typography variant="button">
                            {'Price Negotiation '}
                            <Tag color="blue" variant="subtle">
                              New
                            </Tag>
                          </Typography>
                        </Button>
                      </Tooltip>
                    </Grid>
                  )}

                {orderPaymentFlags?.can_superpay_now && (
                  <Grid item={true}>
                    <SuperPaySingleNowGroupButton
                      source={source}
                      order={order}
                      canScheduleSuperPay={orderPaymentFlags.can_schedule}
                      onSuperPayNow={handleAction(
                        isSuperPayNowManual
                          ? 'superpay_now_manual'
                          : 'superpay_now',
                      )}
                    />
                  </Grid>
                )}

                {isMarkDeliveryVerification && (
                  <Grid item={true}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleAction('mark_as_delivery_verification')}
                    >
                      Mark as Delivery Verified
                    </Button>
                  </Grid>
                )}

                {isPricingInsightsAvailable && (
                  <Grid item={true}>
                    <PricingInsightsButtonWithDrawer order={order} />
                  </Grid>
                )}

                {showEditAction(order, user) && (
                  <Protect entity="ORDER" permission="canUpdate">
                    <Grid item={true}>
                      <Link
                        style={{ textDecoration: 'none' }}
                        to={`/orders/edit/${order.guid}`}
                      >
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() =>
                            trackEventLegacy('Opened Order Edit Page', {
                              utm_medium: source,
                            })
                          }
                        >
                          <Create style={{ color: Color.Grey200 }} />
                        </Button>
                      </Link>
                    </Grid>
                  </Protect>
                )}
              </>
            )}

            {collapseAllActions &&
              source === 'order_view_page' &&
              showSendOffer(order, user) && (
                <Protect entity="SEND_OFFER" permission="canExecute">
                  <Grid item={true}>
                    <Button
                      color="primary"
                      variant="outlined"
                      isLoading={getLoading('send_offer')}
                      size={platform === 'mobile' ? 'small' : 'medium'}
                      onClick={(e) => {
                        withAccessRestriction(() => {
                          if (isSuperPaySuspend) {
                            handleAction('superpay_suspend_send_offer')(e);
                            return;
                          }
                          handleAction('send_offer')(e);
                          trackEventLegacy('Opened Send Offer Drawer', {
                            utm_medium: source,
                            order_guid: order.guid,
                          });
                        });
                      }}
                    >
                      Send Offer
                    </Button>
                  </Grid>
                </Protect>
              )}

            {isPricingInsightsAvailable && collapseAllActions && (
              <SourceManager secondarySource="Pricing Insights Button">
                <PricingInsightsDrawer
                  order={order}
                  open={isOpenPricingInsightsDrawer}
                  onClose={() => {
                    setIsOpenPricingInsightsDrawer(false);
                  }}
                />
              </SourceManager>
            )}

            <Grid item={true}>
              <Button
                variant="outlined"
                color="primary"
                onClick={showMenu}
                disabled={isDisabled}
                isLoading={isMenuActionLoading}
                aria-label="order actions"
                data-intercom-target="order actions"
                size={platform === 'mobile' ? 'small' : 'medium'}
              >
                &bull;&bull;&bull;
              </Button>

              <Menu
                open={!!menuAnchor}
                anchorEl={menuAnchor}
                onClose={hideMenu}
                PaperProps={{
                  style: {
                    bottom: platform === 'mobile' ? '60px' : 'initial',
                  },
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                disableAutoFocusItem={true}
              >
                {(collapseAllActions || !isPaymentPrimaryAction) && [
                  showInitiatePaymentNow && (
                    <MenuItem onClick={handleAction('initiate_payment_now')}>
                      Initiate Payment Now
                    </MenuItem>
                  ),
                  isSuperPayNowMenuItem && (
                    <SuperPaySingleNowMenuButton
                      source={source}
                      onScheduleSuccess={hideMenu}
                      order={order}
                      canScheduleSuperPay={orderPaymentFlags.can_schedule}
                      onSuperPayNow={handleAction(
                        isSuperPayNowManual
                          ? 'superpay_now_manual'
                          : 'superpay_now',
                      )}
                    />
                  ),
                  showResumePayment && (
                    <MenuItem onClick={handleAction('resume_payment')}>
                      Resume Payment
                    </MenuItem>
                  ),
                  (showInitiatePaymentNow ||
                    showResumePayment ||
                    isSuperPayNowMenuItem) && (
                    <ActionDivider key="initiate-resume-payment" />
                  ),
                ]}

                {showSendOffer(order, user) &&
                  canSendOffer &&
                  collapseAllActions &&
                  source === 'orders_list' && (
                    <MenuItem
                      onClick={(e) => {
                        withAccessRestriction(() => {
                          if (isSuperPaySuspend) {
                            handleAction('superpay_suspend_send_offer')(e);
                            return;
                          }
                          handleAction('send_offer')(e);
                          trackEventLegacy('Opened Send Offer Drawer', {
                            utm_medium: source,
                            order_guid: order.guid,
                          });
                        });
                      }}
                    >
                      Send Offer
                    </MenuItem>
                  )}

                {canInstantDispatch && collapseAllActions && (
                  <MenuItem
                    onClick={(e) => {
                      handleAction('instant_dispatch')(e);
                    }}
                  >
                    Instant Dispatch
                  </MenuItem>
                )}

                {platform === 'mobile' &&
                  source === 'order_view_page' &&
                  order.is_active &&
                  !order.is_archived &&
                  user?.shipper.shipper_type === 'BROKER' && (
                    <MenuItem
                      onClick={() => {
                        hideMenu();
                        if (match) {
                          history.push(`${match.url}/load_requests`);
                        }
                      }}
                    >
                      Requests
                    </MenuItem>
                  )}

                {postTarget && collapseAllActions && (
                  <>
                    <PostLoadboardButton
                      target={postTarget}
                      source={source}
                      isMenuItem={true}
                      onClick={(event, type, args) => {
                        withAccessRestriction(() => {
                          if (isSuperPaySuspend) {
                            handleAction('superpay_suspend_post_order')(event);
                            return;
                          }
                          handleAction(type, args)(event);
                        });
                      }}
                      isLoading={
                        getLoading('post_to_private_loadboard') ||
                        getLoading('post_to_cd') ||
                        getLoading('post_to_sdlb') ||
                        getLoading('post_to_all')
                      }
                    />

                    <ActionDivider />
                  </>
                )}

                {unpostTarget && collapseAllActions && (
                  <>
                    <UnpostFromLoadboardButton
                      target={unpostTarget}
                      isMenuItem={true}
                      source={source}
                      isLoading={
                        getLoading('unpost_from_private_loadboard') ||
                        getLoading('unpost_from_cd') ||
                        getLoading('unpost_from_sdlb') ||
                        getLoading('unpost_from_all')
                      }
                      onClick={(type, event) => handleAction(type)(event)}
                    />

                    <ActionDivider />
                  </>
                )}

                {isPriceNegotiationEnabled &&
                  isAdvancedTier &&
                  !isParentOrder &&
                  isPriceNegotiationAvailable(order) &&
                  collapseAllActions && [
                    <MenuItem
                      key="price-negotiation"
                      onClick={handleAction('price_negotiation')}
                    >
                      <Inline space="xxsmall">
                        <TextBox>Price Negotiation</TextBox>

                        <Tag color="blue" variant="subtle">
                          New
                        </Tag>
                      </Inline>
                    </MenuItem>,
                    <ActionDivider key="divider-price-negotiation" />,
                  ]}

                {isPricingInsightsAvailable &&
                  collapseAllActions && [
                    <MenuItem
                      key="pricing_data"
                      onClick={() => {
                        hideMenu();
                        setIsOpenPricingInsightsDrawer(true);
                      }}
                    >
                      Pricing Insights
                    </MenuItem>,
                    <ActionDivider key="divider-5" />,
                  ]}

                {showEditAction(order, user) &&
                  canUpdateOrder &&
                  collapseAllActions && [
                    <MenuItem
                      key="edit"
                      onClick={() => {
                        hideMenu();
                        history.push(`/orders/edit/${order.guid}`);
                        trackEventLegacy('Opened Order Edit Page', {
                          utm_medium: source,
                        });
                      }}
                    >
                      Edit
                    </MenuItem>,
                    <ActionDivider key="divider-6" />,
                  ]}

                {order.carrier_guid && [
                  <MenuItem
                    key="open-ctms"
                    component="a"
                    target="_blank"
                    onClick={hideMenu}
                    href={OrderDTO.getCarrierOrderURL(order.guid)}
                  >
                    Open in Carrier TMS
                  </MenuItem>,
                  <ActionDivider key="divider-7" />,
                ]}

                {isBOLSyncing(order) ? (
                  <MenuItem onClick={handleSyncingBOLClick}>
                    <BOLSyncText>Download BOL</BOLSyncText>
                  </MenuItem>
                ) : getBOLUrl(order, user) ? (
                  <MenuItem
                    component="a"
                    target="_blank"
                    href={getBOLUrl(order, user)}
                    onClick={() => {
                      trackEventLegacy('Downloaded BOL');
                      hideMenu();
                    }}
                  >
                    Download BOL
                  </MenuItem>
                ) : null}

                {isBOLSyncing(order) ? (
                  <MenuItem onClick={handleSyncingBOLClick}>
                    <BOLSyncText>View Online BOL</BOLSyncText>
                  </MenuItem>
                ) : showOnlineBOL(order) ? (
                  <MenuItem
                    component="a"
                    target="_blank"
                    data-intercom-target="view online bol"
                    onClick={() => {
                      trackEventLegacy('Viewed Online BOL');
                      hideMenu();
                    }}
                    href={getOnlineBOLUrl(order)}
                  >
                    View Online BOL
                  </MenuItem>
                ) : null}

                {!!order.dispatch_sheet_url && (
                  <MenuItem
                    component="a"
                    target="_blank"
                    onClick={hideMenu}
                    href={order.dispatch_sheet_url}
                  >
                    View Dispatch Sheet
                  </MenuItem>
                )}

                {getInvoiceUrl(order, customCarrierInvoiceEnabled) && (
                  <MenuItem
                    component="a"
                    target="_blank"
                    data-intercom-target="view carrier invoice"
                    href={getInvoiceUrl(order, customCarrierInvoiceEnabled)}
                    onClick={() => {
                      trackEventLegacy('Viewed Carrier Invoice');
                      hideMenu();
                    }}
                  >
                    View Carrier Invoice
                  </MenuItem>
                )}
                {getCustomerInvoiceUrl(order) && isAdvancedTier && (
                  <MenuItem
                    component="a"
                    target="_blank"
                    href={getCustomerInvoiceUrl(order)}
                    onClick={() => {
                      trackEventLegacy('Viewed Customer Invoice');
                      hideMenu();
                    }}
                  >
                    View Customer Invoice
                  </MenuItem>
                )}

                {isDeliveryReceiptUrlSyncing(order) ? (
                  <MenuItem onClick={handleSyncingBOLClick}>
                    <BOLSyncText>Download Delivery Receipt</BOLSyncText>
                  </MenuItem>
                ) : order.pdf_delivery_receipt_url ? (
                  <MenuItem
                    component="a"
                    target="_blank"
                    onClick={hideMenu}
                    href={order.pdf_delivery_receipt_url}
                  >
                    Download Delivery Receipt
                  </MenuItem>
                ) : null}

                <ActionDivider key="divider-8" />

                {showMarkAsNew(order) && (
                  <Protect
                    permission="canExecute"
                    entity="MANUALLY_MARK_AS_NEW"
                  >
                    <MenuItem onClick={handleAction('mark_as_new')}>
                      Mark as New
                    </MenuItem>
                  </Protect>
                )}

                {showMarkAsOnHold(order) && isAdvancedTier && (
                  <MenuItem onClick={handleAction('mark_as_on_hold')}>
                    Mark as On Hold
                  </MenuItem>
                )}

                {showMarkAsPending(order) && (
                  <Protect
                    permission="canExecute"
                    entity="MANUALLY_MARK_AS_PENDING"
                  >
                    <MenuItem onClick={handleAction('mark_as_pending')}>
                      Mark as Pending
                    </MenuItem>
                  </Protect>
                )}

                {showMarkAsAccepted(order) && (
                  <Protect
                    permission="canExecute"
                    entity="MANUALLY_MARK_AS_ACCEPTED"
                  >
                    <MenuItem onClick={handleAction('mark_as_accepted')}>
                      Mark as Accepted
                    </MenuItem>
                  </Protect>
                )}

                {showRollbackAction(order, isSuperPay) && (
                  <Protect
                    permission="canExecute"
                    entity="ROLLBACK_MANUAL_STATUS_CHANGE"
                  >
                    <MenuItem onClick={handleAction(rollBackActionHandleType)}>
                      {order.status === 'accepted'
                        ? 'Unmark as Accepted'
                        : order.status === 'picked_up'
                        ? 'Unmark as Picked up'
                        : // order.status === 'delivered'
                          'Unmark as Delivered'}
                    </MenuItem>
                  </Protect>
                )}

                {(order.status === 'accepted' ||
                  (order.status === 'delivered' &&
                    order.active_offer?.is_status_changed_manually) ||
                  isNewOrderWithLoads(order)) && (
                  <Protect
                    entity="MANUALLY_MARK_AS_PICKED_UP"
                    permission="canExecute"
                  >
                    <MenuItem {...getMenuItemProps('mark_as_picked_up')}>
                      Mark as Picked Up
                    </MenuItem>
                  </Protect>
                )}

                {(order.status === 'accepted' ||
                  order.status === 'picked_up' ||
                  isNewOrderWithLoads(order)) && (
                  <Protect
                    entity="MANUALLY_MARK_AS_DELIVERED"
                    permission="canExecute"
                  >
                    <MenuItem {...getMenuItemProps('mark_as_delivered')}>
                      Mark as Delivered
                    </MenuItem>
                  </Protect>
                )}

                {orderPaymentFlags?.can_hold_payment && (
                  <MenuItem {...getMenuItemProps('mark_as_hold_custom_reason')}>
                    Mark as Payment on Hold
                  </MenuItem>
                )}

                {isMarkDeliveryVerification && collapseAllActions && (
                  <MenuItem
                    onClick={handleAction('mark_as_delivery_verification')}
                  >
                    Mark as Delivery Verified
                  </MenuItem>
                )}

                {isUnmarkDeliveryVerification && (
                  <MenuItem
                    onClick={handleAction('unmark_as_delivery_verification')}
                  >
                    Unmark as Delivery Verified
                  </MenuItem>
                )}

                {showMarkAsCarrierInvoiced(order) && (
                  <MenuItem {...getMenuItemProps('mark_as_carrier_invoiced')}>
                    Mark as Carrier Invoiced
                  </MenuItem>
                )}

                {showUnmarkAsCarrierInvoiced(order) && (
                  <MenuItem
                    onClick={handleAction('unmark_as_carrier_invoiced')}
                  >
                    Unmark as Carrier Invoiced
                  </MenuItem>
                )}

                {isSuperPay ? null : order.status === 'paid' ? (
                  <Protect entity="MARK_AS_PAID" permission="canExecute">
                    <MenuItem onClick={handleAction('unmark_as_paid')}>
                      Unmark as Paid
                    </MenuItem>
                  </Protect>
                ) : (
                  <Protect permission="canExecute" entity="MARK_AS_PAID">
                    <MenuItem
                      data-intercom-target="mark as paid"
                      onClick={handleAction('mark_as_paid')}
                    >
                      Mark as Paid
                    </MenuItem>
                  </Protect>
                )}

                {isAdvancedTier && (
                  <Protect
                    entity="SEND_CUSTOMER_INVOICE"
                    permission="canExecute"
                  >
                    <MenuItem onClick={handleAction('send_customer_invoice')}>
                      Send Customer Invoice
                    </MenuItem>
                  </Protect>
                )}

                {isAdvancedTier && <ActionDivider key="divider-9" />}

                {showSplitOrderAction(order) &&
                  splitOrderEnabled &&
                  !order.has_loads && (
                    <MenuItem
                      to={`/orders/split/${order.guid}`}
                      component={Link}
                    >
                      Split Order
                    </MenuItem>
                  )}

                {shipperType === 'BROKER' &&
                  splitOrderIntoLoadsEnabled &&
                  showSplitOrderIntoLoadsAction(order) &&
                  !order.is_load &&
                  !order.has_legs && (
                    <MenuItem
                      to={`/orders/split-loads/${order.guid}`}
                      component={Link}
                      onClick={() => {
                        trackEvent(
                          '[STMS] Split Order into Loads: Menu item click',
                        );
                      }}
                    >
                      Split Order into Loads
                    </MenuItem>
                  )}

                <MenuItem onClick={handleAction('duplicate')}>
                  Duplicate
                </MenuItem>

                {isAdvancedTier &&
                  (order.is_flagged ? (
                    <MenuItem onClick={handleAction('unflag')}>Unflag</MenuItem>
                  ) : (
                    <MenuItem onClick={handleAction('flag')}>Flag</MenuItem>
                  ))}

                {buildLoadsEnabled &&
                  showBuildLoads(order) &&
                  !order.is_load &&
                  (!order.has_loads || order.has_legs) && (
                    <MenuItem onClick={handleAction('build_loads')}>
                      Build Loads
                    </MenuItem>
                  )}

                {isAdvancedTier && <ActionDivider key="divider-10" />}

                {showCancelOffer(order) && (
                  <Protect permission="canExecute" entity="CANCEL_OFFER">
                    <MenuItem onClick={handleAction('cancel_offer')}>
                      Cancel Offer
                    </MenuItem>
                  </Protect>
                )}

                {showCancelOrderAction(order) && (
                  <MenuItem onClick={handleAction('cancel_order')}>
                    Cancel Order
                  </MenuItem>
                )}

                {order.is_archived ? (
                  <Protect entity="UNARCHIVE_ORDER" permission="canExecute">
                    <MenuItem onClick={handleAction('unarchive')}>
                      Unarchive
                    </MenuItem>
                  </Protect>
                ) : (
                  <Protect entity="ARCHIVE_ORDER" permission="canExecute">
                    <MenuItem
                      onClick={handleAction('archive')}
                      data-intercom-target="archive"
                    >
                      Archive
                    </MenuItem>
                  </Protect>
                )}

                {showRemoveOrder(order, user) && (
                  <Protect entity="ORDER" permission="canDelete">
                    <MenuItem onClick={handleAction('delete_order')}>
                      Delete
                    </MenuItem>
                  </Protect>
                )}
              </Menu>
            </Grid>
          </>
        ) : !collapseAllActions ? (
          <Grid
            container={true}
            spacing={1}
            wrap="nowrap"
            style={{ justifyContent: 'flex-end' }}
          >
            {showSubmitToBroker(order, user) && (
              <Protect entity="CUSTOMER_ORDER_SUBMIT" permission="canExecute">
                <Grid item={true}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleAction('submit_to_broker')}
                    isLoading={getLoading('submit_to_broker')}
                  >
                    Submit
                  </Button>
                </Grid>
              </Protect>
            )}

            {(isBOLSyncing(order) || getBOLUrl(order, user)) && (
              <Grid item={true}>
                {isBOLSyncing(order) ? (
                  <Tooltip
                    placement="top-end"
                    title="The BOL is not available for this order. Contact shipper for details"
                  >
                    <Button variant="outlined" color="primary">
                      Download BOL
                    </Button>
                  </Tooltip>
                ) : getBOLUrl(order, user) ? (
                  <Button
                    rel="noreferrer"
                    variant="outlined"
                    color="primary"
                    target="_blank"
                    href={getBOLUrl(order, user)}
                    onClick={() => {
                      trackEventLegacy('Downloaded BOL');
                      hideMenu();
                    }}
                  >
                    Download BOL
                  </Button>
                ) : null}
              </Grid>
            )}

            {(isBOLSyncing(order) || showOnlineBOL(order)) && (
              <Grid item={true}>
                {isBOLSyncing(order) ? (
                  <Tooltip
                    placement="top-end"
                    title="The BOL is not available for this order. Contact shipper for details"
                  >
                    <Button color="primary" variant="outlined">
                      View Online BOL
                    </Button>
                  </Tooltip>
                ) : showOnlineBOL(order) ? (
                  <Button
                    color="primary"
                    variant="outlined"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-intercom-target="view online bol"
                    href={getOnlineBOLUrl(order)}
                    onClick={() => {
                      trackEventLegacy('Viewed Online BOL');
                      hideMenu();
                    }}
                  >
                    View Online BOL
                  </Button>
                ) : null}
              </Grid>
            )}

            {showEditAction(order, user) && (
              <Protect entity="ORDER" permission="canUpdate">
                <Grid item={true}>
                  <Link
                    style={{ textDecoration: 'none' }}
                    to={`/orders/edit/${order.guid}`}
                  >
                    <Button color="primary" variant="outlined">
                      Edit
                    </Button>
                  </Link>
                </Grid>
              </Protect>
            )}

            {showRemoveOrder(order, user) && (
              <Protect entity="ORDER" permission="canDelete">
                <Grid item={true}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleAction('delete_order')}
                    isLoading={getLoading('delete_order')}
                  >
                    Delete
                  </Button>
                </Grid>
              </Protect>
            )}
          </Grid>
        ) : (
          <Grid container={true}>
            {showSubmitToBroker(order, user) && (
              <Protect entity="CUSTOMER_ORDER_SUBMIT" permission="canExecute">
                <Grid item={true}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={handleAction('submit_to_broker')}
                    isLoading={getLoading('submit_to_broker')}
                  >
                    Submit
                  </Button>
                </Grid>
              </Protect>
            )}
            <Grid item={true}>
              {showCustomerCollapsedActions && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={showMenu}
                  disabled={isDisabled}
                  isLoading={isMenuActionLoading}
                  aria-label="order actions"
                  data-intercom-target="order actions"
                >
                  &bull;&bull;&bull;
                </Button>
              )}

              <Menu
                open={!!menuAnchor}
                anchorEl={menuAnchor}
                onClose={hideMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                disableAutoFocusItem={true}
              >
                {isBOLSyncing(order) ? (
                  <MenuItem onClick={handleSyncingBOLClick}>
                    <BOLSyncText>View Online BOL</BOLSyncText>
                  </MenuItem>
                ) : getBOLUrl(order, user) ? (
                  <MenuItem
                    rel="norefferer"
                    component="a"
                    target="_blank"
                    onClick={() => {
                      trackEventLegacy('Downloaded BOL');
                      hideMenu();
                    }}
                    href={getBOLUrl(order, user)}
                  >
                    Download Online BOL
                  </MenuItem>
                ) : null}

                {isBOLSyncing(order) ? (
                  <MenuItem onClick={handleSyncingBOLClick}>
                    <BOLSyncText>View Online BOL</BOLSyncText>
                  </MenuItem>
                ) : showOnlineBOL(order) ? (
                  <MenuItem
                    component="a"
                    target="_blank"
                    onClick={() => {
                      trackEventLegacy('Viewed Online BOL');
                      hideMenu();
                    }}
                    href={getOnlineBOLUrl(order)}
                  >
                    View Online BOL
                  </MenuItem>
                ) : null}

                {showEditAction(order, user) && (
                  <Protect entity="ORDER" permission="canUpdate">
                    <MenuItem
                      to={`/orders/edit/${order.guid}`}
                      component={Link}
                    >
                      Edit
                    </MenuItem>
                  </Protect>
                )}

                {showRemoveOrder(order, user) && (
                  <Protect entity="ORDER" permission="canDelete">
                    <MenuItem onClick={handleAction('delete_order')}>
                      Delete
                    </MenuItem>
                  </Protect>
                )}
              </Menu>
            </Grid>
          </Grid>
        )
      ) : (
        <Grid item={true}>
          <Protect entity="RESTORE_INACTIVE" permission="canExecute">
            <Button
              color="primary"
              variant="outlined"
              onClick={handleAction('restore_order')}
              isLoading={getLoading('restore_order')}
            >
              Restore
            </Button>
          </Protect>
        </Grid>
      )}
    </Grid>
  );
};
