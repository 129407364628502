import { Typography } from '@material-ui/core';
import { Box, Flex } from '@rebass/grid';
import { useResponsiveValue } from '@superdispatch/ui';
import { ComponentType } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useUserState } from 'shared/data/AppUserState';
import Order from 'shared/types/order';
import { useFeatureToggle } from '../../shared/data/FeatureToggle';
import { OrderStatus, RequestedLoadsButton } from '../core';
import { OrderActions } from '../core/actions/OrderActions';
import { OrderViewBackButton } from './OrderViewBackButton';

interface HeaderProps {
  order: Order;
}

export const OrderViewHeader: ComponentType<HeaderProps> = ({ order }) => {
  const match = useRouteMatch();
  const history = useHistory();
  const { user } = useUserState();
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const hasPricePredictionAccess =
    useFeatureToggle('price-prediction.access.ui') &&
    user?.shipper.shipper_type === 'BROKER';

  return platform !== 'mobile' ? (
    <Flex alignItems="center" px={platform !== 'desktop' ? 3 : 4} py={3}>
      <Box flex={1}>
        <Flex alignItems="center">
          <Box ml={-12}>
            <OrderViewBackButton />
          </Box>

          <Box ml={1}>
            <Typography variant="h2" aria-label="order number">
              {order.number}
            </Typography>
          </Box>

          <Box mx={2}>
            <OrderStatus order={order} inline={true} />
          </Box>

          <Box mx={2}>
            <RequestedLoadsButton
              order={order}
              onClick={() => {
                if (match) {
                  history.push(`${match.url}/load_requests`);
                }
              }}
            />
          </Box>
        </Flex>
      </Box>

      <Box>
        <OrderActions
          order={order}
          source="order_view_page"
          isPricingInsightsAvailable={hasPricePredictionAccess}
        />
      </Box>
    </Flex>
  ) : (
    <Flex alignItems="space-between" px={3} py={3} flexDirection="column">
      <Box flex={1}>
        <Flex alignItems="center" justifyContent="space-between">
          <Box>
            <OrderViewBackButton />
          </Box>

          <Flex alignItems="center">
            <Box>
              <OrderActions
                order={order}
                source="order_view_page"
                isPricingInsightsAvailable={hasPricePredictionAccess}
              />
            </Box>
          </Flex>
        </Flex>
      </Box>

      <Box flex={1} pt={3} pl={4}>
        <Flex justifyContent="flex-start" alignItems="center">
          <Box ml={1}>
            <Typography variant="h2" aria-label="order number">
              {order.number}
            </Typography>
          </Box>

          <Box mx={2}>
            <OrderStatus order={order} />
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};
