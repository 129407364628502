import { Exclude, Expose, Type } from 'class-transformer';
import { CarrierBrokerPreferences } from 'shared/types/carrier';
import {
  TransformDateString,
  TransformNumber,
  TransformString,
} from 'shared/utils/TransformUtils';
import { RatingStatsDTO } from '../../../core/ratings/data/CarrierRatingDTO';

@Exclude()
export class InstantQuoteDTO {
  @Expose()
  @TransformNumber()
  price: number;

  @Expose()
  @TransformNumber()
  delivery_eta_days: number;

  @Expose()
  @TransformString()
  transport_type: string;

  @Expose()
  @TransformDateString('DateTimeISO')
  expires_at: string;

  @Expose()
  @TransformString()
  transport_option_id: string;
}

@Exclude()
export class InstantDispatchAvailabilityDetailsDTO {
  @Expose()
  @TransformString()
  pickup_area: string;

  @Expose()
  @TransformString()
  delivery_area: string;
}

@Exclude()
export class InstantDispatchCarriersDTO {
  @Expose() guid: string;
  @Expose() lane_guid: string;
  @Expose() name: string;
  @Expose() price: number;
  @Expose() price_per_mile: number;
  @Expose() min_delivery_eta: number;
  @Expose() max_delivery_eta: number;
  @Expose() is_verified: boolean;

  @Expose()
  @Type(() => CarrierBrokerPreferences)
  broker_records: CarrierBrokerPreferences;

  @Expose()
  rating_stats?: RatingStatsDTO | null;
}
