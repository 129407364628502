import { useHistory, useParams } from 'react-router';
import { LoadingContainer } from 'shared/ui/LoadingContainer';
import { useCarrier } from '../data/ManageCarriersAPI';
import { EditInternalCarrierForm } from './EditInternalCarrierForm';

export function EditInternalCarrierPage() {
  const history = useHistory();
  const { guid } = useParams<{ guid: string }>();
  const { data } = useCarrier({ guid });

  if (!data) {
    return <LoadingContainer />;
  }

  return (
    <EditInternalCarrierForm
      carrier={data}
      onSubmitSuccess={() => {
        history.push('/manage-carriers?carrier_type=internal');
      }}
    />
  );
}
