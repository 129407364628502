import {
  Box,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { useValueObserver } from '@superdispatch/hooks';
import { Button, CheckboxField, Inline, Stack } from '@superdispatch/ui';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { InfoIcon } from 'shared/icons/InfoIcon';
import { AttachmentWithFile } from 'shared/types/attachment';
import Order from 'shared/types/order';
import { AnyObject } from 'shared/types/Utils';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import styled from 'styled-components';
import { useProductTiers } from '../../../shared/data/TiersUtils';
import { PaperBox } from './PaperBox';

const ORDER_ATTACHMENT_ALLOWED_MIME_TYPES = [
  'text/html',
  'text/csv',
  'image/jpeg',
  'image/tiff',
  'image/png',
  'image/jpg',
  'image/heic',
  'application/pdf',
  'application/zip',
  'application/x-zip-compressed',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
const ORDER_ATTACHMENT_ACCEPT_TYPES =
  ORDER_ATTACHMENT_ALLOWED_MIME_TYPES.join();

type setFormValueCallback = (field: string, value: AnyObject) => void;

interface AddAttachmentParams {
  files: FileList | null;
  setFormikFieldValue: setFormValueCallback;
}

const duplicateReducer = (
  uniqueAttachments: AttachmentWithFile[],
  attachment: AttachmentWithFile,
) =>
  uniqueAttachments.find((a) => attachment.name === a.name)
    ? uniqueAttachments
    : [...uniqueAttachments, attachment];

const removeDuplicates = (attachments: AttachmentWithFile[]) => {
  return attachments.reduce(duplicateReducer, []);
};

const generateAttachmentLink = ({ file, url }: AttachmentWithFile) => {
  if (file instanceof File) {
    const blob = new Blob([file], { type: file.type });
    return URL.createObjectURL(blob);
  }
  return url;
};

interface UseAttachmentsParams {
  attachments: AttachmentsProps['attachments'];
}

export const useAttachments = ({
  attachments: initialAttachments,
}: UseAttachmentsParams) => {
  const [attachments, setAttachments] = useState(initialAttachments);

  useValueObserver(initialAttachments, () =>
    setAttachments(initialAttachments),
  );

  const addAttachment = ({
    files,
    setFormikFieldValue,
  }: AddAttachmentParams) => {
    if (!files) return;
    const newAttachments: AttachmentWithFile[] = Array.from(files, (file) => {
      return {
        file,
        name: file.name,
        is_shared_with_carrier: false,
        is_shared_with_customer: false,
      };
    });
    const mergedAttachments = [...attachments, ...newAttachments];
    const filteredAttachments = removeDuplicates(mergedAttachments);
    setFormikFieldValue('attachments', filteredAttachments);
    setAttachments(filteredAttachments);
  };

  const removeAttachment = (
    attachmentForRemove: AttachmentWithFile,
    setFormikFieldValue: setFormValueCallback,
  ) => {
    const remainedAttachments = attachments.filter(
      (attachment) => attachment !== attachmentForRemove,
    );
    setFormikFieldValue('attachments', remainedAttachments);
    setAttachments(remainedAttachments);
  };

  const editAttachment = (
    attachmentToEdit: AttachmentWithFile,
    field: string,
    value: boolean,
    setFormikFieldValue: setFormValueCallback,
  ) => {
    const editedAttachments = attachments.map(
      (attachment: AttachmentWithFile) =>
        attachment === attachmentToEdit
          ? { ...attachment, [field]: value, changed: true }
          : attachment,
    );

    if (attachmentToEdit) {
      setAttachments(editedAttachments);
      setFormikFieldValue('attachments', editedAttachments);
    }
  };

  return {
    attachments,
    addAttachment,
    removeAttachment,
    editAttachment,
  };
};

interface AddAttachmentButtonProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AddAttachmentButton: React.ComponentType<AddAttachmentButtonProps> = ({
  onChange,
}) => {
  return (
    <Button variant="text" color="primary" startIcon={<Add />}>
      <label style={{ cursor: 'inherit' }}>
        <span>Add New</span>

        <input
          type="file"
          multiple={true}
          onChange={onChange}
          hidden={true}
          accept={ORDER_ATTACHMENT_ACCEPT_TYPES}
        />
      </label>
    </Button>
  );
};

interface AttachmentListProps {
  orderID: Order['id'];
  attachments: AttachmentWithFile[];
  removeAttachment: (
    attachmentForRemove: AttachmentWithFile,
    setFormikFieldValue: setFormValueCallback,
  ) => void;
  editAttachment: (
    attachmentToEdit: AttachmentWithFile,
    field: string,
    value: boolean,
    setFormikFieldValue: setFormValueCallback,
  ) => void;
}

const StyledTableCellHead = styled(TableCell)`
  padding: 8px;
`;

const StyledTableCell = styled(TableCell)`
  padding: 12px 8px;
  max-width: 150px;
  overflow-wrap: break-word;

  & .MuiFormControlLabel-root {
    margin-left: 0;
  }
`;

const isDispatchSheet = (attachment: AttachmentWithFile): boolean => {
  if (!attachment.name) {
    return false;
  }

  return attachment.name
    .toLowerCase()
    .replaceAll(' ', '')
    .endsWith('dispatchsheet.pdf');
};

const AttacmentTable: React.ComponentType<AttachmentListProps> = ({
  attachments,
  removeAttachment,
  editAttachment,
}) => {
  const { user } = useUserState();
  const { setFieldValue } = useFormikContext();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [attachmentToRemove, setAttacmentToRemove] =
    useState<AttachmentWithFile | null>(null);
  const isCustomer = user?.shipper.shipper_type === 'CUSTOMER';

  const { isAdvancedTier } = useProductTiers();

  return (
    <TableContainer>
      <Table>
        {!isCustomer && (
          <TableHead>
            <TableRow>
              <StyledTableCellHead>
                <Typography variant="h5" color="textSecondary">
                  File
                </Typography>
              </StyledTableCellHead>

              <StyledTableCellHead style={{ width: 200 }}>
                <Typography variant="h5" color="textSecondary">
                  Owner
                </Typography>
              </StyledTableCellHead>

              <StyledTableCellHead style={{ width: 80 }}>
                <Typography variant="h5" color="textSecondary">
                  Carrier
                </Typography>
              </StyledTableCellHead>

              {isAdvancedTier && (
                <StyledTableCellHead style={{ width: 120 }}>
                  <Inline verticalAlign="center" noWrap={true}>
                    <Typography variant="h5" color="textSecondary">
                      Customer
                    </Typography>
                    <Tooltip
                      interactive={true}
                      title={
                        <Stack>
                          <Typography>
                            Share with Customer is available if this account is
                            connected with Customer Portal or another STMS
                            account.
                          </Typography>
                        </Stack>
                      }
                      placement="top"
                    >
                      <InfoIcon fontSize="small" />
                    </Tooltip>
                  </Inline>
                </StyledTableCellHead>
              )}

              <StyledTableCellHead style={{ width: 40 }} />
            </TableRow>
          </TableHead>
        )}

        <TableBody>
          {attachments.map((attachment, index) => (
            <TableRow key={index}>
              <StyledTableCell style={isCustomer ? { border: 'none' } : {}}>
                <Link
                  href={generateAttachmentLink(attachment)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {attachment.name}
                </Link>
              </StyledTableCell>

              {!isCustomer && (
                <>
                  <StyledTableCell>
                    <Typography color="textSecondary">
                      {attachment.shared_by
                        ? attachment.shared_by === 'CARRIER'
                          ? 'Carrier'
                          : 'Customer'
                        : 'You'}
                    </Typography>
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {attachment.shared_by !== 'CARRIER' ? (
                      <CheckboxField
                        label=""
                        checked={attachment.is_shared_with_carrier}
                        onChange={(_, checked) => {
                          editAttachment(
                            attachment,
                            'is_shared_with_carrier',
                            checked,
                            setFieldValue,
                          );
                        }}
                        disabled={isDispatchSheet(attachment)}
                      />
                    ) : (
                      <Typography>-</Typography>
                    )}
                  </StyledTableCell>

                  {isAdvancedTier && (
                    <StyledTableCell align="center">
                      {attachment.shared_by !== 'CUSTOMER' ? (
                        <CheckboxField
                          label=""
                          checked={attachment.is_shared_with_customer}
                          onChange={(_, checked) => {
                            editAttachment(
                              attachment,
                              'is_shared_with_customer',
                              checked,
                              setFieldValue,
                            );
                          }}
                          disabled={isDispatchSheet(attachment)}
                        />
                      ) : (
                        <Typography>-</Typography>
                      )}
                    </StyledTableCell>
                  )}
                </>
              )}

              <StyledTableCell
                style={
                  isCustomer
                    ? { border: 'none', width: '40px', padding: '10px 8px' }
                    : {}
                }
              >
                <Tooltip title="Delete" placement="top-end">
                  <div>
                    <IconButton
                      size="small"
                      disabled={
                        attachment.is_read_only || isDispatchSheet(attachment)
                      }
                      onClick={() => {
                        setShowConfirmation(true);
                        setAttacmentToRemove(attachment);
                      }}
                    >
                      <Delete fontSize="medium" />
                    </IconButton>
                  </div>
                </Tooltip>
              </StyledTableCell>
            </TableRow>
          ))}

          <ConfirmDialog
            open={showConfirmation}
            onClose={() => {
              setShowConfirmation(false);
              setAttacmentToRemove(null);
            }}
            title="Delete this file?"
            cancelButtonProps={{ children: 'Cancel' }}
            confirmButtonProps={{
              onClick: () => {
                setShowConfirmation(false);

                if (attachmentToRemove) {
                  removeAttachment(attachmentToRemove, setFieldValue);
                }
              },
              children: 'Delete',
              color: 'error',
            }}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export interface AttachmentsProps {
  title: string;
  orderId: number;
  attachments: AttachmentWithFile[];
  addAttachment: (params: AddAttachmentParams) => void;
  removeAttachment: (
    attachment: AttachmentWithFile,
    setFormikFieldValue: setFormValueCallback,
  ) => void;
  editAttachment: (
    attachmentToEdit: AttachmentWithFile,
    field: string,
    value: boolean,
    setFormikFieldValue: setFormValueCallback,
  ) => void;
}

export const Attachments: React.SFC<AttachmentsProps> = ({
  title,
  orderId,
  attachments,
  addAttachment,
  removeAttachment,
  editAttachment,
}) => {
  const { setFieldValue } = useFormikContext();
  const filteredAttachments = attachments;
  return (
    <PaperBox
      title={
        <Box display="flex">
          <Typography variant="h3">{title}</Typography>
          <Box my="-2px" ml={1}>
            <AddAttachmentButton
              onChange={(e) =>
                addAttachment({
                  files: e.currentTarget.files,
                  setFormikFieldValue: setFieldValue,
                })
              }
            />
          </Box>
        </Box>
      }
    >
      {filteredAttachments.length > 0 && (
        <Box mt={2}>
          <AttacmentTable
            orderID={orderId}
            attachments={filteredAttachments}
            removeAttachment={removeAttachment}
            editAttachment={editAttachment}
          />
        </Box>
      )}
    </PaperBox>
  );
};
