import {
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { ExitTransitionPlaceholder, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useBulkOrderActionAPI } from 'orders/data/OrderActionAPI';
import { useMemo } from 'react';
import Order from 'shared/types/order';
import { useUserState } from '../../../../shared/data/AppUserState';
import { trackOderActionEvent } from '../OrderActionsAnalytics';
import {
  AutomaticFlowDialogContent,
  ManualFlowDialogContent,
} from './BulkSuperPayNowDialogContent';

interface BulkSuperPayNowDialogProps {
  orders?: Order[];
  onClose: () => void;
  onSubmitSuccess: () => void;
}
export function BulkSuperPayNowDialog({
  orders,
  onClose,
  onSubmitSuccess,
}: BulkSuperPayNowDialogProps) {
  const open = !!orders && orders.length > 0;
  return (
    <Dialog fullWidth={true} maxWidth="xs" open={open} onClose={onClose}>
      <ExitTransitionPlaceholder in={open}>
        {open && (
          <BulkSuperPayNowDialogContent
            orders={orders}
            onClose={onClose}
            onSubmitSucess={onSubmitSuccess}
          />
        )}
      </ExitTransitionPlaceholder>
    </Dialog>
  );
}

interface BulkSuperPayNowDialogContentProps {
  orders: Order[];
  onClose: () => void;
  onSubmitSucess: () => void;
}
function BulkSuperPayNowDialogContent({
  orders,
  onClose,
  onSubmitSucess,
}: BulkSuperPayNowDialogContentProps) {
  const { user } = useUserState();
  const { bulkSuperPayNow } = useBulkOrderActionAPI();
  const { addSnackbar } = useSnackbarStack();
  const isManualFlow =
    user?.shipper.super_pay_settings?.superpay_flow === 'MANUAL';

  const price = useMemo(
    () => orders.reduce((acc, order) => acc + (order.price ?? 0), 0),
    [orders],
  );

  const formik = useFormikEnhanced({
    initialValues: {},
    onSubmit: () => {
      const ids = orders.map((x) => x.id);
      return bulkSuperPayNow(ids);
    },
    onSubmitSuccess: () => {
      addSnackbar('Orders updated', { variant: 'success' });
      trackOderActionEvent({
        name: 'Shipper Super Paid Now',
        source: 'Bulk Orders',
      });
      onSubmitSucess();
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form aria-label="superpay now dialog form">
        <DialogTitle>
          <Typography variant="h3">SuperPay Now</Typography>
        </DialogTitle>
        {isManualFlow ? (
          <ManualFlowDialogContent orders={orders} price={price} />
        ) : (
          <AutomaticFlowDialogContent orders={orders} price={price} />
        )}
        <DialogActions>
          <Button variant="neutral" onClick={onClose}>
            Cancel
          </Button>

          <Button type="submit" variant="primary" pending={formik.isSubmitting}>
            SuperPay Now
          </Button>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}
