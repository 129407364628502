import { APIQueryOptions, APIQueryResult, useAPIQuery } from '../api/APIQuery';
import { useGoogleMapsContext } from './GoogleMapsContext';

const defaultRequest: google.maps.DirectionsRequest = {
  avoidFerries: true,
};

export function useGoogleMapsDirections(
  request: google.maps.DirectionsRequest,
  options?: APIQueryOptions<google.maps.DirectionsResult | null>,
): APIQueryResult<google.maps.DirectionsResult | null> {
  const { getDirections } = useGoogleMapsContext();

  return useAPIQuery(
    [
      'maps',
      'directions',
      { request: request.origin, destination: request.destination },
    ],
    () => {
      return getDirections({
        ...defaultRequest,
        ...request,
      });
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!request.origin && !!request.destination,
      ...options,
    },
  );
}
