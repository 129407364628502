import { Grid, Paper, Popover, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Box, Flex } from '@rebass/grid';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  Button,
  Color,
  Inline,
  Stack,
  useResponsiveValue,
  useSnackbarStack,
} from '@superdispatch/ui';
import { isSuperPayStatusAvailable } from 'core/SuperPayUtils';
import { Form, FormikProvider } from 'formik';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { FeatureToggle, useFeatureToggle } from 'shared/data/FeatureToggle';
import { FormikCurrencyField } from 'shared/form/FormikCurrencyField';
import { OutlineCheckIcon as CheckIcon } from 'shared/icons/OutlineCheckIcon';
import { OutlineCloseIcon as CloseIcon } from 'shared/icons/OutlineCloseIcon';
import Order from 'shared/types/order';
import { required } from 'shared/utils/ValidatorUtils';
import { yupNumber, yupObject } from 'shared/utils/YupUtils';
import styled from 'styled-components';
import { OrderPricePerMile } from '../../../core/PricePerMile';
import { OrderPaymentFlagDTO } from '../../data/dto/OrderPaymentFlagsDTO';
import { useOrderAPI, useOrdersCache } from '../../data/OrderAPI';
import { useOrderPaymentFlags } from '../../data/OrderPaymentFlagsAPI';
import { useOrderActionsContext } from '../actions/OrderActionsContext';
import { isPriceSuperPayValid } from '../form/OrderFormValidation';
import {
  isOrderBelowRecommendedPrice,
  PricingRecommendationOrderList,
} from '../pricing-insights/PricingRecommendation';
import { OrderListPayment } from './OrderListPayment';

const StyledRoot = styled(Flex)`
  svg.MuiSvgIcon-root {
    visibility: hidden;
  }

  &:hover {
    cursor: pointer;

    svg {
      visibility: visible;

      &:hover {
        color: ${Color.Blue300};
      }
      &:not(:hover) {
        color: ${Color.Grey300};
      }
    }
  }
`;

const StyledStack = styled.div<{ alwaysShowIcon?: boolean }>`
  svg.MuiSvgIcon-root {
    visibility: ${(props) => (props.alwaysShowIcon ? 'visible' : 'hidden')};
    color: ${Color.Grey100};
  }

  &:hover {
    cursor: pointer;

    svg {
      visibility: visible;

      &:hover {
        color: ${Color.Blue300};
      }
      &:not(:hover) {
        color: ${Color.Grey100};
      }
    }
  }
`;

const StyledButton = styled(Button).attrs({ size: 'small' })`
  padding: 3px !important;
  min-width: 0 !important;
`;

const createOrderListPriceValidationSchema = ({
  order,
  orderPaymentFlags,
}: {
  order: Order;
  orderPaymentFlags?: OrderPaymentFlagDTO;
}) =>
  yupObject({
    newPrice: yupNumber().test(
      'isPriceSuperPayValid',
      'Price should be above $0 for SuperPay',
      (value) => isPriceSuperPayValid({ order, orderPaymentFlags, value }),
    ),
  });

const canEditPrice = (order: Order): boolean => {
  return !isSuperPayStatusAvailable(order);
};

export interface PriceProps {
  order: Order;
  title?: string;
}

export const OrderListPrice: React.ComponentType<PriceProps> = ({
  order,
  title,
}) => {
  const { addSnackbar } = useSnackbarStack();
  const { onActionComplete } = useOrderActionsContext();
  const { updateOrderPrice } = useOrderAPI();
  const { invalidateOrders } = useOrdersCache();
  const orderPaymentFlags = useOrderPaymentFlags(order.id);

  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const showEditForm = (e: React.MouseEvent<HTMLDivElement>) =>
    setAnchorEl(e.currentTarget);
  const hideEditForm = () => setAnchorEl(null);
  const isPricingRecommendationAvailable = useFeatureToggle(
    'recommended-price.enabled.ui',
  );
  const isBelowPrice =
    isPricingRecommendationAvailable && isOrderBelowRecommendedPrice(order);

  const handleInputFocus = ({ target }: React.FocusEvent<HTMLInputElement>) =>
    target.select();

  const validationSchema = useMemo(() => {
    return createOrderListPriceValidationSchema({ order, orderPaymentFlags });
  }, [order, orderPaymentFlags]);

  const formik = useFormikEnhanced<{ newPrice?: number }, unknown>({
    initialValues: { newPrice: order.price },
    validationSchema,
    onSubmit({ newPrice }) {
      if (newPrice == null) {
        return Promise.reject(Error('Price is required'));
      }
      return updateOrderPrice(order.id, newPrice);
    },
    onSubmitSuccess(_, { newPrice }) {
      void invalidateOrders();
      onActionComplete({
        type: 'price_update',
        payload: { order, updatedOrder: { ...order, price: newPrice } },
      });
      addSnackbar('Carrier Price Updated', { variant: 'success' });

      hideEditForm();
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <>
      {title ? (
        <Stack space="xxsmall">
          <StyledStack
            onClick={showEditForm}
            alwaysShowIcon={platform !== 'desktop'}
          >
            <Stack space="none">
              <Inline verticalAlign="center" space="xxsmall">
                <Typography variant="caption" color="textSecondary">
                  {title}
                </Typography>
                {canEditPrice(order) && <Edit style={{ fontSize: '16px' }} />}
              </Inline>

              <StyledRoot alignItems="center">
                <Box>
                  <OrderListPayment
                    amount={order.price}
                    terms={order.payment?.terms}
                    method={order.payment?.method}
                    highlightPrice={isBelowPrice}
                  />

                  {!!order.price && !!order.distance_meters && (
                    <OrderPricePerMile
                      order={order}
                      TypographyProps={{ variant: 'caption' }}
                    />
                  )}
                </Box>
              </StyledRoot>
            </Stack>
          </StyledStack>

          <FeatureToggle name="recommended-price.enabled.ui">
            <PricingRecommendationOrderList
              order={order}
              onPriceClick={(value) => {
                formik.setFieldValue('newPrice', value);
                void formik.submitForm();
              }}
            />
          </FeatureToggle>
        </Stack>
      ) : (
        <StyledRoot alignItems="center" onClick={showEditForm}>
          <Box>
            <OrderListPayment
              amount={order.price}
              terms={order.payment?.terms}
              method={order.payment?.method}
            />
            {!!order.price && !!order.distance_meters && (
              <OrderPricePerMile
                order={order}
                TypographyProps={{ variant: 'caption' }}
              />
            )}
          </Box>

          {canEditPrice(order) && (
            <Box ml={2}>
              <Edit />
            </Box>
          )}
        </StyledRoot>
      )}

      {canEditPrice(order) && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={hideEditForm}
          anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
          transformOrigin={{ vertical: 'center', horizontal: 'left' }}
        >
          <Paper>
            <Box p={2}>
              <FormikProvider value={formik}>
                <Form noValidate={true}>
                  <Grid container={true} spacing={1}>
                    <Grid item={true}>
                      <FormikCurrencyField
                        name="newPrice"
                        autoFocus={true}
                        onFocus={handleInputFocus}
                        validate={required}
                      />
                    </Grid>

                    <Grid item={true}>
                      <StyledButton
                        color="primary"
                        variant="outlined"
                        onClick={hideEditForm}
                        disabled={formik.isSubmitting}
                      >
                        <CloseIcon />
                      </StyledButton>
                    </Grid>

                    <Grid item={true}>
                      <StyledButton
                        type="submit"
                        color="primary"
                        variant="contained"
                        isLoading={formik.isSubmitting}
                      >
                        <CheckIcon />
                      </StyledButton>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </Box>
          </Paper>
        </Popover>
      )}
    </>
  );
};
