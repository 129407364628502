import { Link, MenuItem } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { useState } from 'react';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';
import { SHIPPER_TYPE } from '../data/VerificationApplicationDTO';
import { ShipperTypeHelpDrawer } from './ShipperTypeHelpDrawer';

export const SHIPPER_TYPES_MAP: Array<[typeof SHIPPER_TYPE[number], string]> = [
  ['ONLINE_MARKETPLACE', 'Online Marketplace'],
  ['BROKER', 'Broker'],
  ['DEALER', 'Dealer'],
  ['CARRIER_DRIVER', 'Carrier/Driver'],
  ['OEM', 'OEM'],
  ['AUCTION', 'Auction'],
  ['RENTAL', 'Rental'],
  ['IMPORT_EXPORT', 'Import/Export'],
  ['FINANCIAL_INSTITUTION', 'Financial Institution'],
  ['WHOLESALER', 'Wholesaler'],
  ['UPLIFTER', 'Uplifter'],
];

export function YourCompanyForm() {
  const [currentDrawer, setCurrentDrawer] = useState<
    'shipper_type_help' | undefined
  >();

  return (
    <Stack space="small">
      <ShipperTypeHelpDrawer
        open={currentDrawer === 'shipper_type_help'}
        onClose={() => setCurrentDrawer(undefined)}
      />
      <FormikTextField
        name="company_type"
        label="Shipper Type"
        helperText={
          <Link
            color="primary"
            href="#"
            onClick={(event) => {
              event.preventDefault();
              trackVerificationApplicationEvent({
                name: 'Shipper Clicked on Learn More',
                utm_content: 'Shipper Type',
              });
              setCurrentDrawer('shipper_type_help');
            }}
          >
            Learn more
          </Link>
        }
        select={true}
        fullWidth={true}
      >
        {SHIPPER_TYPES_MAP.map(([value, label]) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </FormikTextField>

      <FormikTextField
        name="legal_company_name"
        label="Legal Company Name"
        fullWidth={true}
      />

      <FormikTextField
        name="dba_company_name"
        label="Doing Business As (Optional)"
        fullWidth={true}
      />

      <Stack>
        <FormikTextField
          name="street"
          label="Company Address:"
          placeholder="Street"
          fullWidth={true}
        />

        <Columns space="xsmall">
          <Column>
            <FormikTextField name="city" placeholder="City" />
          </Column>
          <Column>
            <FormikTextField name="state" placeholder="State" />
          </Column>
          <Column>
            <FormikTextField name="zip" placeholder="ZIP Code" />
          </Column>
        </Columns>
      </Stack>
    </Stack>
  );
}
