import { Drawer, IconButton, Link } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  Button,
  DrawerTitle,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { plainToClass } from 'class-transformer';
import { Link as RouterLink } from 'react-router-dom';
import Order from 'shared/types/order';
import { ExitTransitionPlaceholder } from 'shared/ui/ExitTransitionPlaceholder';
import { LoadingContainer } from 'shared/ui/LoadingContainer';
import {
  CarrierTags,
  UnverifiedTag,
  VerifiedTag,
} from '../../../core/CarrierTags';
import { DictionaryCarrierDTO } from '../../../core/dictionary/data/DictionaryDTO';
import { TotalOrdersDispatchedLink } from '../../../core/TotalOrdersDispatchedLink';
import { useUserState } from '../../../shared/data/AppUserState';
import { formatCurrency } from '../../../shared/helpers/IntlHelpers';
import {
  useInstantDispatchCarriers,
  useInstantDispatchMutation,
} from '../../data/InstantDispatchAPI';
import {
  OrderActionSource,
  trackOderActionEvent,
} from './OrderActionsAnalytics';

interface InstantDispatchDialogContentProps {
  order: Order;
  source: OrderActionSource;
  onSubmitSuccess: () => void;
}

export function InstantDispatchDrawerContent({
  order,
  source,
  onSubmitSuccess,
}: InstantDispatchDialogContentProps) {
  const { user } = useUserState();
  const { addSnackbar } = useSnackbarStack();
  const { data: carriers } = useInstantDispatchCarriers(order);

  const {
    mutate: instantDispatch,
    isLoading,
    variables = [],
  } = useInstantDispatchMutation({
    onError(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
    onSuccess() {
      onSubmitSuccess();
      trackOderActionEvent({
        name: 'Shipper Instant Dispatched',
        order,
        user,
        source,
      });
    },
  });

  const [_activeOrderGuid, activeCarrierGuid] = variables;

  if (!carriers) {
    return <LoadingContainer />;
  }

  return (
    <Stack space="small">
      <Box paddingLeft="large">
        <TextBox variant="heading-4">Carriers Ready for Dispatch</TextBox>
      </Box>

      <Stack space="small">
        {carriers.objects.map(
          (
            {
              name,
              lane_guid,
              guid,
              price,
              is_verified,
              price_per_mile,
              max_delivery_eta,
              min_delivery_eta,
              broker_records,
            },
            index,
          ) => {
            return (
              <Box
                padding="large"
                key={index}
                borderBottomWidth="small"
                borderBottomColor="Silver400"
                paddingTop={index === 0 ? 'none' : 'small'}
              >
                <Stack space="small">
                  <Stack>
                    <TextBox variant="heading-3">
                      {formatCurrency(price, { maximumFractionDigits: 0 })}{' '}
                      <TextBox variant="caption" color="secondary">
                        {formatCurrency(price_per_mile, {
                          minimumFractionDigits: 2,
                        })}
                        /mi
                      </TextBox>
                    </TextBox>

                    {(!!min_delivery_eta || !!max_delivery_eta) && (
                      <TextBox variant="body-semibold">
                        {[min_delivery_eta, max_delivery_eta]
                          .filter(Boolean)
                          .join('-')}{' '}
                        <TextBox color="secondary">days for delivery</TextBox>
                      </TextBox>
                    )}

                    <Inline>
                      <Link
                        component={RouterLink}
                        target="_blank"
                        to={`/manage-carriers/${guid}/{?utm_medium,utm_content}`}
                      >
                        <TextBox variant="heading-5">{name}</TextBox>
                      </Link>

                      {is_verified ? <VerifiedTag /> : <UnverifiedTag />}
                    </Inline>

                    <CarrierTags carrierBrokerPreferences={broker_records} />

                    <TotalOrdersDispatchedLink
                      source="shipper_profile"
                      loadsCount={broker_records.accepted_loads_count}
                      carrier={plainToClass(DictionaryCarrierDTO, {
                        name,
                        guid,
                      })}
                    />
                  </Stack>

                  <Button
                    variant="outlined"
                    fullWidth={true}
                    isLoading={isLoading && activeCarrierGuid === guid}
                    disabled={isLoading && activeCarrierGuid !== guid}
                    onClick={() => {
                      instantDispatch([order.guid, guid, lane_guid, price]);
                    }}
                  >
                    Instant Dispatch
                  </Button>
                </Stack>
              </Box>
            );
          },
        )}
      </Stack>
    </Stack>
  );
}

interface InstantDispatchDrawerProps
  extends Omit<InstantDispatchDialogContentProps, 'order' | 'onClose'> {
  order?: Order;
  onClose: () => void;
}

export function InstantDispatchDrawer({
  order,
  onClose,
  ...props
}: InstantDispatchDrawerProps) {
  const open = !!order;
  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerTitle
        title="Instant Dispatch Available"
        endAction={
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        }
      />

      <ExitTransitionPlaceholder in={open}>
        {!!order && <InstantDispatchDrawerContent {...props} order={order} />}
      </ExitTransitionPlaceholder>
    </Drawer>
  );
}
