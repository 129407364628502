import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { updateSearchQuery } from 'shared/helpers/RouteHelpers';

export function useTerminalRouteHelpers() {
  const history = useHistory();

  return useMemo(
    () => ({
      goToTerminalCreate() {
        history.push('/terminals/create');
      },
      goToTerminalsImport() {
        history.push('/terminals/import');
      },
      goToTerminalDetails(terminalGUID?: string, replace?: boolean) {
        const location = {
          pathname: '/terminals',
          search: updateSearchQuery(history.location.search, (prev) => ({
            ...prev,
            current_terminal_guid: terminalGUID,
          })),
        };

        if (replace) {
          history.replace(location);
        } else {
          history.push(location);
        }
      },
    }),
    [history],
  );
}
