import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import { Color } from '@superdispatch/ui';
import { debounce } from 'lodash-es';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useQueryParams } from '../../shared/helpers/RouteHelpers';
import { TerminalsPageParamsDTO } from '../data/TerminalsPageParamsDTO';
import { useTerminalRouteHelpers } from '../TerminalsRouteHelpers';

export function TerminalsSidebarSearch() {
  const { goToTerminalDetails } = useTerminalRouteHelpers();
  const [{ query }, setParams] = useQueryParams(TerminalsPageParamsDTO);

  const [input, setInput] = useState<string>('');

  // eslint-disable-next-line eslint-comments/no-use
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchWithDebounce = useCallback(
    debounce((updatedQuery: string) => {
      setParams({ query: updatedQuery });

      if (!updatedQuery || (updatedQuery && updatedQuery.length > 2)) {
        goToTerminalDetails();
      }
    }, 500),
    [goToTerminalDetails, setParams],
  );

  const handleSearch = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setInput(value);
      handleSearchWithDebounce(value);
    },
    [setInput, handleSearchWithDebounce],
  );

  const clearSearch = useCallback(() => {
    setInput('');
    setParams({ query: null });

    goToTerminalDetails();
  }, [setInput, setParams, goToTerminalDetails]);

  useEffect(() => {
    setInput(query || '');
  }, [query]);

  return (
    <TextField
      fullWidth={true}
      placeholder="3 symbols to search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search color="action" />
          </InputAdornment>
        ),
        endAdornment: input && (
          <InputAdornment position="end">
            <IconButton onClick={clearSearch}>
              <Close htmlColor={Color.Grey300} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={input || ''}
      onChange={handleSearch}
    />
  );
}
