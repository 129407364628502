import {
  mdiBulletinBoard,
  mdiDownload,
  mdiShieldAlert,
  mdiShieldCar,
  mdiUpload,
} from '@mdi/js';
import { createSvgIcon } from './IconUtils';

export const UploadIcon = createSvgIcon('UploadIcon', mdiUpload);
export const DownloadIcon = createSvgIcon('DownloadIcon', mdiDownload);
export const ShieldCarIcon = createSvgIcon('ShieldCarIcon', mdiShieldCar);
export const BulletinBoardIcon = createSvgIcon(
  'BulletinBoardIcon',
  mdiBulletinBoard,
);
export const ShieldAlertIcon = createSvgIcon('ShieldAlertIcon', mdiShieldAlert);
