import { Menu, MenuItem } from '@material-ui/core';
import { Button, Color, SuperDispatchTheme } from '@superdispatch/ui';
import {
  DictionaryKey,
  DictionaryValues,
  formatDictionary,
} from 'core/dictionary/data/DictionaryDTO';
import { DictionaryAutocomplete } from 'core/dictionary/DictionaryAutocomplete';
import { useDictionaryList } from 'core/dictionary/DictionaryList';
import { SingleBooleanFilter } from 'core/dictionary/SingleBooleanFilter';
import { ActionDivider } from 'orders/core/actions/OrderActionUtils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useUserValue } from 'shared/data/UserPermissions';
import { FilterIcon } from 'shared/icons/FilterIcon';
import { ScrollableViewport } from 'shared/ui/ScrollableViewport';
import styled, { css } from 'styled-components';
import { PaymentMethodAutocomplete } from '../../core/dictionary/PaymentMethodAutocomplete';
import { useProductTiers } from '../../shared/data/TiersUtils';

export const Wrap = styled.div`
  padding: 16px;
  box-sizing: border-box;
  border-bottom: solid 1px #eaebec;
  position: relative;
  background: ${Color.White};
`;

export const Row = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

const ListWrap = styled.div(
  ({ theme }: { theme: SuperDispatchTheme }) => css`
    position: relative;
    margin-right: 8px;
    max-width: 85%;

    ${theme.breakpoints.down('sm')} {
      max-width: 60%;
    }
  `,
);

const StyledButton = styled(Button)`
  flex-shrink: 0;
  height: 32px;
  line-height: 20px;
  margin-right: 8px;
`;

const brokerFilters: Set<DictionaryKey> = new Set([
  'tags',
  'statuses',
  'pickup_addresses',
  'delivery_addresses',
  'delivered_on_date',
  'customer_names',
  'carriers',
  'dispatcher_names',
  'salespersons',
  'lot_numbers',
  'payment_statuses',
  'payment_methods',
  'is_superpay_available_carrier',
  'is_carrier_requested_superpay',
  'is_expedited_payment',
]);

const customerFilters: Set<DictionaryKey> = new Set([
  'statuses',
  'pickup_addresses',
  'delivery_addresses',
  'contact_names',
]);

function useUserFilters(status: string | undefined): Set<DictionaryKey> {
  const { user } = useUserState();
  const { isBasicTier } = useProductTiers();
  const items = useUserValue(brokerFilters, customerFilters);
  const isBtachPayinEnabled = useFeatureToggle(
    'superpay-manual-batch.access.ui',
  );
  const isSuperPayExpeditedPayEnabled = useFeatureToggle(
    'payments.expedited-pay.access.ui',
  );

  return useMemo(() => {
    const filters = new Set(items);

    if (status) {
      filters.delete('statuses');
    }

    if (status === 'requests') {
      filters.delete('carriers');
      filters.delete('delivered_on_date');
      filters.delete('payment_statuses');
      filters.delete('is_expedited_payment');
    }

    if (!isSuperPayExpeditedPayEnabled) {
      filters.delete('is_expedited_payment');
    }

    if (!user?.shipper.had_superpay_access) {
      filters.delete('payment_statuses');
      filters.delete('payment_methods');
      filters.delete('is_expedited_payment');
    }

    if (status !== 'superpay_delivery_verified') {
      filters.delete('is_superpay_available_carrier');
    }

    if (status !== 'invoiced') {
      filters.delete('is_carrier_requested_superpay');
    }

    if (isBasicTier) {
      filters.delete('tags');
      filters.delete('customer_names');
    }

    if (!isBtachPayinEnabled) {
      filters.delete('delivered_on_date');
    }

    return filters;
  }, [
    items,
    status,
    isSuperPayExpeditedPayEnabled,
    user?.shipper.is_super_pay_enabled,
    isBasicTier,
    isBtachPayinEnabled,
  ]);
}

interface OrderListFilterProps {
  status?: string;
  filters: DictionaryValues;

  onRemove: (key: DictionaryKey) => void;
  onChange: <T extends DictionaryKey>(
    key: T,
    value: DictionaryValues[T],
  ) => void;
}

export function OrderListFilter({
  status,
  onChange,
  onRemove,
  filters,
}: OrderListFilterProps) {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement | null>(null);

  const userFilters = useUserFilters(status);
  const { disabled, availableItems, activeItems, setActive, getProps } =
    useDictionaryList({
      onRemove,
      onChange,
      values: filters,
      items: userFilters,
    });

  const GetFilter = (type: DictionaryKey) => {
    switch (type) {
      case 'pickup_addresses':
      case 'delivery_addresses':
        return <DictionaryAutocomplete key={type} {...getProps(type)} />;
      case 'carriers':
        return <DictionaryAutocomplete key={type} {...getProps(type)} />;
      case 'is_superpay_available_carrier':
      case 'is_carrier_requested_superpay':
      case 'is_expedited_payment':
        return <SingleBooleanFilter key={type} {...getProps(type)} />;
      case 'payment_methods':
        return <PaymentMethodAutocomplete key={type} {...getProps(type)} />;
      default:
        return <DictionaryAutocomplete key={type} {...getProps(type)} />;
    }
  };

  useEffect(() => {
    // Remove filters that are not available for current status.
    Object.keys(filters).forEach((key) => {
      if (!userFilters.has(key as DictionaryKey)) {
        onRemove(key as DictionaryKey);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <Row>
      <ListWrap>
        <ScrollableViewport disableScrollbar={true}>
          {activeItems.map((type) => GetFilter(type))}
        </ScrollableViewport>
      </ListWrap>

      {activeItems.length > 0 ? (
        <StyledButton
          ref={ref}
          color="primary"
          variant="outlined"
          disabled={disabled}
          onClick={() => setOpen((prev) => !prev)}
        >
          <FilterIcon />
        </StyledButton>
      ) : (
        <StyledButton
          ref={ref}
          color="primary"
          variant="outlined"
          disabled={disabled}
          startIcon={<FilterIcon />}
          onClick={() => setOpen((prev) => !prev)}
          style={{
            fontSize: '14px',
            padding: '6px 16px',
          }}
        >
          Add Filter
        </StyledButton>
      )}

      <Menu open={open} anchorEl={ref.current} onClose={() => setOpen(false)}>
        {availableItems.map((type) => (
          <>
            <MenuItem
              key={type}
              onClick={() => {
                setOpen(false);
                // wait menu close animation
                requestAnimationFrame(() => {
                  setActive(type);
                });
              }}
            >
              {formatDictionary(type)}
            </MenuItem>
            {shouldAddDivider(type) && <ActionDivider key={type} />}
          </>
        ))}
      </Menu>
    </Row>
  );
}

function shouldAddDivider(type: DictionaryKey) {
  return [
    'tags',
    'statuses',
    'delivered_on_date',
    'carriers',
    'salespersons',
    'lot_numbers',
  ].includes(type);
}
