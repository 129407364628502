import { Link } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { FormikCheckboxField } from '@superdispatch/forms';
import { Inline, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { usePermission } from 'shared/data/UserPermissions';
import { useSuperPaySettings } from '../data/SuperPayAPI';

export function BatchPaymentSettings() {
  const { data } = useSuperPaySettings();
  const canManageSuperPay = usePermission('MANAGE_SUPERPAY', 'canExecute');
  const isAutomatic = data?.superpay_flow === 'AUTOMATIC';
  const isDisabled = isAutomatic || !canManageSuperPay;
  return (
    <FormikCheckboxField
      name="can_batch_payin"
      label={
        <TextBox color={isDisabled ? 'secondary' : 'inherit'}>
          Combine “SuperPay Now” into one batch charge
        </TextBox>
      }
      disabled={isDisabled}
      helperText={
        <Box maxWidth={['330px', '100%']}>
          <Stack>
            <TextBox color="secondary">
              Enable this to consolidate several individual bank charges into a
              single transaction.{' '}
              <Link href="" target="_blank" rel="noreferrer">
                Learn more
              </Link>
            </TextBox>
            {isAutomatic && (
              <Inline verticalAlign="center" space="xxsmall">
                <Info color="primary" fontSize="small" />
                <TextBox>Available for Manual Workflow only</TextBox>
              </Inline>
            )}
          </Stack>
        </Box>
      }
    />
  );
}
