import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { updateSearchQuery } from 'shared/helpers/RouteHelpers';

export function useCustomerRouteHelpers() {
  const history = useHistory();

  return useMemo(
    () => ({
      goToCustomerCreate() {
        history.push('/customers/create');
      },
      goToCustomerDetails(customerID?: string | number, replace?: boolean) {
        const location = {
          pathname: '/customers',
          search: updateSearchQuery(history.location.search, (prev) => ({
            ...prev,
            current_customer_id: customerID,
          })),
        };

        if (replace) {
          history.replace(location);
        } else {
          history.push(location);
        }
      },
    }),
    [history],
  );
}
