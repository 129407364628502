import { Switch } from 'react-router';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { BankAccountPage } from './bank-account/BankAccountPage';
import { BusinessDetailsPage } from './business-details/BusinessDetailsPage';
import { MoovServiceContextProvider } from './MoovServiceContext';
import { OwnersListPage } from './owners/OwnersListPage';
import { SuperPayPromoPage } from './promo/SuperPayPromoPage';
import { ROUTES_SUPERPAY, SuperPayRoute } from './SuperPayRoute';
import { SuperPaySettingsPage } from './SuperPaySettingsPage';

export const SuperPayContainer = () => {
  useDocumentTitle('SuperPay');

  return (
    <MoovServiceContextProvider>
      <Switch>
        <SuperPayRoute path={ROUTES_SUPERPAY.businessDetails}>
          <BusinessDetailsPage />
        </SuperPayRoute>

        <SuperPayRoute path={ROUTES_SUPERPAY.owners}>
          <OwnersListPage />
        </SuperPayRoute>

        <SuperPayRoute path={ROUTES_SUPERPAY.bankDetails}>
          <SuperPaySettingsPage />
        </SuperPayRoute>

        <SuperPayRoute path={ROUTES_SUPERPAY.bankAccount}>
          <BankAccountPage />
        </SuperPayRoute>

        <SuperPayRoute path={ROUTES_SUPERPAY.main}>
          <SuperPayPromoPage />
        </SuperPayRoute>
      </Switch>
    </MoovServiceContextProvider>
  );
};
