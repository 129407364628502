import { Drawer, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import { PaymentMethod } from '@superdispatch/sdk';
import {
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { round } from 'lodash-es';
import { useOrderPaymentFlags } from 'orders/data/OrderPaymentFlagsAPI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AllPaymentTerm } from 'shared/dto/Order/CarrierPaymentDTO';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useQuery } from 'shared/helpers/RouteHelpers';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import { OrderFormValues } from '../orders/core/form/OrderForm';
import { useSuperPayEdit } from './data/SuperPayAPI';
import {
  PaymentMethods,
  ResultsInformation,
  VehiclesTable,
} from './SuperPayEditDrawerFormContent';

interface SuperPayEditableVehicle {
  guid: string;
  price: number;
  is_active: boolean;
  title: string;
}

interface ValuesEditForm {
  terms: AllPaymentTerm;
  method: PaymentMethod;
  editableVehicles: SuperPayEditableVehicle[];
}

interface SuperPayEditFormProps {
  superPayChargedAmount: number;
  totalCarrierPrice: number;
  totalCarrierPriceMinusFee: number;
  expeditedPayFeeAmount: number;
  refundAmount: number;
  isPaymentMethodChanged: boolean;
  canReviseVehiclePrice: boolean;
  editableVehicles: SuperPayEditableVehicle[];
  isTotalCarrierPriceNotBeZero: boolean;
}

interface SuperPayEditDrawerProps {
  order: OrderFormValues;
  onClose: () => void;
  open: boolean;
  onSubmitSuccess: (value: ValuesEditForm) => void;
}

function vehiclePrices(values: SuperPayEditableVehicle[], feeValue: number) {
  const priceValue = values.reduce((total, { price = 0 }) => total + price, 0);
  const totalCarrierPrice = round(priceValue, 2);
  return {
    totalCarrierPrice,
    totalCarrierPriceMinusFee: totalCarrierPrice - feeValue,
  };
}

function SuperPayDrawerForm({
  superPayChargedAmount,
  totalCarrierPrice,
  totalCarrierPriceMinusFee,
  expeditedPayFeeAmount,
  refundAmount,
  editableVehicles,
  isPaymentMethodChanged,
  canReviseVehiclePrice,
  isTotalCarrierPriceNotBeZero,
}: SuperPayEditFormProps) {
  const [query] = useQuery();

  useEffect(() => {
    trackEvent('Shipper Opened Edit SuperPay Drawer', {
      utm_medium: query.utm_medium ?? 'Order View',
    });
  }, [query]);

  return (
    <Stack space="large">
      <Stack space={['small', 'xsmall']}>
        <VehiclesTable
          isDisabled={!canReviseVehiclePrice}
          editableVehicles={editableVehicles}
          isPaymentMethodChanged={isPaymentMethodChanged}
        />
        <ResultsInformation
          refundAmount={refundAmount}
          totalCarrierPrice={totalCarrierPrice}
          totalCarrierPriceMinusFee={totalCarrierPriceMinusFee}
          expeditedPayFeeAmount={expeditedPayFeeAmount}
          isPaymentMethodChanged={isPaymentMethodChanged}
          superPayChargedAmount={superPayChargedAmount}
          isTotalCarrierPriceNotBeZero={isTotalCarrierPriceNotBeZero}
        />
      </Stack>
      <PaymentMethods isPaymentMethodChanged={isPaymentMethodChanged} />
    </Stack>
  );
}

export function SuperPayEditDrawer({
  open,
  order,
  onClose,
  onSubmitSuccess,
}: SuperPayEditDrawerProps) {
  const { vehicles, payment, id } = order;
  const { mutateAsync } = useSuperPayEdit(id);
  const { addSnackbar } = useSnackbarStack();
  const hasVehicles = !!vehicles.length;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const orderPaymentFlags = useOrderPaymentFlags(order.id);
  const expeditedPayFeeAmount =
    order.payment?.super_pay?.expedited_pay_fee_amount || 0;
  const superPayChargedAmount = payment?.super_pay?.amount || 0;
  const superPayStatus = payment?.super_pay?.status;

  const initialValues: ValuesEditForm = useMemo(() => {
    const editableVehicles = hasVehicles
      ? vehicles.map((vehicle) => ({
          price: vehicle.price || 0,
          guid: vehicle.guid || '',
          is_active: !!vehicle.is_active,
          title: `${vehicle.year || ''} ${vehicle.make || ''} ${
            vehicle.model || ''
          }`,
        }))
      : [];

    return {
      terms: payment?.terms || '1_3_days',
      method: payment?.method || 'superpay',
      editableVehicles,
    };
  }, [hasVehicles, payment?.method, payment?.terms, vehicles]);

  const formik = useFormikEnhanced({
    initialValues,
    onSubmit: ({ editableVehicles, terms, method }) => {
      const { totalCarrierPrice } = vehiclePrices(
        editableVehicles,
        expeditedPayFeeAmount,
      );
      return mutateAsync({
        price: totalCarrierPrice,
        vehicles: editableVehicles,
        terms,
        method,
      });
    },
    onSubmitFailure: (error) =>
      addSnackbar(error.message, { variant: 'error' }),
    onSubmitSuccess: (_, values) => {
      onSubmitSuccess(values);
      onClose();
      addSnackbar('SuperPay updated', { variant: 'success' });
    },
  });

  const {
    values: { editableVehicles, method },
    initialValues: { method: initialMethodValue },
    resetForm,
    isSubmitting,
    dirty,
    status,
  } = formik;

  const handleDrawerClose = useCallback(() => {
    if (isSubmitting) {
      return;
    }

    if (dirty && status.type !== 'submitted') {
      setShowConfirmation(true);
    } else {
      onClose();
    }
  }, [isSubmitting, dirty, status, onClose]);

  const discardChanges = () => {
    setShowConfirmation(false);
    resetForm();
    onClose();
  };

  const { totalCarrierPrice, totalCarrierPriceMinusFee } = vehiclePrices(
    editableVehicles,
    expeditedPayFeeAmount,
  );
  const isPaymentMethodChanged = initialMethodValue !== method;
  const isTotalCarrierPriceNotBeZero =
    totalCarrierPriceMinusFee === 0 &&
    !isPaymentMethodChanged &&
    (superPayStatus === 'CREATED' || superPayStatus === 'CHARGE_SCHEDULED');

  const refundAmount = useMemo(() => {
    if (
      isPaymentMethodChanged ||
      superPayChargedAmount === totalCarrierPriceMinusFee
    ) {
      return superPayChargedAmount;
    }

    if (superPayChargedAmount > totalCarrierPriceMinusFee) {
      return superPayChargedAmount - totalCarrierPriceMinusFee;
    }

    return 0;
  }, [
    isPaymentMethodChanged,
    superPayChargedAmount,
    totalCarrierPriceMinusFee,
  ]);

  return (
    <>
      <Drawer open={open} onClose={handleDrawerClose}>
        <Box width={['initial', '448px']}>
          <FormikProvider value={formik}>
            <DrawerTitle
              title="Edit SuperPay"
              endAction={
                <IconButton
                  edge="end"
                  onClick={handleDrawerClose}
                  disabled={isSubmitting}
                >
                  <Close />
                </IconButton>
              }
            />
            <Form aria-labelledby="superpay edit drawer form">
              <DrawerContent>
                {hasVehicles ? (
                  <SuperPayDrawerForm
                    totalCarrierPrice={totalCarrierPrice}
                    totalCarrierPriceMinusFee={totalCarrierPriceMinusFee}
                    expeditedPayFeeAmount={expeditedPayFeeAmount}
                    refundAmount={refundAmount}
                    superPayChargedAmount={superPayChargedAmount}
                    editableVehicles={editableVehicles}
                    isPaymentMethodChanged={isPaymentMethodChanged}
                    canReviseVehiclePrice={
                      !!orderPaymentFlags?.can_revise_price
                    }
                    isTotalCarrierPriceNotBeZero={isTotalCarrierPriceNotBeZero}
                  />
                ) : (
                  <Stack align="center">
                    <Typography color="textSecondary">No data</Typography>
                  </Stack>
                )}
              </DrawerContent>

              {hasVehicles && (
                <DrawerActions>
                  <Button
                    type="submit"
                    pending={isSubmitting}
                    disabled={
                      !refundAmount ||
                      isTotalCarrierPriceNotBeZero ||
                      !!(expeditedPayFeeAmount && method === 'superpay')
                    }
                  >
                    Save
                  </Button>
                </DrawerActions>
              )}
            </Form>
          </FormikProvider>
        </Box>
      </Drawer>
      <ConfirmDialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        title="Discard unsaved changes?"
        cancelButtonProps={{ children: 'Cancel' }}
        confirmButtonProps={{
          onClick: discardChanges,
          children: 'Discard',
        }}
      >
        Changes have not been saved. Leaving will result in unsaved changes
        being lost.
      </ConfirmDialog>
    </>
  );
}
