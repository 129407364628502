import { Redirect, Route, Switch } from 'react-router';
import { SubscriptionPlansExpressPage } from './express-subscription-plan/SubscriptionPlansExpressPage';
import { SubscriptionPlansProPage } from './pro-subscription-plan/SubscriptionPlansProPage';
import { SubscriptionPlansIndexPage } from './SubscriptionPlansIndexPage';

export function SubscriptionPlansPage() {
  return (
    <Switch>
      <Route exact={true} path="/subscription-plans">
        <SubscriptionPlansIndexPage />
      </Route>

      <Route path="/subscription-plans/shipper-express">
        <SubscriptionPlansExpressPage />
      </Route>

      <Route path="/subscription-plans/shipper-pro">
        <SubscriptionPlansProPage />
      </Route>

      <Route path="*">
        <Redirect to="/subscription-plans" />
      </Route>
    </Switch>
  );
}
