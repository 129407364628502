import { SidebarContainer } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useTrackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { useQueryParams } from '../shared/helpers/RouteHelpers';
import { CustomersSelection } from './core/CustomersSelection';
import { CustomerCreatePage } from './create/CustomerCreatePage';
import { CustomersImportPage } from './CustomersImportPage';
import { useCustomersList } from './data/CustomerAPI';
import { CustomerPageParamsDTO } from './data/CustomersPageParamsDTO';
import { CustomerDetailsPage } from './details/CustomerDetailsPage';
import { CustomersEmptyList } from './details/CustomersEmptyList';
import { CustomersList } from './list/CustomersList';

export function CustomersPage() {
  const [{ query }] = useQueryParams(CustomerPageParamsDTO);
  const { list = [], isLoading } = useCustomersList(query);
  const [selectedCustomers, setSelectedCustomers] = useState<Set<number>>(
    new Set(),
  );

  useDocumentTitle('Customers');
  useTrackEvent('[STMS] Viewed Customers Page');

  return (
    <SidebarContainer
      sidebar={
        <CustomersList
          selectedCustomers={selectedCustomers}
          onSelectionChange={setSelectedCustomers}
        />
      }
    >
      <Switch>
        <Route path="/customers/create">
          <CustomerCreatePage />
        </Route>

        <Route path="/customers/import">
          <CustomersImportPage />
        </Route>

        <Route>
          {selectedCustomers.size > 1 ? (
            <CustomersSelection
              count={selectedCustomers.size}
              onClear={() => {
                setSelectedCustomers(new Set());
              }}
            />
          ) : !query && !isLoading && list.length === 0 ? (
            <CustomersEmptyList />
          ) : (
            <CustomerDetailsPage customers={list} />
          )}
        </Route>
      </Switch>
    </SidebarContainer>
  );
}
