import { useFormikEnhanced } from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Form, FormikProvider } from 'formik';
import { useOnboarding } from 'shared/context/OnboardingContext';
import { useInitialValues } from 'shared/form/AppFormik';
import { camelCaseFormErrorsGetter } from 'shared/helpers/FormHelpers';
import { LoadingContainer } from 'shared/ui/LoadingContainer';
import {
  performNavigation,
  useNavigationBlock,
} from '../../shared/routing/NavigationBlock';
import { InsuranceForm } from '../core/InsuranceForm';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';
import {
  useVerificationApplication,
  useVerificationApplicationAPI,
} from '../data/VerificationApplicationAPI';
import {
  VerificationInsuranceDTO,
  verificationInsuranceSchema,
} from '../data/VerificationApplicationDTO';
import {
  VerificationApplicationStepsBox,
  VerificationApplicationStepsWrapper,
} from './VerificationApplicationLayout';
import { VerificationStepper } from './VerificationStepper';

type VerificationInsuranceForm = Omit<
  VerificationInsuranceDTO,
  'insurance_certificate_url' | 'company_insurance_type'
> & {
  insurance_certificate_url: string | File | null;
  company_insurance_type:
    | VerificationInsuranceDTO['company_insurance_type']
    | null;
};

interface VerificationApplicationInsuranceProps {
  onSubmitSuccess: () => void;
}

export function VerificationApplicationInsurance({
  onSubmitSuccess,
}: VerificationApplicationInsuranceProps) {
  const { addSnackbar } = useSnackbarStack();
  const { data, isLoading } = useVerificationApplication({
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
  const { updateInsurance, uploadFile } = useVerificationApplicationAPI();
  const { completeStep } = useOnboarding();

  const initialValues = useInitialValues(verificationInsuranceSchema, data);

  const formik = useFormikEnhanced({
    initialValues,
    validationSchema: verificationInsuranceSchema,
    getFormErrors: camelCaseFormErrorsGetter,
    async onSubmit(values: VerificationInsuranceForm) {
      if (values.insurance_certificate_url instanceof File) {
        values.insurance_certificate_url = await uploadFile(
          values.insurance_certificate_url,
        );
      }

      return updateInsurance(values as VerificationInsuranceDTO);
    },
    onSubmitSuccess() {
      completeStep('GET_VERIFIED');
      performNavigation(onSubmitSuccess);
    },
    onSubmitFailure() {
      addSnackbar(
        'Please ensure all required fields are filled out correctly',
        { variant: 'error' },
      );
    },
  });

  useNavigationBlock(
    formik.status.type !== 'submitted' &&
      (formik.dirty || formik.isSubmitting) &&
      'Changes have not been saved. Leaving will result in unsaved changes being lost.',
  );

  return (
    <FormikProvider value={formik}>
      <VerificationApplicationStepsWrapper
        step={3}
        onGoBack={() => {
          trackVerificationApplicationEvent({
            name: 'Shipper Clicked Back',
            utm_content: 'Insurance',
          });
        }}
      >
        <Stack space="xlarge">
          <VerificationStepper step={3} />
          {isLoading ? (
            <LoadingContainer />
          ) : (
            <Form>
              <VerificationApplicationStepsBox>
                <InsuranceForm />
              </VerificationApplicationStepsBox>
            </Form>
          )}
        </Stack>
      </VerificationApplicationStepsWrapper>
    </FormikProvider>
  );
}
