import { Card, CardContent, Typography } from '@material-ui/core';
import { Stack, useResponsiveValue } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useEffect } from 'react';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { usePaymentLogger } from 'superpay/helpers/PaymentLogger';
import { SuperPayOnboardingPageLayout } from 'superpay/SuperPayOnboardingPageLayout';
import { useSuperPaySettingsCache } from '../data/SuperPayAPI';
import { BusinessContacts } from './BusinessContacts';
import { BusinessContactsFormLegacy } from './BusinessContactsFormLegacy';
import { BusinessDetails } from './BusinessDetails';
import { BusinessDetailsFormLegacy } from './BusinessDetailsFormLegacy';
import { SuperPayTermsConditions } from './SuperPayTermsConditions';
import { useBusinessDetailsForm } from './useBusinessDetailsForm';

export function BusinessDetailsPage() {
  const { invalidateSuperPaySettings } = useSuperPaySettingsCache();
  const isMobile = useResponsiveValue(true, false);
  const { logPaymentInfo } = usePaymentLogger();
  const isChangedSuperPayOnboarding = useFeatureToggle(
    'superpay-onboarding.access.ui',
  );

  const formik = useBusinessDetailsForm({
    onComplete: () => {
      trackEvent('Shipper Submitted Business Details');
      invalidateSuperPaySettings();
    },
  });

  const { isSubmitting, isValid } = formik;

  useEffect(() => {
    trackEvent('Shipper Opened Business Details');
    logPaymentInfo('Opened Business Details', 'BusinessDetailsForm');
  }, [logPaymentInfo]);

  return (
    <SuperPayOnboardingPageLayout stepNumber={1}>
      <FormikProvider value={formik}>
        <Form aria-label="Business details">
          <Stack space="small">
            {isChangedSuperPayOnboarding ? (
              <Card square={isMobile}>
                <BusinessDetails />
              </Card>
            ) : (
              <Card square={isMobile}>
                <CardContent>
                  <Stack>
                    <Typography variant="h3">Business Details</Typography>
                    <BusinessDetailsFormLegacy />
                  </Stack>
                </CardContent>
              </Card>
            )}

            {isChangedSuperPayOnboarding ? (
              <Card square={isMobile}>
                <BusinessContacts />
              </Card>
            ) : (
              <Card square={isMobile}>
                <CardContent>
                  <Stack>
                    <Typography variant="h3">Business Contacts</Typography>
                    <BusinessContactsFormLegacy />
                  </Stack>
                </CardContent>
              </Card>
            )}

            <Card square={isMobile}>
              <CardContent>
                <Stack space="small">
                  <SuperPayTermsConditions />
                  <Button
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    pending={isSubmitting}
                  >
                    Continue
                  </Button>
                  {!isValid && (
                    <Typography color="error">
                      Complete all required fields to continue.
                    </Typography>
                  )}
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        </Form>
      </FormikProvider>
    </SuperPayOnboardingPageLayout>
  );
}
