import { SidebarContainer } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useTrackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import { useQueryParams } from '../shared/helpers/RouteHelpers';
import { TerminalCreatePage } from './create/TerminalCreatePage';
import { TerminalsPageParamsDTO } from './data/TerminalsPageParamsDTO';
import { TerminalDetailsPage } from './details/TerminalDetailsPage';
import { TerminalEmptyState } from './list/TerminalEmptyState';
import { TerminalsList } from './list/TerminalsList';
import { useTerminals } from './list/TerminalsListState';
import { TerminalsSelection } from './list/TerminalsSelection';
import { TerminalsImportPage } from './TerminalsImportPage';

export function TerminalsPage() {
  const { list: terminals } = useTerminals();
  const [{ query }] = useQueryParams(TerminalsPageParamsDTO);
  const [selectedTerminals, setSelectedTerminals] = useState(
    () => new Set<number>(),
  );

  useDocumentTitle('Terminals');
  useTrackEvent('[STMS] Viewed Terminals Page');

  return (
    <PageLayout>
      <SidebarContainer
        sidebar={
          <TerminalsList
            selectedTerminals={selectedTerminals}
            onSelectedTerminalsChange={setSelectedTerminals}
          />
        }
      >
        <Switch>
          <Route path="/terminals/create">
            <TerminalCreatePage />
          </Route>

          <Route path="/terminals/import">
            <TerminalsImportPage />
          </Route>

          <Route>
            {selectedTerminals.size ? (
              <TerminalsSelection
                count={selectedTerminals.size}
                onClear={() => {
                  setSelectedTerminals(new Set());
                }}
              />
            ) : !query && terminals?.length === 0 ? (
              <TerminalEmptyState />
            ) : (
              <TerminalDetailsPage />
            )}
          </Route>
        </Switch>
      </SidebarContainer>
    </PageLayout>
  );
}
