import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { VerificationApplicationPage } from './VerificationApplicationPage';
import { VerificationUnderwayPage } from './VerificationUnderwayPage';
import { VerifiedApplicationInfoPage } from './VerifiedApplicationInfoPage';

export function VerificationApplicationContainer() {
  const location = useLocation();
  return (
    <Switch>
      <Route path="/verification-application/info">
        <VerifiedApplicationInfoPage />
      </Route>
      <Route path="/verification-application/underway">
        <VerificationUnderwayPage />
      </Route>
      <Route path="/verification-application/step-*">
        <VerificationApplicationPage />
      </Route>

      <Route path="*">
        <Redirect to={`/verification-application/info${location.search}`} />
      </Route>
    </Switch>
  );
}
