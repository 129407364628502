import { Link, TextField, Typography } from '@material-ui/core';
import { Add, ArrowForward } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { Button, Inline, Stack, Tag } from '@superdispatch/ui';
import { useField } from 'formik';
import { get } from 'lodash-es';
import {
  forwardRef,
  HTMLAttributes,
  MouseEvent,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import { CounterpartyContactDTO } from 'shared/dto/CounterpartyContactDTO';
import styled from 'styled-components';

interface AddAsNewButtonProps {
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

function AddAsNewButton({ onClick }: AddAsNewButtonProps) {
  return (
    <Button
      variant="text"
      startIcon={<Add />}
      onClick={onClick}
      onMouseDown={(event) => {
        // Prevent blur
        event.preventDefault();
      }}
    >
      Save as New Contact
    </Button>
  );
}

interface ContinueButtonProps {
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

function ContinueButton({ onClick }: ContinueButtonProps) {
  return (
    <Button
      variant="text"
      startIcon={<ArrowForward />}
      onClick={onClick}
      onMouseDown={(event) => {
        // Prevent blur
        event.preventDefault();
      }}
    >
      Continue without Saving
    </Button>
  );
}

const ListboxOption = styled.div`
  padding: 6px 16px;
`;

const ListboxComponent = forwardRef<
  HTMLUListElement,
  HTMLAttributes<HTMLElement> & { secondaryContent?: ReactNode }
>(({ children, secondaryContent, ...props }, ref) => (
  <ul {...props} ref={ref}>
    {children}

    {secondaryContent}
  </ul>
));

ListboxComponent.displayName = 'ListboxComponent';

export type CounterpartyContactChangeReason =
  | 'select-option'
  | 'save-as-new'
  | 'dont-save'
  | 'clear'
  | 'close';

interface CounterpartyContactAutocompleteProps {
  name: string;
  isNew: boolean;
  showContinue?: boolean;
  options?: CounterpartyContactDTO[];
  validate?: (value: CounterpartyContactDTO) => void;
  onChange?: (
    value: Partial<CounterpartyContactDTO>,
    reason: CounterpartyContactChangeReason,
  ) => void;
}

export function CounterpartyContactAutocomplete({
  name,
  isNew,
  validate,
  showContinue,
  options = [],
  onChange: onChangeProp,
}: CounterpartyContactAutocompleteProps) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');

  const [{ value, onBlur }, { error }, { setValue }] = useField({
    name,
    validate,
  });

  const inputValue = useMemo(() => value || '', [value]);

  const nameError = get(error, 'name');

  const hasSimilarContact = useMemo(
    () =>
      !!value?.name &&
      options.some((x) =>
        x.name.toLowerCase().includes(value.name.toLowerCase()),
      ),
    [options, value],
  );

  function handleChange(
    autocompleteValue: Partial<CounterpartyContactDTO>,
    reason: CounterpartyContactChangeReason,
  ) {
    setValue(autocompleteValue);
    onChangeProp?.(autocompleteValue, reason);
  }

  function handleAsNewSave(reason: CounterpartyContactChangeReason) {
    setOpen(false);
    handleChange({ ...value, id: undefined, name: query }, reason);
  }

  function handleContinue() {
    setOpen(false);
    handleChange({ ...value, name: query }, 'dont-save');
  }

  return (
    <Autocomplete
      ListboxComponent={ListboxComponent}
      ListboxProps={{
        secondaryContent: (
          <>
            {showContinue && (
              <ListboxOption>
                <ContinueButton onClick={handleContinue} />
              </ListboxOption>
            )}

            <ListboxOption>
              <AddAsNewButton onClick={() => handleAsNewSave('save-as-new')} />
            </ListboxOption>
          </>
        ),
      }}
      open={open}
      onBlur={onBlur}
      onOpen={() => setOpen(true)}
      onClose={(_, reason) => {
        setOpen(false);

        if (
          query.length > 0 &&
          query !== value?.name &&
          reason !== 'toggleInput' &&
          reason !== 'select-option'
        ) {
          handleAsNewSave('close');
        }
      }}
      value={inputValue}
      options={options}
      clearOnBlur={false}
      onChange={(_, updatedValue) => {
        if (updatedValue) {
          handleChange(updatedValue, 'select-option');
        } else {
          handleChange({}, 'clear');
        }
      }}
      onInputChange={(_, input) => setQuery(input)}
      getOptionSelected={(option, selectedValue) =>
        option.name === selectedValue.name
      }
      getOptionLabel={(option) => option.name || ''}
      noOptionsText={
        query.length === 0 ? (
          'No option'
        ) : (
          <Stack>
            {showContinue && <ContinueButton onClick={handleContinue} />}

            <AddAsNewButton onClick={() => handleAsNewSave('save-as-new')} />
          </Stack>
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!nameError}
          label={
            <Inline>
              <Typography color="textPrimary">Contact Name</Typography>

              {isNew && (
                <Tag variant="subtle" color="blue">
                  New Contact
                </Tag>
              )}
            </Inline>
          }
          helperText={
            nameError ? (
              nameError
            ) : isNew && hasSimilarContact ? (
              <Typography component="span">
                Similar contact already exists.{' '}
                <Link href="#" color="primary" onClick={() => setOpen(true)}>
                  Review
                </Link>
              </Typography>
            ) : undefined
          }
        />
      )}
    />
  );
}
