import { Container, IconButton, Toolbar, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { Color, Inline } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { ReactNode } from 'react';
import { useHistory } from 'react-router';
import { PageLayout } from 'shared/layout/PageLayout';
import styled from 'styled-components';

interface VerificationApplicationLayoutProps {
  onBackButton?: () => void;
  children: ReactNode;
}

export function VerificationApplicationLayout({
  onBackButton,
  children,
}: VerificationApplicationLayoutProps) {
  return (
    <PageLayout
      contentBackground={Color.White}
      header={
        <Toolbar>
          <Inline verticalAlign="center">
            <IconButton
              aria-label="go back"
              onClick={onBackButton}
              size="small"
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h2">Verification Application</Typography>
          </Inline>
        </Toolbar>
      }
    >
      {children}
    </PageLayout>
  );
}

export const VerificationApplicationStepsBox = styled(Container)`
  max-width: 500px;
  padding: 0;
`;

export const VerificationApplicationStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;

  height: 100%;
  width: 100%;
`;

export const VerificationApplicationStepsContent = styled.div`
  display: flex;
  flex: 1 1 100%;
`;

export const VerificationApplicationBottomBar = styled.div`
  top: auto;
  bottom: 0;
  position: sticky;
  padding: 16px 40px;
  margin: 0 -16px -16px;
  background-color: ${Color.White};
  border-top: 1px solid ${Color.Silver200};

  display: flex;
  justify-content: flex-end;
  gap: 16px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 16px;
  }
`;

interface VerificationApplicationWrapperProps {
  showBackButton: boolean;
  onBackNavigation?: () => void;
  onSubmit: () => void;
  saveButtonTitle?: string;
  children: ReactNode;
}

export function VerificationApplicationWrapper({
  showBackButton,
  onBackNavigation,
  onSubmit,
  saveButtonTitle = 'Save and Continue',
  children,
}: VerificationApplicationWrapperProps) {
  return (
    <VerificationApplicationStepsContainer>
      <VerificationApplicationStepsContent>
        {children}
      </VerificationApplicationStepsContent>
      <VerificationApplicationBottomBar>
        {showBackButton && (
          <Button
            type="submit"
            variant="neutral"
            size="small"
            onClick={onBackNavigation}
          >
            Back
          </Button>
        )}
        <Button type="submit" variant="primary" size="small" onClick={onSubmit}>
          {saveButtonTitle}
        </Button>
      </VerificationApplicationBottomBar>
    </VerificationApplicationStepsContainer>
  );
}

interface VerificationStepperProps {
  step: number;
  children: ReactNode;
  onGoBack?: () => void;
}

export function VerificationApplicationStepsWrapper({
  step,
  children,
  onGoBack,
}: VerificationStepperProps) {
  const history = useHistory();
  const formik = useFormikContext();
  return (
    <VerificationApplicationWrapper
      showBackButton={step > 1}
      onBackNavigation={() => {
        onGoBack?.();
        history.push(
          `/verification-application/step-${step - 1}${
            history.location.search
          }`,
        );
      }}
      onSubmit={() => formik.handleSubmit()}
    >
      {children}
    </VerificationApplicationWrapper>
  );
}
