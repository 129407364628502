import { CardContent, IconButton, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { PhoneText, usePhoneService } from '@superdispatch/phones';
import { Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { MouseEvent, useState } from 'react';
import styled from 'styled-components';
import { BusinessDTO } from '../data/BusinessDTO';
import {
  BusinessContactsEditDrawer,
  Source,
} from './BusinessContactsEditDrawer';
import { EmptyField } from './EmptyField';

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export function BusinessContacts() {
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [sourceDrawer, setSourceDrawer] = useState<Source>();
  const { setValues, values } = useFormikContext<BusinessDTO>();
  const { phoneNumber, streetAddress, city, state, zip, website } = values;

  const phoneService = usePhoneService();
  const formattedPhone = phoneService.format(phoneNumber);
  const isFullAddress = !!(streetAddress && city && zip && state);

  const openDrawer = (
    e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>,
    source: Source,
  ) => {
    e.preventDefault();
    setOpenDrawer(true);
    setSourceDrawer(source);
  };

  return (
    <>
      <BusinessContactsEditDrawer
        source={sourceDrawer}
        open={isOpenDrawer}
        onClose={() => setOpenDrawer(false)}
        onSave={(data) => setValues({ ...values, ...data })}
        initialValues={values}
      />
      <CardContent>
        <Stack space="small">
          <StyledBox>
            <Typography variant="h3">Business Contacts</Typography>
            <IconButton
              size="small"
              onClick={(e) => openDrawer(e, 'Edit Icon')}
            >
              <Edit fontSize="medium" color="action" />
            </IconButton>
          </StyledBox>
          <Stack space="small">
            <Stack>
              <Typography color="textSecondary">Address</Typography>
              <EmptyField
                isDisabled={!isFullAddress}
                onClick={(e) => openDrawer(e, 'Add Link')}
              >
                <Typography>
                  {`${streetAddress}, ${city}, ${state} ${zip}`}
                </Typography>
              </EmptyField>
            </Stack>

            <Stack>
              <Typography color="textSecondary">
                Phone (US numbers only)
              </Typography>
              <EmptyField
                isDisabled={!formattedPhone}
                onClick={(e) => openDrawer(e, 'Add Link')}
              >
                <Typography>
                  <PhoneText phone={formattedPhone} fallback={formattedPhone} />
                </Typography>
              </EmptyField>
            </Stack>

            <Stack>
              <Typography color="textSecondary">Website (Optional)</Typography>
              <EmptyField
                isDisabled={!website}
                onClick={(e) => openDrawer(e, 'Add Link')}
              >
                <Typography>{website}</Typography>
              </EmptyField>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </>
  );
}
