import { plainToClass } from 'class-transformer';
import { useAPI } from 'shared/api/API';
import { useAPIQuery } from 'shared/api/APIQuery';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { Order } from 'shared/types/order';
import {
  APIMutationOptions,
  useAPIMutation,
} from '../../shared/api/APIMutation';
import { useProductTiers } from '../../shared/data/TiersUtils';
import { InstantDispatchCarriersDTO } from './dto/InstantDispatchDTO';
import { useSingleOrderActionAPI } from './OrderActionAPI';

export function useInstantDispatchCarriers(order: Order | undefined) {
  const { requestPage } = useAPI();
  const hasInstantDispatchAccess = useFeatureToggle(
    'instant-dispatch.access.ui',
  );
  const { isAdvancedTier } = useProductTiers();

  return useAPIQuery(
    [
      'order',
      'instant-dispatch-carriers',
      {
        guid: order?.guid,
        status: order?.status,
        is_on_hold: order?.is_on_hold,
      },
    ],
    () => {
      return requestPage(
        'GET /internal/orders/{orderGuid}/instant_dispatch/carriers',
        (data) => plainToClass(InstantDispatchCarriersDTO, data),
        { orderGuid: order?.guid },
      );
    },
    { enabled: hasInstantDispatchAccess && isAdvancedTier && !!order },
  );
}

export function useCanInstantDispatch(order: Order | undefined): boolean {
  const { data: carriers } = useInstantDispatchCarriers(order);
  return (
    !!carriers?.objects.length &&
    (!!order?.is_on_hold ||
      order?.status === 'declined' ||
      order?.status === 'canceled' ||
      order?.status === 'new')
  );
}

export function useInstantDispatchMutation(
  options?: APIMutationOptions<
    [
      orderGuid: string,
      carrierGuid: string,
      laneGuid: string,
      expectedPrice: number,
    ]
  >,
) {
  const { instantDispatch } = useSingleOrderActionAPI();
  return useAPIMutation<
    [
      orderGuid: string,
      carrierGuid: string,
      laneGuid: string,
      expectedPrice: number,
    ]
  >((args) => instantDispatch(...args), options);
}
