import { useSnackbarStack } from '@superdispatch/ui';
import { useAppFormik } from 'shared/form/AppFormik';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useMoovSyncAPI } from 'shared/helpers/superpay/MoovHelpers';
import { performNavigation } from 'shared/routing/NavigationBlock';
import { useShipperProfile } from 'shipper-profile/data/ShipperProfileAPI';
import { usePaymentLogger } from 'superpay/helpers/PaymentLogger';
import { bankAccountValidationSchema } from './BankAccountFormValues';

interface BankAccountFormArgs {
  onComplete: () => void;
}

export function useBankAccountForm({ onComplete }: BankAccountFormArgs) {
  const { addSnackbar } = useSnackbarStack();
  const { linkSyncMoovBankAccount } = useMoovSyncAPI();
  const { data: profile } = useShipperProfile();
  const { logPaymentInfo } = usePaymentLogger();

  return useAppFormik({
    initialValues: {
      ...bankAccountValidationSchema.getDefault(),
      holderName: profile?.name,
    },
    validationSchema: bankAccountValidationSchema,
    onSubmit: (data) => linkSyncMoovBankAccount(data),
    onSubmitSuccess: () => {
      trackEvent('Shipper Submitted Bank Details');
      performNavigation(onComplete);
      logPaymentInfo('Submitted Bank Details', 'VerifyBankAccountForm');
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });
}
