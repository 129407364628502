import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import { Color } from '@superdispatch/ui';
import { debounce } from 'lodash-es';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useQueryParams } from '../../shared/helpers/RouteHelpers';
import { useCustomerRouteHelpers } from '../CustomersRouteHelpers';
import { CustomerPageParamsDTO } from '../data/CustomersPageParamsDTO';

export function CustomersSearchField() {
  const { goToCustomerDetails } = useCustomerRouteHelpers();
  const [{ query }, setParams] = useQueryParams(CustomerPageParamsDTO);
  const [value, setValue] = useState<string>('');

  const handleSearchWithDebounce = useCallback(
    debounce((updatedQuery: string) => {
      setParams({ query: updatedQuery });

      if (!updatedQuery || (updatedQuery && updatedQuery.length > 2)) {
        goToCustomerDetails();
      }
    }, 500),
    [setParams, goToCustomerDetails],
  );

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchQuery = event.target.value;
      setValue(searchQuery);
      handleSearchWithDebounce(searchQuery);
    },
    [setValue, handleSearchWithDebounce],
  );

  const clearSearch = useCallback(() => {
    setValue('');
    setParams({ query: undefined });
    goToCustomerDetails();
  }, [goToCustomerDetails, setValue, setParams]);

  useEffect(() => {
    setValue(query || '');
  }, [query]);

  return (
    <TextField
      fullWidth={true}
      placeholder="3 symbols to search"
      value={value || ''}
      onChange={handleSearch}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search color="action" />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position="end">
            <IconButton onClick={clearSearch}>
              <Close htmlColor={Color.Grey300} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
