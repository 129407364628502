import { useEffect } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { usePermission } from 'shared/data/UserPermissions';
import { useQueryParams } from 'shared/helpers/RouteHelpers';
import { CarriersPageParamsDTO } from '../data/CarriersPageParamsDTO';
import { ManageCarriersTable } from './ManageCarriersTable';
import { PrivateNetworkAccess } from './PrivateNetworkAccess';
import { AddPrivateNetworkGroupButton } from './PrivateNetworkAddGroup';
import { PrivateNetworkGroupsSidebar } from './PrivateNetworkGroupsSidebar';
import { RemovePrivateNetworkButton } from './PrivateNetworkRemove';

export function PrivateNetworkTab() {
  const { user } = useUserState();
  const [{ group_guid }, setParams] = useQueryParams(CarriersPageParamsDTO);
  const canUpdateCarrierProfile = usePermission(
    'UPDATE_CARRIER_PROFILE_FOR_BROKER',
    'canExecute',
  );

  useEffect(() => {
    return () => setParams({ group_guid: undefined });
  }, [setParams]);

  if (!user?.shipper.is_private_network_enabled) {
    return <PrivateNetworkAccess />;
  }

  return (
    <PrivateNetworkGroupsSidebar
      activeGroup={group_guid}
      onSelect={(group) => {
        setParams({ group_guid: group?.guid });
      }}
    >
      <ManageCarriersTable
        carrierType="private-network"
        TableContainerProps={{
          style: {
            width: 'auto',
            margin: 16,
            marginBottom: 0,
          },
        }}
        renderActions={
          !canUpdateCarrierProfile
            ? undefined
            : (carrierGuids, clearSelectedCarriers) => (
                <>
                  <AddPrivateNetworkGroupButton
                    selectedGroupGuid={group_guid}
                    carrierGuids={carrierGuids}
                    onSubmitSuccess={() => {
                      clearSelectedCarriers();
                    }}
                  />

                  <RemovePrivateNetworkButton
                    groupGuid={group_guid}
                    carrierGuids={carrierGuids}
                    onSubmitSuccess={() => {
                      clearSelectedCarriers();
                    }}
                  />
                </>
              )
        }
      />
    </PrivateNetworkGroupsSidebar>
  );
}
