import { IconButton } from '@material-ui/core';
import { Add, Business, Delete, Edit, Person, Phone } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import { SuspendedPhoneLink } from '@superdispatch/phones';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Button, DescriptionItem } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { Redirect, Route, RouteChildrenProps, useHistory } from 'react-router';
import { useTryBack } from 'shared/helpers/RouteHelpers';
import { Confirmation } from 'shared/ui/Confirmation';
import { useShipperProfileAPI } from '../data/ShipperProfileAPI';
import { ShipperProfileDTO } from '../data/ShipperProfileDTO';
import { isEmpty } from '../data/ShipperProfileUtils';
import { EmptySection, SectionLayout } from '../SectionLayout';
import { isValidIndex, ReferralEditDrawer } from './ReferralEditDrawer';

interface ReferencesSectionProps {
  profile: ShipperProfileDTO;
  onUpdate?: () => void;
}

export function ReferencesSection({
  profile,
  onUpdate,
}: ReferencesSectionProps) {
  const history = useHistory();
  const { tryBack } = useTryBack();
  const { addSnackbar } = useSnackbarStack();
  const { updateReferrals } = useShipperProfileAPI();

  const formik = useFormikEnhanced<{ referralIndex?: number }, unknown>({
    initialValues: { referralIndex: undefined },
    onSubmit: ({ referralIndex }) => {
      if (isValidIndex(referralIndex)) {
        return updateReferrals(
          profile.referrals.filter((_, index) => referralIndex !== index),
        );
      }

      return Promise.reject('Invalid index');
    },
    onSubmitSuccess: () => {
      addSnackbar('Profile updated successfully', { variant: 'success' });
      onUpdate?.();
    },
    onSubmitFailure: () => {
      addSnackbar('Failed to delete reference', { variant: 'error' });
    },
  });

  const isContentEmpty = useMemo(
    () =>
      isEmpty(profile, ['referrals']) ||
      profile.referrals.every((referral) =>
        isEmpty(referral, ['contact_name', 'name', 'phone']),
      ),
    [profile],
  );

  const closeDrawer = () => tryBack({ pathname: '/profile' });

  const openAddReferralDrawer = () => history.push('/profile/references/add');

  const openEditDrawer = (index: number) =>
    history.push(`/profile/references/${index + 1}/edit`);

  return (
    <>
      <Route path="/profile/references/:referralIndex/edit">
        {({ match }: RouteChildrenProps<{ referralIndex: string }>) => (
          <ReferralEditDrawer
            open={!!match}
            referrals={profile.referrals}
            onClose={closeDrawer}
            onUpdate={onUpdate}
            editReferralIndex={Number(match?.params.referralIndex) - 1}
          />
        )}
      </Route>

      <Route path="/profile/references/add">
        {({ match }) => (
          <ReferralEditDrawer
            referrals={profile.referrals}
            open={!!match}
            onClose={closeDrawer}
            onUpdate={onUpdate}
          />
        )}
      </Route>

      <Route path="/profile/referrals/add">
        <Redirect to="/profile/references/add" />
      </Route>

      {isContentEmpty ? (
        <EmptySection onClick={openAddReferralDrawer} startIcon={<Add />}>
          Add Reference
        </EmptySection>
      ) : (
        <SectionLayout
          title="References"
          editComponent={
            profile.referrals.length < 3 ? (
              <Button
                aria-label="add reference"
                variant="text"
                size="small"
                onClick={openAddReferralDrawer}
              >
                <Inline verticalAlign="center">
                  <Add fontSize="inherit" />
                  Add New
                </Inline>
              </Button>
            ) : undefined
          }
        >
          <Stack space="large">
            {profile.referrals.map((referral, index) => (
              <Columns key={`${index}-${String(referral.name)}`}>
                <Column>
                  <Stack space="xxsmall">
                    {referral.name && (
                      <DescriptionItem
                        icon={<Business />}
                        label="Company"
                        wrap={true}
                      >
                        {referral.name}
                      </DescriptionItem>
                    )}
                    {referral.contact_name && (
                      <DescriptionItem
                        icon={<Person />}
                        label="Contact"
                        wrap={true}
                      >
                        {referral.contact_name}
                      </DescriptionItem>
                    )}
                    {referral.phone && (
                      <DescriptionItem icon={<Phone />}>
                        <SuspendedPhoneLink
                          phone={referral.phone}
                          fallback={referral.phone}
                        />
                      </DescriptionItem>
                    )}
                  </Stack>
                </Column>

                <Column width="content">
                  <Inline space="small">
                    <IconButton
                      size="small"
                      aria-label={`edit reference ${String(referral.name)}`}
                      onClick={() => openEditDrawer(index)}
                    >
                      <Edit fontSize="small" />
                    </IconButton>

                    <Confirmation
                      title="Delete this Reference?"
                      cancelText="No"
                      processText="Yes, Delete"
                      onProcess={() => formik.handleSubmit()}
                      isProcessing={formik.isSubmitting}
                      trigger={({ showConfirmation }) => (
                        <IconButton
                          size="small"
                          aria-label={`delete reference ${String(
                            referral.name,
                          )}`}
                          onClick={(e) => {
                            formik.setFieldValue('referralIndex', index);
                            showConfirmation(e);
                          }}
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      )}
                    />
                  </Inline>
                </Column>
              </Columns>
            ))}
          </Stack>
        </SectionLayout>
      )}
    </>
  );
}
