import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { AddAPhoto, Edit } from '@material-ui/icons';
import { AvatarButton, useSnackbarStack } from '@superdispatch/ui';
import { useField } from 'formik';
import { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { OrderFormVehiclePhotoDTO } from '../data/OrderFormVehicleDTO';

const PhotoButton = styled(AvatarButton)`
  margin: 6px 0;

  .MuiAvatar-root {
    width: 48px;
    height: 36px;
    border-radius: 4px;
  }

  .SD-AvatarButton-overlay {
    border-radius: 4px; /* TODO Support rounded avatar for overlay in sd/ui*/
  }
`;

interface Props {
  name: string;
}

export function VehicleSamplePhotoField({ name }: Props) {
  const { addSnackbar } = useSnackbarStack();
  const inputRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [field, , { setValue }] = useField<OrderFormVehiclePhotoDTO[]>(name);
  const thumbnail = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    () => field.value?.find((x) => x.photo_type === 'Sample'),
    [field.value],
  );

  const photoUrl = useMemo(
    () =>
      !thumbnail
        ? null
        : thumbnail.photo_file
        ? URL.createObjectURL(thumbnail.photo_file)
        : thumbnail.photo_url,
    [thumbnail],
  );

  function hideMenu() {
    setAnchorEl(null);
  }

  function openUploadDialog() {
    inputRef.current?.click();
  }

  return (
    <>
      {!photoUrl ? (
        <IconButton onClick={openUploadDialog}>
          <AddAPhoto />
        </IconButton>
      ) : (
        <PhotoButton
          aria-label="thumbnail"
          size="small"
          icon={<Edit />}
          src={photoUrl}
          onClick={({ currentTarget }) => {
            setAnchorEl(currentTarget);
          }}
        >
          <AddAPhoto />
        </PhotoButton>
      )}

      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={hideMenu}>
        <MenuItem
          onClick={() => {
            openUploadDialog();
            hideMenu();
          }}
        >
          Update Photo
        </MenuItem>
        <MenuItem
          onClick={() => {
            hideMenu();
            setValue([]);
          }}
        >
          Remove
        </MenuItem>
      </Menu>
      <input
        type="file"
        hidden={true}
        ref={inputRef}
        accept=".jpg,.jpeg,.png"
        onClick={(event) => {
          // Allow choosing same file
          // https://stackoverflow.com/questions/4109276/how-to-detect-input-type-file-change-for-the-same-file/54632736#54632736
          event.currentTarget.value = '';
        }}
        onChange={({ currentTarget: { files } }) => {
          const file = files?.[0];

          const maxAllowedSize = 20 * 1024 * 1024;

          if (file) {
            if (file.size > maxAllowedSize) {
              addSnackbar('The photo should be less than 20 Mb');
            } else if (thumbnail) {
              setValue([
                {
                  ...thumbnail,
                  photo_file: file,
                },
              ]);
            } else {
              setValue([
                {
                  photo_type: 'Sample',
                  photo_file: file,
                },
              ]);
            }
          }
        }}
      />
    </>
  );
}
