import { Grid, MenuItem, Typography } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { InfoTooltip, Stack } from '@superdispatch/ui';
import { isSuperpayPayment } from 'core/SuperPayUtils';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { useProductTiers } from 'shared/data/TiersUtils';
import { OrderFormValues } from './OrderForm';
import { OrderFormInstantBooking } from './OrderFormInstantBooking';
import { OrderTagsField } from './OrderTagsField';
import { PaperBox } from './PaperBox';

const transportTypes = [
  { label: 'Open', value: 'OPEN' },
  { label: 'Enclosed', value: 'ENCLOSED' },
  { label: 'Driveaway', value: 'DRIVEAWAY' },
];

const inspectionTypes = [
  { label: 'Standard', value: 'standard' },
  { label: 'Advanced', value: 'advanced' },
  { label: 'AIAG', value: 'aiag' },
];

export const LoadID = ({
  values,
  source,
}: {
  values: OrderFormValues;
  source: 'order_edit_page' | 'order_create_page';
}) => {
  const { user } = useUserState();
  const isOrderCreate = source === 'order_create_page';
  const { setFieldValue } = useFormikContext();
  const { isAdvancedTier } = useProductTiers();

  const isInspectionTypeDisabled =
    values.inspection_type === 'advanced' && isSuperpayPayment(values.payment);

  useEffect(() => {
    if (isSuperpayPayment(values.payment)) {
      setFieldValue('inspection_type', 'advanced');
    }
  }, [setFieldValue, values.payment]);

  return (
    <PaperBox>
      <Stack space="small">
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} md={6} lg={isAdvancedTier ? 3 : 6}>
            <FormikTextField
              name="number"
              fullWidth={true}
              inputProps={{
                maxLength: '50',
              }}
              label={
                <>
                  <Typography display="inline" color="textPrimary">
                    Order ID
                  </Typography>{' '}
                  <Typography display="inline" color="textSecondary">
                    (Required)
                  </Typography>
                </>
              }
              autoFocus={true}
            />
          </Grid>

          {isAdvancedTier && user?.shipper.shipper_type === 'BROKER' && (
            <Grid item={true} xs={12} md={6} lg={3}>
              <OrderTagsField />
            </Grid>
          )}

          {(user?.order_form_settings.is_purchase_order_number_visible ||
            values.purchase_order_number) && (
            <Grid item={true} xs={12} md={6} lg={2}>
              <FormikTextField
                name="purchase_order_number"
                fullWidth={true}
                inputProps={{
                  maxLength: '50',
                }}
                label={<Typography color="textPrimary">PO Number</Typography>}
              />
            </Grid>
          )}

          {(isOrderCreate
            ? user?.order_form_settings.is_transport_type_visible
            : user?.order_form_settings.is_transport_type_visible ||
              values.transport_type) && (
            <Grid item={true} xs={12} md={6} lg={2}>
              <FormikTextField
                name="transport_type"
                label={
                  <Typography color="textPrimary">Transport Type</Typography>
                }
                select={true}
                fullWidth={true}
              >
                {transportTypes.map((transportType) => (
                  <MenuItem
                    key={transportType.value}
                    value={transportType.value}
                  >
                    {transportType.label}
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid>
          )}

          {user?.shipper.shipper_type === 'BROKER' && (
            <Grid item={true} xs={12} md={6} lg={2}>
              <FormikTextField
                name="inspection_type"
                disabled={isInspectionTypeDisabled}
                label={
                  <InfoTooltip
                    title={
                      <>
                        Standard inspection doesn&apos;t have any required steps
                        for drivers.
                        <br />
                        <br />
                        Advanced inspection requires drivers to take at least 6
                        photos at Pickup and Delivery. This type is required if
                        SuperPay is selected as payment method.
                        <br />
                        <br />
                        AIAG inspection is used for OEM customers and requires 2
                        photos per damage.
                      </>
                    }
                    TextProps={{
                      color: 'textPrimary',
                    }}
                  >
                    Inspection Type
                  </InfoTooltip>
                }
                select={true}
                fullWidth={true}
              >
                {inspectionTypes.map((inspectionType) => (
                  <MenuItem
                    key={inspectionType.value}
                    value={inspectionType.value}
                  >
                    {inspectionType.label}
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid>
          )}
        </Grid>

        <Grid container={true}>
          <Grid item={true}>
            <OrderFormInstantBooking status={values.status} />
          </Grid>
        </Grid>
      </Stack>
    </PaperBox>
  );
};
