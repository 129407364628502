import { MenuItem, Typography } from '@material-ui/core';
import { FormikPatternField, FormikTextField } from '@superdispatch/forms';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { usePredictions } from 'shared/helpers/MapboxHelpers';
import { useSearchInput } from 'shared/helpers/ReactHelpers';
import { USA_STATES } from 'shared/helpers/superpay/MoovDTO';
import { NewShipperMoovAccount } from '../../shared/helpers/superpay/MoovHelpers';

export function BusinessContactsFormLegacy() {
  const { setFieldValue, values, initialValues } =
    useFormikContext<NewShipperMoovAccount>();

  const { zip, city, state } = values;
  const isChangedZip = initialValues.zip !== zip;
  const shouldFillCity = !city || isChangedZip;
  const shouldFillState = !state || isChangedZip;
  const query = useSearchInput(
    (shouldFillCity || shouldFillState) && zip ? zip : '',
  );
  const { data: places = [] } = usePredictions(query);

  useEffect(() => {
    if (!places.length) {
      return;
    }
    if (shouldFillCity) {
      setFieldValue('city', places[0]?.place);
    }

    if (shouldFillState) {
      setFieldValue('state', places[0]?.region_short);
    }
  }, [shouldFillCity, shouldFillState, places, setFieldValue]);

  return (
    <Stack space="small">
      <FormikPatternField
        name="phoneNumber"
        mask="_"
        format="+1 ( ### ) ### - ####"
        allowEmptyFormatting={true}
        label={
          <Inline space="xxsmall">
            <Typography>Phone</Typography>
            <Typography color="textSecondary">(US numbers only)</Typography>
          </Inline>
        }
      />
      <FormikTextField fullWidth={true} label="Website" name="website" />
      <Stack space="small">
        <Typography variant="h4">Address</Typography>

        <FormikTextField
          label="Street Address"
          name="streetAddress"
          fullWidth={true}
          inputProps={{ maxLength: 60 }}
        />

        <Columns space="small">
          <Column width="1/4">
            <FormikTextField label="ZIP" name="zip" fullWidth={true} />
          </Column>

          <Column width="1/2">
            <FormikTextField
              label="City"
              name="city"
              fullWidth={true}
              inputProps={{ maxLength: 24 }}
            />
          </Column>

          <Column width="1/4">
            <FormikTextField
              label="State"
              name="state"
              select={true}
              fullWidth={true}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="" disabled={true}>
                <Typography color="textSecondary">Select</Typography>
              </MenuItem>
              {USA_STATES.map((code) => (
                <MenuItem key={code} value={code}>
                  {code}
                </MenuItem>
              ))}
            </FormikTextField>
          </Column>
        </Columns>
      </Stack>
    </Stack>
  );
}
