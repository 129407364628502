import { Menu, MenuItem } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import { AvatarButton, useSnackbarStack } from '@superdispatch/ui';
import { useMemo, useRef } from 'react';
import { useBoolean } from 'shared/helpers/ReactHelpers';
import { getInitials } from 'shared/utils/TextUtils';
import { trackProfileAvatarUpdate } from './data/ShipperProfileAnalytics';
import { useShipperProfileAPI } from './data/ShipperProfileAPI';
import { ShipperProfileDTO } from './data/ShipperProfileDTO';

interface AvatarUploadProps {
  shipper: ShipperProfileDTO;
}

const MAX_FILE_SIZE_IN_MB = 5;
const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MB * 1024 * 1024;
const MAX_FILE_SIZE_ERROR_MESSAGE = `The logo should be less than ${MAX_FILE_SIZE_IN_MB} Mb`;

export function ShipperProfileAvatar({
  shipper: { name: shipperName, logo_url: logoUrl },
}: AvatarUploadProps) {
  const { addSnackbar } = useSnackbarStack();
  const { uploadShipperProfileLogo, removeShipperProfileLogo } =
    useShipperProfileAPI();

  const [isMenuOpen, showMenu, hideMenu] = useBoolean(false);
  const anchorEl = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const avatarPlaceholder = useMemo(
    () => getInitials(shipperName),
    [shipperName],
  );
  const formik = useFormikEnhanced<{ file: File | null }, unknown>({
    initialValues: { file: null },
    onSubmit: ({ file }) => {
      if (file instanceof File) {
        if (file.size > MAX_FILE_SIZE_IN_BYTES) {
          return Promise.reject(new Error(MAX_FILE_SIZE_ERROR_MESSAGE));
        }

        return uploadShipperProfileLogo(file);
      }

      return removeShipperProfileLogo();
    },
    onSubmitSuccess: (_, { file }) => {
      const message =
        file instanceof File
          ? 'Logo uploaded successfully'
          : 'Logo removed successfully';

      addSnackbar(message, { variant: 'success' });
      trackProfileAvatarUpdate({ profileAvatar: 'updated' });
    },
    onSubmitFailure: (error, { file }) => {
      const message =
        error.message === MAX_FILE_SIZE_ERROR_MESSAGE
          ? MAX_FILE_SIZE_ERROR_MESSAGE
          : file instanceof File
          ? 'Failed to upload logo'
          : 'Failed to remove logo';

      addSnackbar(message, { variant: 'error' });
    },
  });

  const submitForm = (file: File | null) => {
    formik.setFieldValue('file', file);
    void formik.submitForm();
  };

  const openUploadDialog = () => {
    inputRef.current?.click();
  };

  return (
    <>
      <AvatarButton
        ref={anchorEl}
        onClick={logoUrl ? showMenu : openUploadDialog}
        icon={<Edit />}
        size="large"
        alt={shipperName}
        src={logoUrl || undefined}
        isLoading={formik.isSubmitting}
      >
        {avatarPlaceholder}
      </AvatarButton>

      <Menu open={isMenuOpen} onClose={hideMenu} anchorEl={anchorEl.current}>
        <MenuItem
          onClick={() => {
            openUploadDialog();
            hideMenu();
          }}
        >
          Change Logo
        </MenuItem>
        <MenuItem
          onClick={() => {
            submitForm(null);
            hideMenu();
          }}
        >
          Remove
        </MenuItem>
      </Menu>

      <input
        type="file"
        accept=".jpg,.jpeg,.png"
        ref={inputRef}
        hidden={true}
        onChange={({ target: { files } }) => {
          if (files?.[0]) {
            submitForm(files[0]);
          }
        }}
      />
    </>
  );
}
