import { Redirect, useHistory } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { getVerificationBackUrl } from '../core/VerificationBackUrl';
import {
  trackClosedVerificationApplicationEvent,
  trackVerificationApplicationEvent,
} from '../data/VerificationAnalyticsEvents';
import { useVerificationApplication } from '../data/VerificationApplicationAPI';
import { VerificationApplicationCompany } from './VerificationApplicationCompany';
import { VerificationApplicationIdentification } from './VerificationApplicationIdentification';
import { VerificationApplicationInsurance } from './VerificationApplicationInsurance';
import { VerificationApplicationLayout } from './VerificationApplicationLayout';

export function VerificationApplicationPage() {
  const history = useHistory();
  const { data } = useVerificationApplication();

  return (
    <VerificationApplicationLayout
      onBackButton={() => {
        trackClosedVerificationApplicationEvent(history.location.pathname);
        history.push(getVerificationBackUrl());
      }}
    >
      <Switch>
        {data && data.status !== 'NOT_SUBMITTED' && (
          <Route path="*">
            <Redirect to="/profile" />
          </Route>
        )}

        <Route path="/verification-application/step-1">
          <VerificationApplicationCompany
            onSubmitSuccess={() => {
              trackVerificationApplicationEvent({
                name: 'Shipper Continued to Identification Step',
              });
              history.push(
                `/verification-application/step-2${history.location.search}`,
              );
            }}
          />
        </Route>

        <Route path="/verification-application/step-2">
          <VerificationApplicationIdentification
            onSubmitSuccess={() => {
              trackVerificationApplicationEvent({
                name: 'Shipper Continued to Insurance Step',
              });
              history.push(
                `/verification-application/step-3${history.location.search}`,
              );
            }}
          />
        </Route>

        <Route path="/verification-application/step-3">
          <VerificationApplicationInsurance
            onSubmitSuccess={() => {
              trackVerificationApplicationEvent({
                name: 'Shipper Submitted Verification Application',
              });
              history.push(
                `/verification-application/underway${history.location.search}`,
              );
            }}
          />
        </Route>

        <Route path="*">
          <Redirect to="/verification-application/step-1" />
        </Route>
      </Switch>
    </VerificationApplicationLayout>
  );
}
