import { Box, Checkbox } from '@material-ui/core';
import { Color, useResponsiveValue } from '@superdispatch/ui';
import { OrderActions } from 'orders/core/actions/OrderActions';
import {
  BulkActionType,
  OrderBulkActions,
} from 'orders/core/bulk-actions/OrderBulkActions';
import { OrdersListCardView } from 'orders/core/list/OrdersListCardView';
import { useMemo, useState } from 'react';
import Order from 'shared/types/order';
import styled from 'styled-components';

interface OrderViewRelatedLoadsProps {
  loads: Order[];
  isLoading: boolean;
  refetchLoads: () => void;
}

const BulkActionsBox = styled(Box)`
  background: ${Color.White};
`;

export function OrderViewRelatedLoads({
  loads,
  isLoading,
  refetchLoads,
}: OrderViewRelatedLoadsProps) {
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isMobile = platform === 'mobile';
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const ordersIDs = useMemo(() => loads.map((x) => x.id), [loads]);
  const selectable = loads.length > 1;
  const selectedOrders = useMemo<Order[]>(() => {
    const result = [];

    for (let id of selectedIds) {
      const order = loads.find((x) => x.id === id);

      if (order) {
        result.push(order);
      }
    }

    return result;
  }, [selectedIds, loads]);

  function toggleSelectedOrder(id: number) {
    const set = new Set(selectedIds);

    if (set.has(id)) {
      set.delete(id);
    } else {
      set.add(id);
    }
    setSelectedIds(Array.from(set));
  }

  function handleBulkActionComplete(_type: BulkActionType) {
    refetchLoads();
  }

  return (
    <>
      {selectedOrders.length > 0 && (
        <BulkActionsBox py={2} px={4}>
          <OrderBulkActions
            source="Order View"
            orders={selectedOrders}
            onActionComplete={handleBulkActionComplete}
          />
        </BulkActionsBox>
      )}

      {!isMobile && selectable && (
        <Box px={platform !== 'desktop' ? 4 : 6} pt={2}>
          <Checkbox
            checked={selectedOrders.length === loads.length && !isLoading}
            onChange={() =>
              selectedOrders.length === loads.length
                ? setSelectedIds([])
                : setSelectedIds(ordersIDs)
            }
          />
        </Box>
      )}

      <Box py={isMobile ? 2 : 4} pl={isMobile ? 0 : 2} pt={2}>
        <OrdersListCardView
          orders={loads}
          isLoading={isLoading}
          selectedOrders={selectedOrders}
          toggleSelectOrder={toggleSelectedOrder}
          disableSelectable={!selectable}
          renderActions={(order: Order) => (
            <OrderActions order={order} source="orders_list" />
          )}
        />
      </Box>
    </>
  );
}
