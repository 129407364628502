import { CircularProgress } from '@material-ui/core';
import { Color, useSnackbarStack } from '@superdispatch/ui';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { isPreviewVariant, usePreviewFile } from './OrderPreviewAPI';

export interface OrderPreviewPageProps {
  type: string;
  orderID: string;
}
export function OrderPreviewPage({ type, orderID }: OrderPreviewPageProps) {
  const history = useHistory();
  const { addSnackbar } = useSnackbarStack();
  const { data: previewFile, error } = usePreviewFile(
    isPreviewVariant(type) ? { orderId: Number(orderID), type } : undefined,
  );

  const previewURL = useMemo(
    () => (previewFile ? URL.createObjectURL(previewFile) : undefined),
    [previewFile],
  );

  useEffect(() => {
    if (error) {
      addSnackbar(error.message, { variant: 'error' });
      history.goBack();
    }
  }, [error, history, addSnackbar]);
  return (
    <div
      style={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        background: Color.White,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {previewURL ? (
        <iframe title="Preview" src={previewURL} width="100%" height="100%" />
      ) : (
        <CircularProgress
          size={100}
          color="inherit"
          aria-label="loading"
          variant="indeterminate"
        />
      )}
    </div>
  );
}
