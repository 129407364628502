import { Link } from '@material-ui/core';
import { LocationDescriptor, LocationState } from 'history';
import { ReactNode } from 'react';
import { Link as RLink } from 'react-router-dom';

interface RouterLinkProps<S = LocationState> {
  to: LocationDescriptor<S>;
  children: ReactNode;
}

export function RouterLink<S = LocationState>(props: RouterLinkProps<S>) {
  return <Link component={RLink} {...props} />;
}
