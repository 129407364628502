import { Typography } from '@material-ui/core';
import { Color, Inline, Stack } from '@superdispatch/ui';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { ExpressSubscriptionPlanCard } from 'shared/subscription-plans/ExpressSubscriptionPlanCard';
import styled from 'styled-components';
import { SubscriptionSuccessDialog } from 'subscription-plans/SubscriptionSuccessDialog';
import { trackClickedSubscribeNow } from './PaywallAnalyticsEvents';
import { TrialUpgradeDialog } from './TrialUpgradeDialog';
import { usePaywallCounters } from './usePaywallCounters';

const StyledRoot = styled.div`
  position: relative;
  padding: 64px;
  max-width: 674px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  margin-top: 28px;
  margin-bottom: 68px;
  z-index: 0;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid ${Color.Silver30};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 490px;
    border-radius: inherit;
    z-index: -1;
    background: linear-gradient(
      270deg,
      rgba(0, 99, 219, 0.17) -0.59%,
      rgba(204, 238, 253, 0.17) 51.28%,
      rgba(254, 74, 73, 0.17) 100%
    );
    mask-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1) 64px
    );
  }
`;

const StyledStatCard = styled.div`
  background-color: ${Color.White};
  border-radius: 16px;
  padding: 20px 24px;
  display: flex;
  gap: 16px;
  width: 264px;
  align-items: center;
`;

const StyledStatCardNumber = styled.div<{ secondaryGradient?: boolean }>`
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;

  ${({ secondaryGradient }) => {
    if (secondaryGradient) {
      return 'background: linear-gradient(270deg, #A445FF -0.59%, #E14E5B 100%);';
    }

    return 'background: linear-gradient(270deg, #E053B9 -0.59%, #FD4B4B 100%);';
  }};

  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
`;

export function PaywallContent() {
  const history = useHistory();
  const { counters, isCountersLoading, hasSomeCounters } = usePaywallCounters();
  const [isUpgradeDialogOpen, setUpgradeDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setSuccessDialogOpen] = useState(false);

  const statCardItems = [
    {
      count: counters.pendingLoadRequests,
      label: 'Pending Load Requests',
    },
    {
      count: counters.loadsPostedToLoadBoard,
      label: 'Loads Posted to Load Board',
      isSecondaryGradient: true,
    },
    {
      count: counters.deliveredVehicles,
      label: 'Delivered Vehicles',
    },
  ];

  return (
    <StyledRoot>
      <Stack space={hasSomeCounters ? 'large' : 'small'}>
        <Stack space="small">
          <Typography variant="h1">Your Free Trial Has Ended</Typography>
          {!isCountersLoading && hasSomeCounters && (
            <>
              <Typography variant="h3">
                Keep the momentum going — here&apos;s an overview of your
                activity:
              </Typography>

              <Inline space="small">
                {statCardItems.map((entry) => {
                  if (entry.count === undefined) {
                    return null;
                  }

                  return (
                    <StyledStatCard key={entry.label}>
                      <StyledStatCardNumber
                        secondaryGradient={entry.isSecondaryGradient}
                      >
                        {entry.count}
                      </StyledStatCardNumber>
                      <Typography variant="h4">{entry.label}</Typography>
                    </StyledStatCard>
                  );
                })}
              </Inline>
            </>
          )}
        </Stack>

        <Stack space="small">
          <Typography variant="h3">
            {hasSomeCounters ? (
              <>
                Don&apos;t stop now – Subscribe Today! You have active loads
                posted on the Super Loadboard{' '}
                <span style={{ color: Color.Purple500 }}>
                  awaiting your response
                </span>
                !
              </>
            ) : (
              <>
                Subscribe today to maintain uninterrupted access to the Super
                Loadboard and the Verified Carrier Network.
              </>
            )}
          </Typography>

          <ExpressSubscriptionPlanCard
            ctaText="Subscribe Today"
            ctaVariant="primary"
            onCtaClick={() => {
              trackClickedSubscribeNow();
              setUpgradeDialogOpen(true);
            }}
          />
        </Stack>
      </Stack>
      <TrialUpgradeDialog
        open={isUpgradeDialogOpen}
        onClose={() => setUpgradeDialogOpen(false)}
        onSubmitSuccess={() => setSuccessDialogOpen(true)}
      />
      <SubscriptionSuccessDialog
        open={isSuccessDialogOpen}
        onClose={() => {
          setSuccessDialogOpen(false);
          history.push('/subscription');
          window.location.reload();
        }}
      />
    </StyledRoot>
  );
}
