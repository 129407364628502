import { Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import {
  Button,
  Color,
  Column,
  Columns,
  useSnackbarStack,
} from '@superdispatch/ui';
import { isSuperpayPayment } from 'core/SuperPayUtils';
import * as React from 'react';
import {
  FunctionComponent,
  MouseEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useProductTiers } from 'shared/data/TiersUtils';
import { Protect } from 'shared/data/UserPermissions';
import { trackEvent, trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';
import { OrderDTO } from '../../../shared/dto/Order/OrderDTO';
import { useOrderPaymentFlags } from '../../data/OrderPaymentFlagsAPI';
import { useAccessRestrictedState } from './AccessRestrictedDialog';
import {
  isPopoverAction,
  OrderActionType,
  useOrderActionsContext,
} from './OrderActionsContext';
import {
  ActionDivider,
  BOLSyncText,
  getBOLUrl,
  getCustomerInvoiceUrl,
  getInvoiceUrl,
  getOnlineBOLUrl,
  isBOLSyncing,
  isDeliveryReceiptUrlSyncing,
  isNewOrderWithLoads,
  isPrimaryAction,
  showBuildLoads,
  showCancelOffer,
  showCancelOrderAction,
  showEditAction,
  showMarkAsAccepted,
  showMarkAsCarrierInvoiced,
  showMarkAsNew,
  showMarkAsOnHold,
  showMarkAsPending,
  showOnlineBOL,
  showRemoveOrder,
  showRollbackAction,
  showSendOffer,
  showSplitOrderAction,
  showSplitOrderIntoLoadsAction,
  showSubmitToBroker,
  showUnmarkAsCarrierInvoiced,
} from './OrderActionUtils';
import {
  getAvailablePostTarget,
  PostLoadboardButton,
} from './PostLoadboardButton';
import {
  getAvailableUnpostTarget,
  UnpostFromLoadboardButton,
} from './UnpostFromLoadboardButton';

export interface OrderListActionsProps {
  order: Order;
}

export const OrderListActions: FunctionComponent<OrderListActionsProps> = ({
  order,
}) => {
  const {
    onAction,
    isSubmitting,
    isValidating,
    popoverAction,
    type: currentActionType,
    order: currentActionOrder,
  } = useOrderActionsContext();
  const orderPaymentFlags = useOrderPaymentFlags(order.id);
  const { user } = useUserState();
  const shipperType = user?.shipper.shipper_type;
  const { addSnackbar } = useSnackbarStack();
  const { withAccessRestriction } = useAccessRestrictedState();

  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  const hideMenu = () => {
    setMenuAnchor(null);
  };
  const splitOrderEnabled = useFeatureToggle('split-order.enabled.ui');
  const buildLoadsEnabled = useFeatureToggle('build-loads.enabled.ui');
  const splitOrderIntoLoadsEnabled = useFeatureToggle(
    'build-loads.split-order.enabled.ui',
  );
  const customCarrierInvoiceEnabled = useFeatureToggle(
    'carrier-invoice.po-number-and-carrier-invoice-date.enabled',
  );
  const isSystemDeliveryVerificationEnabled = useFeatureToggle(
    'stms.system.delivery.verification.enabled',
  );

  const { isAdvancedTier } = useProductTiers();

  const postTarget = useMemo(() => getAvailablePostTarget([order]), [order]);
  const unpostTarget = useMemo(
    () => getAvailableUnpostTarget([order]),
    [order],
  );

  const isDisabled =
    currentActionOrder?.id !== order.id && (isSubmitting || isValidating);
  const isMenuActionLoading =
    currentActionType &&
    !isPrimaryAction(currentActionType) &&
    currentActionOrder?.id === order.id &&
    (isSubmitting || isValidating);
  const isSuperPay =
    user?.shipper.had_superpay_access && isSuperpayPayment(order.payment);
  const isSuperPayMarkDeliveryVerification =
    orderPaymentFlags?.can_mark_as_delivery_verified;
  const isSystemMarkDeliveryVerification =
    isSystemDeliveryVerificationEnabled &&
    order.delivery_verification_status === 'FAILED';
  const isMarkDeliveryVerification =
    isSuperPayMarkDeliveryVerification || isSystemMarkDeliveryVerification;
  const isUnmarkDeliveryVerification =
    orderPaymentFlags?.can_unmark_as_delivery_verified;
  const showInitiatePaymentNow = orderPaymentFlags?.can_be_initiated_now;
  const showResumePayment = orderPaymentFlags?.can_be_resumed;

  const rollBackActionHandleType =
    isSuperPay &&
    order.status === 'delivered' &&
    !order.has_loads &&
    !order.payment?.super_pay?.is_on_hold
      ? 'unmark_as_delivered'
      : 'rollback_manual_status';

  const isSuperPayNowManual =
    user?.shipper.super_pay_settings?.superpay_flow === 'MANUAL' && isSuperPay;
  const isSuperPaySuspend =
    user?.shipper.super_pay_settings?.suspended_account && isSuperPay;

  function handleAction(type: OrderActionType, args?: Record<string, unknown>) {
    return (event: MouseEvent) => {
      if (!isPopoverAction(type)) {
        hideMenu();
      }
      onAction(type, order, event.currentTarget, args);
    };
  }

  function getLoading(type: OrderActionType) {
    return (
      currentActionType === type &&
      currentActionOrder?.id === order.id &&
      (isSubmitting || isValidating)
    );
  }

  function getMenuItemProps(type: OrderActionType) {
    return {
      onClick: handleAction(type),
      selected:
        type === currentActionType && order.id === currentActionOrder?.id,
    };
  }

  function handleSyncingBOLClick() {
    addSnackbar(
      <Typography>
        The document is syncing. <br />
        To speed up the process, ask to keep the driver app open and wait till
        sync finishes.
      </Typography>,
      { key: 'SyncingBOL' },
    );
  }

  useEffect(() => {
    if (!popoverAction) {
      setMenuAnchor(null);
    }
  }, [popoverAction, setMenuAnchor]);

  const paymentActions = useMemo(() => {
    const actions = [
      showInitiatePaymentNow ? (
        <MenuItem onClick={handleAction('initiate_payment_now')}>
          Initiate Payment Now
        </MenuItem>
      ) : null,
      showResumePayment ? (
        <MenuItem onClick={handleAction('resume_payment')}>
          Resume Payment
        </MenuItem>
      ) : null,
    ];

    return actions.filter((val) => val !== null);
  }, [handleAction, showInitiatePaymentNow, showResumePayment]);

  const postingActions = [
    showSendOffer(order, user) ? (
      <Protect key="send-offer" entity="SEND_OFFER" permission="canExecute">
        <MenuItem
          key="send-offer"
          onClick={(event) => {
            hideMenu();
            withAccessRestriction(() => {
              if (isSuperPaySuspend) {
                handleAction('superpay_suspend_send_offer')(event);
                return;
              }
              handleAction('send_offer')(event);
              trackEventLegacy('Opened Send Offer Drawer', {
                utm_medium: 'orders_list',
                order_guid: order.guid,
              });
            });
          }}
        >
          Send Offer
        </MenuItem>
      </Protect>
    ) : null,
    postTarget.length > 0 ? (
      <PostLoadboardButton
        target={postTarget}
        source="orders_list"
        isMenuItem={true}
        onClick={(event, type, args) => {
          withAccessRestriction(() => {
            if (isSuperPaySuspend) {
              handleAction('superpay_suspend_post_order', args)(event);
              return;
            }
            handleAction(type, args)(event);
          });
        }}
        isLoading={
          getLoading('post_to_private_loadboard') ||
          getLoading('post_to_cd') ||
          getLoading('post_to_sdlb') ||
          getLoading('post_to_all')
        }
      />
    ) : null,
    unpostTarget ? (
      <UnpostFromLoadboardButton
        target={unpostTarget}
        source="orders_list"
        isMenuItem={true}
        onClick={(type, event) => handleAction(type)(event)}
        isLoading={
          getLoading('unpost_from_private_loadboard') ||
          getLoading('unpost_from_cd') ||
          getLoading('unpost_from_sdlb') ||
          getLoading('unpost_from_all')
        }
      />
    ) : null,
  ];
  const ctmsActions = order.carrier_guid ? (
    <MenuItem
      key="open-ctms"
      component="a"
      target="_blank"
      onClick={hideMenu}
      href={OrderDTO.getCarrierOrderURL(order.guid)}
    >
      Open in Carrier TMS
    </MenuItem>
  ) : null;
  const dowloadingActions = [
    isBOLSyncing(order) ? (
      <MenuItem onClick={handleSyncingBOLClick}>
        <BOLSyncText>Download BOL</BOLSyncText>
      </MenuItem>
    ) : getBOLUrl(order, user) ? (
      <MenuItem
        component="a"
        target="_blank"
        href={getBOLUrl(order, user)}
        onClick={() => {
          trackEventLegacy('Downloaded BOL');
          hideMenu();
        }}
      >
        Download BOL
      </MenuItem>
    ) : null,
    isBOLSyncing(order) ? (
      <MenuItem onClick={handleSyncingBOLClick}>
        <BOLSyncText>View Online BOL</BOLSyncText>
      </MenuItem>
    ) : showOnlineBOL(order) ? (
      <MenuItem
        component="a"
        target="_blank"
        onClick={() => {
          trackEventLegacy('Viewed Online BOL');
          hideMenu();
        }}
        href={getOnlineBOLUrl(order)}
      >
        View Online BOL
      </MenuItem>
    ) : null,
    order.dispatch_sheet_url ? (
      <MenuItem
        component="a"
        target="_blank"
        onClick={hideMenu}
        href={order.dispatch_sheet_url}
      >
        View Dispatch Sheet
      </MenuItem>
    ) : null,
    getInvoiceUrl(order, customCarrierInvoiceEnabled) ? (
      <MenuItem
        component="a"
        target="_blank"
        data-intercom-target="view carrier invoice"
        href={getInvoiceUrl(order, customCarrierInvoiceEnabled)}
        onClick={() => {
          trackEventLegacy('Viewed Carrier Invoice');
          hideMenu();
        }}
      >
        View Carrier Invoice
      </MenuItem>
    ) : null,
    getCustomerInvoiceUrl(order) ? (
      <MenuItem
        component="a"
        target="_blank"
        href={getCustomerInvoiceUrl(order)}
        onClick={() => {
          trackEventLegacy('Viewed Customer Invoice');
          hideMenu();
        }}
      >
        View Customer Invoice
      </MenuItem>
    ) : null,
    isDeliveryReceiptUrlSyncing(order) ? (
      <MenuItem onClick={handleSyncingBOLClick}>
        <BOLSyncText>Download Delivery Receipt</BOLSyncText>
      </MenuItem>
    ) : order.pdf_delivery_receipt_url ? (
      <MenuItem
        component="a"
        target="_blank"
        onClick={hideMenu}
        href={order.pdf_delivery_receipt_url}
      >
        Download Delivery Receipt
      </MenuItem>
    ) : null,
  ];

  const statusActions = [
    showMarkAsNew(order) ? (
      <Protect permission="canExecute" entity="MANUALLY_MARK_AS_NEW">
        <MenuItem onClick={handleAction('mark_as_new')}>Mark as New</MenuItem>
      </Protect>
    ) : null,
    showMarkAsOnHold(order) ? (
      <MenuItem onClick={handleAction('mark_as_on_hold')}>
        Mark as On Hold
      </MenuItem>
    ) : null,
    showMarkAsPending(order) ? (
      <Protect permission="canExecute" entity="MANUALLY_MARK_AS_PENDING">
        <MenuItem onClick={handleAction('mark_as_pending')}>
          Mark as Pending
        </MenuItem>
      </Protect>
    ) : null,
    showMarkAsAccepted(order) ? (
      <Protect permission="canExecute" entity="MANUALLY_MARK_AS_ACCEPTED">
        <MenuItem onClick={handleAction('mark_as_accepted')}>
          Mark as Accepted
        </MenuItem>
      </Protect>
    ) : null,
    showRollbackAction(order, isSuperPay) ? (
      <Protect permission="canExecute" entity="ROLLBACK_MANUAL_STATUS_CHANGE">
        <MenuItem onClick={handleAction(rollBackActionHandleType)}>
          {order.status === 'accepted'
            ? 'Unmark as Accepted'
            : order.status === 'picked_up'
            ? 'Unmark as Picked up'
            : // order.status === 'delivered'
              'Unmark as Delivered'}
        </MenuItem>
      </Protect>
    ) : null,
    order.status === 'accepted' ||
    (order.status === 'delivered' &&
      order.active_offer?.is_status_changed_manually) ||
    isNewOrderWithLoads(order) ? (
      <Protect entity="MANUALLY_MARK_AS_PICKED_UP" permission="canExecute">
        <MenuItem {...getMenuItemProps('mark_as_picked_up')}>
          Mark as Picked Up
        </MenuItem>
      </Protect>
    ) : null,
    order.status === 'accepted' ||
    order.status === 'picked_up' ||
    isNewOrderWithLoads(order) ? (
      <Protect entity="MANUALLY_MARK_AS_DELIVERED" permission="canExecute">
        <MenuItem {...getMenuItemProps('mark_as_delivered')}>
          Mark as Delivered
        </MenuItem>
      </Protect>
    ) : null,
    isSuperPay ? null : order.status === 'paid' ? (
      <Protect entity="MARK_AS_PAID" permission="canExecute">
        <MenuItem onClick={handleAction('unmark_as_paid')}>
          Unmark as Paid
        </MenuItem>
      </Protect>
    ) : (
      <Protect permission="canExecute" entity="MARK_AS_PAID">
        <MenuItem
          data-intercom-target="mark as paid"
          onClick={handleAction('mark_as_paid')}
        >
          Mark as Paid
        </MenuItem>
      </Protect>
    ),
    orderPaymentFlags?.can_hold_payment ? (
      <MenuItem onClick={handleAction('mark_as_hold_custom_reason')}>
        Mark as Payment on Hold
      </MenuItem>
    ) : null,
    isMarkDeliveryVerification ? (
      <MenuItem onClick={handleAction('mark_as_delivery_verification')}>
        Mark as Delivery Verified
      </MenuItem>
    ) : null,
    isUnmarkDeliveryVerification ? (
      <MenuItem onClick={handleAction('unmark_as_delivery_verification')}>
        Unmark as Delivery Verified
      </MenuItem>
    ) : null,
    showMarkAsCarrierInvoiced(order) ? (
      <MenuItem {...getMenuItemProps('mark_as_carrier_invoiced')}>
        Mark as Carrier Invoiced
      </MenuItem>
    ) : null,
    showUnmarkAsCarrierInvoiced(order) ? (
      <MenuItem onClick={handleAction('unmark_as_carrier_invoiced')}>
        Unmark as Carrier Invoiced
      </MenuItem>
    ) : null,
    orderPaymentFlags?.can_superpay_now ? (
      <MenuItem
        onClick={handleAction(
          isSuperPayNowManual ? 'superpay_now_manual' : 'superpay_now',
        )}
      >
        SuperPay now
      </MenuItem>
    ) : null,
    isAdvancedTier && (
      <Protect
        entity="SEND_CUSTOMER_INVOICE"
        permission="canExecute"
        key="send-customer-invoice"
      >
        <MenuItem onClick={handleAction('send_customer_invoice')}>
          Send Customer Invoice
        </MenuItem>
      </Protect>
    ),
  ];

  const orderOperationActions = [
    showSplitOrderAction(order) && splitOrderEnabled && !order.has_loads ? (
      <MenuItem to={`/orders/split/${order.guid}`} component={Link}>
        Split Order
      </MenuItem>
    ) : null,

    shipperType === 'BROKER' &&
    splitOrderIntoLoadsEnabled &&
    showSplitOrderIntoLoadsAction(order) &&
    !order.is_load &&
    !order.has_legs ? (
      <MenuItem
        to={`/orders/split-loads/${order.guid}`}
        component={Link}
        onClick={() => {
          trackEvent('[STMS] Split Order into Loads: Menu item click');
        }}
      >
        Split Order into Loads
      </MenuItem>
    ) : null,

    <MenuItem onClick={handleAction('duplicate')} key="duplicate">
      Duplicate
    </MenuItem>,

    order.is_flagged ? (
      <MenuItem onClick={handleAction('unflag')}>Unflag</MenuItem>
    ) : (
      <MenuItem onClick={handleAction('flag')}>Flag</MenuItem>
    ),

    buildLoadsEnabled &&
      showBuildLoads(order) &&
      !order.is_load &&
      (!order.has_loads || order.has_legs) && (
        <MenuItem onClick={handleAction('build_loads')}>Build Loads</MenuItem>
      ),
  ];

  const orderCancellingActions = [
    showCancelOffer(order) ? (
      <Protect permission="canExecute" entity="CANCEL_OFFER">
        <MenuItem onClick={handleAction('cancel_offer')}>Cancel Offer</MenuItem>
      </Protect>
    ) : null,
    showCancelOrderAction(order) ? (
      <MenuItem onClick={handleAction('cancel_order')}>Cancel Order</MenuItem>
    ) : null,
    order.is_archived ? (
      <Protect entity="UNARCHIVE_ORDER" permission="canExecute">
        <MenuItem onClick={handleAction('unarchive')}>Unarchive</MenuItem>
      </Protect>
    ) : (
      <Protect entity="ARCHIVE_ORDER" permission="canExecute">
        <MenuItem
          onClick={handleAction('archive')}
          data-intercom-target="archive"
        >
          Archive
        </MenuItem>
      </Protect>
    ),
    showRemoveOrder(order, user) ? (
      <Protect entity="ORDER" permission="canDelete">
        <MenuItem onClick={handleAction('delete_order')}>Delete</MenuItem>
      </Protect>
    ) : null,
  ];

  const customerActions = [
    showSubmitToBroker(order, user) ? (
      <Protect entity="CUSTOMER_ORDER_SUBMIT" permission="canExecute">
        <MenuItem
          onClick={handleAction('submit_to_broker')}
          disabled={getLoading('submit_to_broker')}
        >
          Submit
        </MenuItem>
      </Protect>
    ) : null,
    isBOLSyncing(order) ? (
      <Tooltip
        placement="top-end"
        title="The BOL is not available for this order. Contact shipper for details"
      >
        <MenuItem>Download BOL</MenuItem>
      </Tooltip>
    ) : getBOLUrl(order, user) ? (
      <MenuItem
        rel="noreferrer"
        target="_blank"
        component="a"
        href={getBOLUrl(order, user)}
        onClick={() => {
          trackEventLegacy('Downloaded BOL');
          hideMenu();
        }}
      >
        Download BOL
      </MenuItem>
    ) : null,
    isBOLSyncing(order) ? (
      <Tooltip
        placement="top-end"
        title="The BOL is not available for this order. Contact shipper for details"
      >
        <MenuItem>View Online BOL</MenuItem>
      </Tooltip>
    ) : showOnlineBOL(order) ? (
      <MenuItem
        target="_blank"
        rel="noreferrer"
        component="a"
        data-intercom-target="view online bol"
        href={getOnlineBOLUrl(order)}
        onClick={() => {
          trackEventLegacy('Viewed Online BOL');
          hideMenu();
        }}
      >
        View Online BOL
      </MenuItem>
    ) : null,
    showRemoveOrder(order, user) ? (
      <Protect entity="ORDER" permission="canDelete">
        <MenuItem
          onClick={handleAction('delete_order')}
          disabled={getLoading('delete_order')}
        >
          Delete
        </MenuItem>
      </Protect>
    ) : null,
  ];

  return (
    <Columns space="xsmall">
      {order.is_active ? (
        <>
          {showEditAction(order, user) && (
            <Protect entity="ORDER" permission="canUpdate">
              <Column width="content">
                <Link
                  style={{ textDecoration: 'none' }}
                  to={`/orders/edit/${order.guid}`}
                >
                  <Button
                    aria-labelledby="edit"
                    variant="outlined"
                    style={{ color: Color.Grey200 }}
                    onClick={() =>
                      trackEventLegacy('Opened Order Edit Page', {
                        utm_medium: 'orders_list',
                      })
                    }
                  >
                    <Edit fontSize="small" />
                  </Button>
                </Link>
              </Column>
            </Protect>
          )}
          <Column width="content">
            {shipperType === 'BROKER' ? (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={showMenu}
                  disabled={isDisabled}
                  isLoading={isMenuActionLoading}
                  aria-label="order actions"
                  data-intercom-target="order actions"
                  style={{ color: Color.Grey200 }}
                >
                  &bull;&bull;&bull;
                </Button>
                <Menu
                  open={!!menuAnchor}
                  anchorEl={menuAnchor}
                  onClose={hideMenu}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  disableAutoFocusItem={true}
                >
                  {paymentActions}
                  {paymentActions.length > 0 && (
                    <ActionDivider key="divider-0" />
                  )}

                  {postingActions}
                  {postingActions.filter((val) => val !== null).length > 0 && (
                    <ActionDivider key="divider-1" />
                  )}
                  {ctmsActions}
                  {ctmsActions && <ActionDivider key="divider-2" />}

                  {dowloadingActions}
                  {dowloadingActions.filter((val) => val !== null).length >
                    0 && <ActionDivider key="divider-3" />}
                  {statusActions}
                  {statusActions.filter((val) => val !== null).length > 0 && (
                    <ActionDivider key="divider-4" />
                  )}
                  {orderOperationActions}
                  {orderOperationActions.filter((val) => val !== null).length >
                    0 && <ActionDivider key="divider-5" />}
                  {orderCancellingActions}
                </Menu>
              </>
            ) : customerActions.filter((val) => val !== null).length > 0 ? (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={showMenu}
                  disabled={isDisabled}
                  isLoading={isMenuActionLoading}
                  aria-label="order actions"
                  data-intercom-target="order actions"
                  style={{ color: Color.Grey200 }}
                >
                  &bull;&bull;&bull;
                </Button>
                <Menu
                  open={!!menuAnchor}
                  anchorEl={menuAnchor}
                  onClose={hideMenu}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  disableAutoFocusItem={true}
                >
                  {customerActions}
                </Menu>
              </>
            ) : null}
          </Column>
        </>
      ) : (
        <Column width="content">
          <Protect entity="RESTORE_INACTIVE" permission="canExecute">
            <Button
              color="primary"
              variant="outlined"
              onClick={handleAction('restore_order')}
              isLoading={getLoading('restore_order')}
            >
              Restore
            </Button>
          </Protect>
        </Column>
      )}
    </Columns>
  );
};
