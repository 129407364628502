import { MenuItem } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { Stack } from '@superdispatch/ui';
import {
  MOOV_BUSINESS_TYPES,
  MOOV_BUSINESS_TYPE_DISPLAY_NAMES,
  MOOV_MCC_CODES,
  MOOV_MCC_CODE_DISPLAY_NAMES,
} from 'shared/helpers/superpay/MoovDTO';

export function BusinessDetailsFormLegacy() {
  return (
    <Stack space="small">
      <FormikTextField
        label="Legal Business Name"
        name="legalBusinessName"
        fullWidth={true}
        inputProps={{ maxLength: 64 }}
      />

      <FormikTextField
        label="Business Type"
        name="businessType"
        placeholder="Select option"
        select={true}
        fullWidth={true}
      >
        {MOOV_BUSINESS_TYPES.map((businessType) => (
          <MenuItem key={businessType} value={businessType}>
            {MOOV_BUSINESS_TYPE_DISPLAY_NAMES[businessType]}
          </MenuItem>
        ))}
      </FormikTextField>

      <FormikTextField
        label="Employer Identification Number (EIN)"
        name="einNumber"
        fullWidth={true}
        helperText="9 digits. You can find your business’s EIN on tax returns, credit reports, payroll paperwork, or licenses and permits."
      />

      <FormikTextField
        label="Industry - MCC code"
        name="mccNumber"
        placeholder="Select option"
        select={true}
        fullWidth={true}
      >
        {MOOV_MCC_CODES.map((mccCode) => (
          <MenuItem key={mccCode} value={mccCode}>
            {MOOV_MCC_CODE_DISPLAY_NAMES[mccCode]} – {mccCode}
          </MenuItem>
        ))}
      </FormikTextField>
    </Stack>
  );
}
