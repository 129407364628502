import { CircularProgress, IconButton, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import {
  Sidebar,
  SidebarDivider,
  SidebarMenuItem,
  SidebarSubheader,
} from '@superdispatch/ui-lab';
import { useHistory } from 'react-router';
import { APIErrorMessage } from 'shared/errors/APIErrorMessage';
import { RemoveIcon } from 'shared/icons/RemoveIcon';
import { useReportDeleteMutation, useSavedReports } from './data/ReportsAPI';

interface Props {
  selectedID?: number;
}

export function ReportsList({ selectedID }: Props) {
  const history = useHistory();
  const { data } = useSavedReports();
  const { addSnackbar } = useSnackbarStack();

  const {
    variables,
    mutate: deleteReport,
    isLoading: isDeleting,
  } = useReportDeleteMutation({
    onSuccess() {
      history.push('/reports');
    },
    onError(error) {
      addSnackbar(<APIErrorMessage error={error} />, {
        variant: 'error',
      });
    },
  });

  return (
    <Sidebar title="Reports" id="reports-sidebar">
      <SidebarMenuItem
        selected={!selectedID}
        openContentOnClick={true}
        onClick={() => {
          history.push('/reports');
        }}
      >
        <Typography>Custom Report</Typography>
      </SidebarMenuItem>

      <SidebarDivider />

      <SidebarSubheader>SAVED REPORTS</SidebarSubheader>

      {!data ? (
        <Stack space="small">
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Stack>
      ) : (
        data.objects.map((report) => (
          <SidebarMenuItem
            key={report.id}
            openContentOnClick={true}
            selected={report.id === selectedID}
            onClick={() => {
              history.push(`/reports/${report.id}`);
            }}
            secondaryActions={
              variables === report.id && isDeleting ? (
                <CircularProgress
                  size={24}
                  color="secondary"
                  variant="indeterminate"
                />
              ) : (
                <IconButton
                  aria-label="remove report"
                  disabled={isDeleting && variables !== report.id}
                  onClick={() => {
                    deleteReport(report.id);
                  }}
                >
                  <RemoveIcon />
                </IconButton>
              )
            }
          >
            {report.name}
          </SidebarMenuItem>
        ))
      )}
    </Sidebar>
  );
}
