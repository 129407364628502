import { Route, Switch } from 'react-router-dom';
import { useTrackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { ProtectedRoute } from '../ApRouter';
import { CreateInternalCarrierPage } from './create/CreateInternalCarrierPage';
import { EditInternalCarrierPage } from './edit/EditInternalCarrierPage';
import { ManageCarriersListPage } from './list/ManageCarriersListPage';
import { CarrierProfilePage } from './view/CarrierProfilePage';

export function ManageCarriersPage() {
  useDocumentTitle('Manage Carriers');
  useTrackEvent('[STMS] Viewed Manage Carriers');

  return (
    <Switch>
      <ProtectedRoute path="/manage-carriers/create">
        <CreateInternalCarrierPage />
      </ProtectedRoute>

      <ProtectedRoute path="/manage-carriers/edit/:guid">
        <EditInternalCarrierPage />
      </ProtectedRoute>

      <Route path={['/manage-carriers/usdot/:usdot', '/manage-carriers/:guid']}>
        <CarrierProfilePage />
      </Route>

      <ProtectedRoute path="/manage-carriers">
        <ManageCarriersListPage />
      </ProtectedRoute>
    </Switch>
  );
}
