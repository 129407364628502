import { Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useHistory } from 'react-router-dom';
import { AlertIcon } from 'shared/icons/AlertIcon';
import { PageLayout } from 'shared/layout/PageLayout';
import styled from 'styled-components';
import { BrokerFooter, BrokerHeader } from './BrokerLayout';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Content = styled.div`
  padding: 107px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledAlertIcon = styled(AlertIcon)`
  font-size: 48px;
`;

export function ExpiredLinkPage() {
  const history = useHistory();
  return (
    <PageLayout
      contentBackground="inherit"
      header={
        <BrokerHeader
          actions={
            <Button variant="neutral" onClick={() => history.push('/signin')}>
              Log in
            </Button>
          }
        />
      }
      footer={<BrokerFooter />}
    >
      <Wrapper>
        <Content>
          <Stack space="medium" align="center">
            <StyledAlertIcon />

            <Stack space="xxsmall" align="center">
              <Typography variant="h2">
                This link has alredy been used or expired.
              </Typography>

              <Typography>
                You must submit a new password reset request from your Admin.
              </Typography>
            </Stack>
          </Stack>
        </Content>
      </Wrapper>
    </PageLayout>
  );
}
