import { FormikTextField } from '@superdispatch/forms';
import {
  Button,
  Column,
  Columns,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { SidebarContainer, SidebarContent } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useHistory, useParams } from 'react-router';
import { useReport } from 'reports/data/ReportsAPI';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useTrackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { SuperPayRequestedBanner } from '../superpay/request-to-use/SuperPayRequestedBanner';
import { trackReportEvent } from './data/ReportsAnalytics';
import { ReportsFields } from './ReportsFields';
import { ReportsFilters } from './ReportsFilters';
import { useReportsForm } from './ReportsForm';
import { ReportsList } from './ReportsList';

export function ReportsPage() {
  const isCarrierRequestsSuperPay = useFeatureToggle(
    'carrier-requests-superpay.access.ui',
  );
  const { user } = useUserState();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const report = useReport(id);
  const { addSnackbar } = useSnackbarStack();

  const { formik, getButtonProps } = useReportsForm({
    report,
    onCreate(value) {
      trackReportEvent({ name: 'Saved Report', params: value.report_params });
      history.push(`/reports/${value.id}`);
    },
    onSendReport(params) {
      trackReportEvent({ name: 'Sent Report', params });
      addSnackbar('Report will be delivered soon via email.', {
        variant: 'success',
      });
    },
  });

  useDocumentTitle('Reports');
  useTrackEvent('[STMS] Viewed Reports Page');

  return (
    <SidebarContainer sidebar={<ReportsList selectedID={report?.id} />}>
      <SidebarContent
        title={report ? report.name : 'Custom Report'}
        action={
          <Columns space="small">
            <Column width="content">
              <FormikProvider value={formik}>
                <FormikTextField
                  name="report_name"
                  placeholder="Enter report name"
                />
              </FormikProvider>
            </Column>

            {report && (
              <Column width="content">
                <Button {...getButtonProps('update')}>Save</Button>
              </Column>
            )}

            <Column width="content">
              <Button {...getButtonProps('create')}>
                {report ? 'Save as New' : 'Save'}
              </Button>
            </Column>

            <Column width="content">
              <Button variant="contained" {...getButtonProps('send_report')}>
                Send Report
              </Button>
            </Column>
          </Columns>
        }
      >
        <Stack space="medium">
          {isCarrierRequestsSuperPay &&
            user?.shipper.super_pay_settings
              ?.superpay_requested_banner_available && (
              <SuperPayRequestedBanner source="Reports" />
            )}
          <FormikProvider value={formik}>
            <Form>
              <Columns space="small" collapseBelow="desktop">
                <Column width="1/3">
                  <ReportsFilters />
                </Column>

                <Column width="2/3">
                  <ReportsFields />
                </Column>
              </Columns>
            </Form>
          </FormikProvider>
        </Stack>
      </SidebarContent>
    </SidebarContainer>
  );
}
