import { Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import styled from 'styled-components';
import { useManageUsersAPI } from './data/ManageUsersAPI';

interface Props {
  userGuid: string;
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
}

const StyledList = styled.ul`
  padding-left: 16px;
`;

export function UserDeactivateDialog({
  userGuid,
  open,
  onClose,
  onUpdate,
}: Props) {
  const { deactivateUser } = useManageUsersAPI();
  const { addSnackbar } = useSnackbarStack();

  const formik = useFormikEnhanced({
    initialValues: {},
    onSubmit: (_) => deactivateUser(userGuid),
    onSubmitFailure: (error) =>
      addSnackbar(error.message, { variant: 'error' }),
    onSubmitSuccess: () => {
      onClose();
      addSnackbar('User deactivated', { variant: 'success' });
      onUpdate();
    },
  });
  return (
    <ConfirmDialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={() => (formik.isSubmitting ? undefined : onClose())}
      title="Deactivate this user?"
      cancelButtonProps={{
        children: 'Cancel',
        disabled: formik.isSubmitting,
        onClick: () => onClose(),
      }}
      confirmButtonProps={{
        children: 'Deactivate',
        isLoading: formik.isSubmitting,
        onClick: () => formik.handleSubmit(),
        autoFocus: false,
      }}
    >
      <Stack>
        <StyledList>
          <li>
            <Typography>
              All of the orders related to the user will remain in the account.
            </Typography>
          </li>
          <li>
            <Typography>
              The user will not be able to login to Shipper TMS.
            </Typography>
          </li>
          <li>
            <Typography>
              You can reactivate this user at any time in the future.
            </Typography>
          </li>
        </StyledList>
      </Stack>
    </ConfirmDialog>
  );
}
