import { Box, IconButton, TextField } from '@material-ui/core';
import { Add, GetApp, Search } from '@material-ui/icons';
import {
  Button,
  Color,
  Column,
  Columns,
  useResponsiveValue,
} from '@superdispatch/ui';
import { ChangeEvent } from 'react';
import { useHistory } from 'react-router';
import { useUserState } from 'shared/data/AppUserState';
import { canOrderSubmitAllowed, Protect } from 'shared/data/UserPermissions';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { OrderListSearchPeriod } from './OrderListSearchPeriod';
import { OrderListSearchType } from './OrderListSearchType';
import { getOrdersListHeaderText, StyledViewTitle } from './OrdersListHeader';

interface OrderListHeaderCompactProps {
  status?: string;
  title?: string;
  query?: string;
  onSearch?: (query: string) => void;
  setSearchVisibility: (visibility: boolean) => void;
}

export function OrderListHeaderCompact({
  title,
  query,
  setSearchVisibility,
  onSearch,
  status,
}: OrderListHeaderCompactProps) {
  const { user } = useUserState();
  const history = useHistory();
  const platform = useResponsiveValue(
    'mobile',
    'tablet',
    'desktop-md',
    'desktop',
  );
  const isDesktop = platform === 'desktop';
  const isDesktopMd = platform === 'desktop-md';
  const showSearchBar = onSearch && isDesktop;
  const showSearchPeriod = query && query.length > 2;

  function handleChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    onSearch?.(value);
  }

  return (
    <Columns align="center">
      <Column width="1/4">
        <StyledViewTitle variant="h2">
          {title || getOrdersListHeaderText(status)}
        </StyledViewTitle>
      </Column>

      {showSearchBar && (
        <Column width="1/2">
          <Columns space="xsmall">
            <Column width="content">
              <OrderListSearchType />
            </Column>

            <Column width="fluid">
              <TextField
                placeholder="3 symbols to search"
                value={query}
                fullWidth={true}
                autoFocus={true}
                type="search"
                onChange={handleChange}
              />
            </Column>

            {showSearchPeriod && <OrderListSearchPeriod isColumn={true} />}
          </Columns>
        </Column>
      )}

      <Column width={showSearchBar ? '1/4' : '3/4'}>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {!isDesktop && onSearch && (
            <IconButton
              aria-label="search"
              style={{ color: Color.Grey100 }}
              onClick={() => {
                setSearchVisibility(true);
              }}
            >
              <Search fontSize="small" />
            </IconButton>
          )}
          {(user?.shipper.shipper_type === 'BROKER' ||
            canOrderSubmitAllowed(user)) && (
            <Protect permission="canCreate" entity="ORDER">
              {isDesktop || isDesktopMd ? (
                <Box ml={1}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => history.push('/orders/import')}
                  >
                    Import Orders
                  </Button>
                </Box>
              ) : (
                <IconButton>
                  <GetApp
                    fontSize="small"
                    style={{ color: Color.Grey100 }}
                    onClick={() => history.push('/orders/import')}
                  />
                </IconButton>
              )}
            </Protect>
          )}

          {(user?.shipper.shipper_type === 'BROKER' ||
            canOrderSubmitAllowed(user)) && (
            <Protect permission="canCreate" entity="ORDER">
              {isDesktop || isDesktopMd ? (
                <Box ml={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    data-intercom-target="create order"
                    onClick={() => {
                      trackEventLegacy('Clicked Create Order');
                      history.push('/orders/create');
                    }}
                  >
                    Create Order
                  </Button>
                </Box>
              ) : (
                <Box ml={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    data-intercom-target="create order"
                    onClick={() => {
                      trackEventLegacy('Clicked Create Order');
                      history.push('/orders/create');
                    }}
                  >
                    <Add fontSize="small" />
                  </Button>
                </Box>
              )}
            </Protect>
          )}
        </Box>
      </Column>
    </Columns>
  );
}
