import { useDefaultTimeZone } from '@superdispatch/dates';
import { ForgotPasswordPage } from 'auth/ForgotPasswordPage';
import { ResetPasswordPage } from 'auth/ResetPasswordPage';
import { VerifyAccount } from 'auth/VerifyAccount';
import LoadboardSettingsIssuesDialog from 'core/LoadboardSettingsIssuesDialog';
import { TrialStartedDialog } from 'core/TrialStartedDialog';
import { ManageFieldsPage } from 'manage-fields/ManageFieldsPage';
import { ManageUsersPage } from 'manage-users/ManageUsersPage';
import { lazy, useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { OnboardingProvider } from 'shared/context/OnboardingContext';
import { useUserState } from 'shared/data/AppUserState';
import { isInternalUser } from 'shared/data/AppUtils';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useOnboardingState } from 'shared/data/OnboardingState';
import { Paywall } from 'shared/errors/paywall/Paywall';
import { useHasPaywall } from 'shared/errors/paywall/PaywallEvents';
import { browserFingerprint } from 'shared/helpers/BrowserFingerprint';
import { identifyErrorTrackerUser } from 'shared/helpers/MonitoringService';
import { SubscriptionDetailsPage } from 'subscription-details/SubscriptionDetailsPage';
import { SubscriptionDetailsPageLegacy } from 'subscription-details/SubscriptionDetailsPageLegacy';
import { SubscriptionPlansPage } from 'subscription-plans/SubscriptionPlansPage';
import { SuperPayContainer } from 'superpay/SuperPayContainer';
import { AppBlankRoute, AppProtectedRoute, AppRoute } from './ApRouter';
import { LoginPage } from './auth/LoginPage';
import { BillingPaymentForm } from './BillingPaymentForm';
import { ComplianceFormDialog } from './ComplianceFormDialog';
import { CustomersPage } from './customers/CustomersPage';
import { GeneralSettingsPage } from './general-settings/GeneralSettingsPage';
import { SuperLoadboardSettingsPage } from './loadboard-settings/SuperLoadboardSettingsPage';
import { LoadingContainer } from './LoadingContainer';
import { ManageCarriersPage } from './manage-carriers/ManageCarriersPage';
import { NotificationSettingsPage } from './notification-settings/NotificationSettingsPage';
import { OrdersPage } from './orders/OrdersPage';
import { ReportsPage } from './reports/ReportsPage';
import { enableTokenRefresh } from './shared/api/API';
import { useNotificationHandler } from './shared/data/notifications/NotificationHelpers';
import {
  identifyUser,
  trackEventLegacy,
} from './shared/helpers/AnalyticsHelpers';
import {
  hideIntercomButton,
  showIntercomButton,
  updateIntercom,
} from './shared/helpers/IntercomHelpers';
import { stringifySearchQuery, useQuery } from './shared/helpers/RouteHelpers';
import { ShipperProfilePage } from './shipper-profile/ShipperProfilePage';
import { VerificationApplicationContainer } from './shipper-profile/verification-application/VerificationApplicationContainer';
import { TerminalsPage } from './terminals/TerminalsPage';

const DashboardPageLazy = lazy(() => import('./dashboard/DashboardPage'));

const OnboardingPageLegacyLazy = lazy(
  () => import('onboarding/OnboardingPageLegacy'),
);

const OnboardingPageLazy = lazy(() => import('onboarding/OnboardingPage'));

export function App() {
  const history = useHistory();
  const { user, authState } = useUserState();

  const hasPaywall = useHasPaywall();

  useDefaultTimeZone(user?.shipper.zone_offset_minutes ?? 'local');

  useNotificationHandler();

  useEffect(() => {
    if (authState === 'pending') {
      return;
    }

    if (user) {
      identifyUser(user, () => {
        // update intercom surveys, tours etc.
        updateIntercom();
      });
      identifyErrorTrackerUser({
        id: user.guid,
        shipperGuid: user.shipper.guid,
        shipperName: user.shipper.name,
        browserFingerprint,
      });

      if (user.shipper.shipper_type === 'CUSTOMER' || isInternalUser(user)) {
        hideIntercomButton();
      } else {
        showIntercomButton();
      }
    } else {
      identifyUser();
    }

    trackEventLegacy('Started Session');
  }, [user]);

  useEffect(() => {
    if (authState !== 'authorized') {
      return;
    }
    return history.listen(updateIntercom);
  }, [authState]);

  if (authState === 'pending') {
    return <LoadingContainer />;
  }

  if (authState === 'unauthorized') {
    return <UnauthenticatedRoutes />;
  }

  return (
    <OnboardingProvider key={user?.guid}>
      <ComplianceFormDialog />
      {hasPaywall ? <AuthenticatedPaywallRoutes /> : <AuthenticatedAppRoutes />}
    </OnboardingProvider>
  );
}

function UnauthenticatedRoutes() {
  const location = useLocation();

  return (
    <Switch>
      <Route path="/signin/verify">
        <VerifyAccount />
      </Route>

      <Route path="/signin">
        <LoginPage />
      </Route>

      <Route path="/forgot-password">
        <ForgotPasswordPage />
      </Route>

      <Route path="/reset-password">
        <ResetPasswordPage />
      </Route>

      <Route path="*">
        <Redirect
          to={{
            pathname: '/signin',
            search: stringifySearchQuery({
              redirect_url: `${location.pathname}${decodeURIComponent(
                location.search,
              )}`,
            }),
          }}
        />
      </Route>
    </Switch>
  );
}

function AuthenticatedPaywallRoutes() {
  return (
    <Switch>
      <AppBlankRoute path="/subscription-plans">
        <SubscriptionPlansPage />
      </AppBlankRoute>

      <Route path="*">
        <Paywall />
      </Route>
    </Switch>
  );
}

function AuthenticatedAppRoutes() {
  const { showOnboarding, isNewOnboardingAvailable } = useOnboardingState();
  const isSubscriptionEnabled = useFeatureToggle(
    'update-credit-card-info.enabled',
  );
  const isSubscriptionDetailsV2Enabled = useFeatureToggle(
    'subscription-details-v2.ui',
  );
  const isTokenRefreshEnabled = useFeatureToggle('stms.refresh-token.enabled');
  const [{ redirect_url }] = useQuery();
  const { user, isAdmin, isSuperUser } = useUserState();
  const showSubscription =
    (isAdmin || isSuperUser) &&
    user?.shipper.is_self_serve &&
    isSubscriptionEnabled;

  const showPlans = isSubscriptionEnabled && (isAdmin || isSuperUser);

  useEffect(() => {
    if (!isTokenRefreshEnabled) {
      return;
    }

    enableTokenRefresh();
  }, [isTokenRefreshEnabled]);

  return (
    <>
      <Switch>
        <AppProtectedRoute path="/dashboard">
          <DashboardPageLazy />
        </AppProtectedRoute>

        <AppRoute path={['/orders', '/overview/orders']}>
          <OrdersPage />
        </AppRoute>

        <AppProtectedRoute path="/customers">
          <CustomersPage />
        </AppProtectedRoute>

        <AppRoute path="/terminals">
          <TerminalsPage />
        </AppRoute>

        <AppProtectedRoute path="/reports/:id?">
          <ReportsPage />
        </AppProtectedRoute>

        {showOnboarding && (
          <AppRoute path="/getting-started">
            {isNewOnboardingAvailable ? (
              <OnboardingPageLazy />
            ) : (
              <OnboardingPageLegacyLazy />
            )}
          </AppRoute>
        )}

        {showPlans && (
          <AppRoute path="/subscription-plans">
            <SubscriptionPlansPage />
          </AppRoute>
        )}

        <AppRoute path="/manage-carriers">
          <ManageCarriersPage />
        </AppRoute>

        <AppRoute path="/profile">
          <ShipperProfilePage />
        </AppRoute>

        <AppBlankRoute path="/verification-application">
          <VerificationApplicationContainer />
        </AppBlankRoute>

        <AppRoute path="/notification-settings">
          <NotificationSettingsPage />
        </AppRoute>

        <AppRoute path="/superpay">
          <SuperPayContainer />
        </AppRoute>

        {showSubscription && (
          <AppRoute path="/subscription">
            {isSubscriptionDetailsV2Enabled ? (
              <SubscriptionDetailsPage />
            ) : (
              <SubscriptionDetailsPageLegacy />
            )}
          </AppRoute>
        )}

        <AppRoute path="/users">
          <ManageUsersPage />
        </AppRoute>

        <AppProtectedRoute path="/manage-fields">
          <ManageFieldsPage />
        </AppProtectedRoute>

        <AppRoute path="/general-settings">
          <GeneralSettingsPage />
        </AppRoute>

        <AppRoute path="/super-loadboard-settings">
          <SuperLoadboardSettingsPage />
        </AppRoute>

        <Route path="/payment-form">
          <BillingPaymentForm />
        </Route>

        <Route path="*">
          <Redirect
            to={typeof redirect_url === 'string' ? redirect_url : '/orders'}
          />
        </Route>
      </Switch>
      <TrialStartedDialog />
      <LoadboardSettingsIssuesDialog />
    </>
  );
}
