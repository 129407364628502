import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import {
  Button,
  Column,
  Columns,
  Inline,
  NumberField,
  Stack,
  Tag,
  useResponsiveValue,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { PricePerMile } from 'core/PricePerMile';
import { Form, FormikProvider } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { FeatureToggle, useFeatureToggle } from 'shared/data/FeatureToggle';
import Order from 'shared/types/order';
import {
  AcceptsSuperPayChangeTerms,
  CarrierAcceptsSuperPay,
} from '../../../superpay/CarrierAcceptsSuperPay';
import { CarrierRequestsSuperPay } from '../../../superpay/CarrierRequestsSuperPay';
import { OrderRequestDTO } from '../../data/dto/OrderRequestDTO';
import { useOrderActivityCache } from '../../data/OrderAPI';
import { useOrderRequestsAPI } from '../../data/OrderRequestsAPI';
import { usePriceNegotiation } from '../../data/PriceNegotiationAPI';
import { useAccessRestrictedState } from '../actions/AccessRestrictedDialog';
import { trackOderActionEvent } from '../actions/OrderActionsAnalytics';
import { PricingRecommendationCounterOffer } from '../pricing-insights/PricingRecommendation';
import { useCountOrderRequests } from './OrderRequestsCount';

export const requestStatusInfo: Record<
  Exclude<OrderRequestDTO['status'], 'REQUEST_PENDING' | 'UNPOSTED'>,
  { text: string; isMuted?: boolean }
> = {
  REQUEST_DECLINED_BY_BROKER: { text: 'Declined', isMuted: true },
  REQUEST_CANCELED: { text: 'Carrier Canceled Request', isMuted: true },
  OFFER_SENT: { text: 'Offer Sent' },
  COUNTER_OFFER_SENT: { text: 'Counter Offer Sent' },
  OFFER_ACCEPTED: { text: 'Offer Accepted' },
  OFFER_CANCELED: { text: 'Offer Canceled', isMuted: true },
  OFFER_DECLINED: { text: 'Carrier Declined Offer', isMuted: true },
};

export const RequestStatusInfo: React.ComponentType<{
  status: OrderRequestDTO['status'];
  date: string;
}> = ({ status, date }) => {
  const statusInfo = requestStatusInfo[status];

  return statusInfo ? (
    <Inline>
      <Tag color={statusInfo.isMuted ? 'grey' : 'green'} variant="subtle">
        {statusInfo.text}
      </Tag>
      <Box>
        <Inline>
          <Typography color="textSecondary" variant="caption">
            <FormattedDate variant="Time" format="JodaISO" date={date} />,
          </Typography>
          <Typography color="textSecondary" variant="caption">
            <FormattedDate variant="ShortDate" format="JodaISO" date={date} />
          </Typography>
        </Inline>
      </Box>
    </Inline>
  ) : null;
};

type ActionType = 'accept' | 'decline' | 'cancel' | 'counter_offer';

export interface OrderRequestCardActionsProps {
  order: Order;
  request: OrderRequestDTO;
  hasPrevCounterOffer: boolean;
  onSubmitSuccess: (order: Order) => void;
}

export function OrderRequestCardActions({
  order,
  request,
  onSubmitSuccess,
  hasPrevCounterOffer,
}: OrderRequestCardActionsProps) {
  const isCarrierRequestsSuperPay = useFeatureToggle(
    'carrier-requests-superpay.access.ui',
  );
  const brokerRecords = request.broker_records;
  const { user } = useUserState();
  const { data: priceNegotiation } = usePriceNegotiation(order);
  const { addSnackbar } = useSnackbarStack();
  const {
    acceptRequest,
    declineRequest,
    cancelRequestCounterOffer,
    cancelOffer,
    sendCounterOffer,
  } = useOrderRequestsAPI();
  const [action, setAction] = useState<ActionType>();
  const [price, setPrice] = useState<number>();
  const [hasSuperPayTerms, setSuperPayTerms] = useState<boolean>(false);
  const { withAccessRestriction } = useAccessRestrictedState();
  const declineContent = useResponsiveValue(
    <Close color="action" />,
    <>Decline</>,
  );
  const { invalidateOrderActivity } = useOrderActivityCache();

  const countRequests = useCountOrderRequests(order.guid);

  const form = useFormikEnhanced<{ declineReason: string }, Order>({
    key: action,
    initialValues: { declineReason: '' },
    onSubmit(values) {
      switch (action) {
        case 'accept':
          return acceptRequest(order.guid, request.guid, hasSuperPayTerms);
        case 'decline':
          return declineRequest(order.guid, request.guid, values.declineReason);
        case 'counter_offer':
          if (typeof price !== 'number') {
            throw new Error('Price should be set properly');
          }
          return sendCounterOffer(order.guid, request.guid, price);
        case 'cancel':
          if (request.status === 'COUNTER_OFFER_SENT') {
            return cancelRequestCounterOffer(order.guid, request.guid).then(
              () => order,
            );
          }

          if (order.active_offer) {
            return cancelOffer(order.id, order.active_offer.id).then(
              (offer) => ({
                ...order,
                status: 'canceled',
                active_offer: offer,
                vehicles: order.vehicles?.map((x) => ({
                  ...x,
                  status: 'canceled',
                })),
              }),
            );
          }

          return Promise.reject(new Error('Active offer not found'));
      }

      return Promise.reject(new Error('Unknown action'));
    },
    onSubmitSuccess(response) {
      void invalidateOrderActivity(order.id);
      if (hasSuperPayTerms) {
        trackOderActionEvent({
          name: 'Method Changed to SuperPay',
          source: 'Order Requests',
        });
      }

      trackOderActionEvent({
        name:
          action === 'accept'
            ? '[STMS] Sent Load Offer'
            : action === 'counter_offer'
            ? '[STMS] Counter Offer Sent'
            : action === 'cancel'
            ? '[STMS] Canceled Load Offer'
            : 'Shipper Declined Request',
        order,
        user,
        request,
        priceNegotiation,
        offer: response.active_offer,
        source: 'Order Requests',
        carrierSource: 'carrier_request',
        carrier: {
          guid: request.carrier_guid,
          rating_stats: request.carrier_rating_stats,
        },
        ...countRequests(),
      });
      if (action === 'accept') {
        addSnackbar('Offer sent to Carrier for review.', {
          variant: 'success',
        });
      } else if (action === 'counter_offer') {
        addSnackbar('Counter Offer sent to Carrier for review.', {
          variant: 'success',
        });
      } else if (action !== 'cancel') {
        addSnackbar('Request declined.', {
          variant: 'success',
        });
      }

      setAction(undefined);
      onSubmitSuccess(response);
    },
    onSubmitFailure(error) {
      setAction(undefined);
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  const { values, handleSubmit } = form;

  useEffect(() => {
    if (
      action === 'accept' ||
      action === 'cancel' ||
      action === 'counter_offer'
    ) {
      handleSubmit();
    }
  }, [action, handleSubmit]);

  function getButtonProps(type: ActionType) {
    return {
      onClick: () => {
        if (type === 'accept') {
          withAccessRestriction(() => setAction(type));
          return;
        }
        setAction(type);
      },
      disabled: form.isSubmitting && type !== action,
      isLoading: form.isSubmitting && type === action,
    };
  }

  function handleClose() {
    setAction(undefined);
  }

  const requestPrice = request.price;
  const changedAmount = Number(requestPrice) - Number(order.price);
  const isRequestPending = request.status === 'REQUEST_PENDING';

  const canChangeToSuperPayTerms =
    user?.shipper.super_pay_settings?.on_boarding_status === 'COMPLETED' &&
    !order.payment?.superpay_canceled;
  return (
    <Stack space="small">
      {isRequestPending ? (
        isCarrierRequestsSuperPay && brokerRecords?.superpay_requested ? (
          <CarrierRequestsSuperPay
            source="Carrier Requests Drawer"
            compact={true}
          />
        ) : (
          request.is_super_pay_enabled &&
          (canChangeToSuperPayTerms ? (
            <AcceptsSuperPayChangeTerms
              disabled={!!price}
              checked={hasSuperPayTerms}
              source="Carrier Requests Drawer"
              onChange={(_, checked) => setSuperPayTerms(checked)}
            />
          ) : (
            <CarrierAcceptsSuperPay
              source="Carrier Requests Drawer"
              compact={true}
            />
          ))
        )
      ) : null}

      <Box width="100%" data-intercom-target="order request card actions">
        {isRequestPending ? (
          <Stack space="small">
            <Stack space="xxsmall">
              {order.price !== request.price && (
                <NumberField
                  value={price}
                  fullWidth={true}
                  onChange={(event) => {
                    setPrice(Number(event.target.value));
                    setSuperPayTerms(false);
                  }}
                  inputProps={{
                    decimalScale: 2,
                  }}
                  data-intercom-target="counter offer field"
                  placeholder="Counter offer"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <PricePerMile price={price} order={order} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}

              {price != null && (
                <FeatureToggle name="recommended-price.enabled.ui">
                  <PricingRecommendationCounterOffer
                    order={order}
                    onPriceClick={setPrice}
                    counterOfferPrice={price}
                    source="Order Requests Drawer"
                  />
                </FeatureToggle>
              )}
            </Stack>

            <Stack space="xxsmall">
              <Columns space="xsmall">
                <Column width="content">
                  <Button
                    color="primary"
                    variant="outlined"
                    {...getButtonProps('decline')}
                  >
                    {declineContent}
                  </Button>
                </Column>
                <Column>
                  {price != null ? (
                    <Button
                      color="primary"
                      variant="contained"
                      data-intercom-target="make counter offer"
                      {...getButtonProps('counter_offer')}
                    >
                      Make Counter Offer
                    </Button>
                  ) : changedAmount ? (
                    <Button
                      color="primary"
                      variant="contained"
                      {...getButtonProps('accept')}
                    >
                      Accept Bid
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      {...getButtonProps('accept')}
                    >
                      Accept Request
                    </Button>
                  )}
                </Column>
              </Columns>

              {hasPrevCounterOffer && price != null && (
                <TextBox align="right" color="secondary" variant="caption">
                  Previous counter offer will be canceled
                </TextBox>
              )}
            </Stack>
          </Stack>
        ) : request.status === 'OFFER_SENT' ||
          request.status === 'COUNTER_OFFER_SENT' ? (
          <Button
            color="primary"
            variant="outlined"
            {...getButtonProps('cancel')}
          >
            {request.status === 'COUNTER_OFFER_SENT'
              ? 'Cancel Counter Offer'
              : 'Cancel Offer'}
          </Button>
        ) : null}

        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={action === 'decline' && !form.isSubmitting}
          onClose={form.isSubmitting ? undefined : handleClose}
        >
          <FormikProvider value={form}>
            <Form>
              <DialogTitle>Decline Reason</DialogTitle>
              <DialogContent>
                <FormikTextField
                  multiline={true}
                  fullWidth={true}
                  name="declineReason"
                  placeholder='Giving a reason to decline can help the carrier to improve the counter offer. &#10;To skip without a reason click "Send".'
                  inputProps={{ style: { resize: 'none' }, maxLength: 120 }}
                  InputProps={{ rows: 4, rowsMax: 8 }}
                  label={
                    <TextBox color="secondary" align="right">
                      {`${values.declineReason.length || 0} of 120`}
                    </TextBox>
                  }
                />
              </DialogContent>

              <DialogActions>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleClose}
                  disabled={form.isSubmitting}
                >
                  Cancel
                </Button>

                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={form.isSubmitting}
                >
                  Send
                </Button>
              </DialogActions>
            </Form>
          </FormikProvider>
        </Dialog>
      </Box>
    </Stack>
  );
}
