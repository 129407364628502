import {
  Card,
  CardContent,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { Stack } from '@superdispatch/ui';
import { FunctionComponent } from 'react';
import Order from 'shared/types/order';
import { User } from 'shared/types/user';
import { userFullName } from './helpers/orderFormHelpers';

export interface DispatcherProps {
  order: Partial<Order>;
  users: User[];
}
interface OptionType {
  label: string;
  value: string;
}

const mapUsersToOptions = (users: User[]): OptionType[] =>
  users
    .filter((user) => user.first_name && user.last_name)
    .map((user) => ({
      label: userFullName(user),
      value: userFullName(user),
    }))
    .sort(({ label: a }, { label: b }) =>
      a.toLowerCase().localeCompare(b.toLowerCase()),
    );

export const Dispatcher: FunctionComponent<DispatcherProps> = ({ users }) => {
  const options = mapUsersToOptions(users);

  return (
    <Card>
      <CardContent>
        <Stack space="small">
          <Typography variant="h3">Dispatcher and Salesperson</Typography>
          <Grid container={true} spacing={2}>
            <Grid item={true} sm={12} md={6}>
              <FormikTextField
                name="dispatcher_name"
                select={true}
                label="Dispatcher"
                fullWidth={true}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">
                  <Typography
                    color="textSecondary"
                    style={{ fontStyle: 'italic' }}
                  >
                    None
                  </Typography>
                </MenuItem>
                {options.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    <Typography>{label}</Typography>
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid>

            <Grid item={true} sm={12} md={6}>
              <FormikTextField
                name="sales_representative"
                select={true}
                label="Salesperson"
                fullWidth={true}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">
                  <Typography
                    color="textSecondary"
                    style={{ fontStyle: 'italic' }}
                  >
                    None
                  </Typography>
                </MenuItem>
                {options.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    <Typography>{label}</Typography>
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
};
