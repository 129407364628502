import { Typography } from '@material-ui/core';
import { Inline } from '@superdispatch/ui';
import { useHistory } from 'react-router';
import { SuperPayLogo } from 'shared/icons/SuperPayLogo';
import styled from 'styled-components';

const PointerSuperPayLogo = styled(SuperPayLogo)`
  cursor: pointer;
`;

type Source =
  | 'Carrier Profile'
  | 'Carrier Search'
  | 'Carrier Requests Drawer'
  | 'Direct Offer Suggested'
  | 'Single Send Offer Drawer'
  | 'Direct Offer Previously Dispatched';

interface CarrierRequestsSuperPayProps {
  source: Source;
  compact?: boolean;
}

export function CarrierRequestsSuperPay({
  source,
  compact,
}: CarrierRequestsSuperPayProps) {
  const history = useHistory();

  return (
    <Inline space="xxsmall" verticalAlign="center">
      <Typography
        color="textSecondary"
        variant={compact ? 'caption' : undefined}
      >
        Requests
      </Typography>
      <PointerSuperPayLogo
        width={compact ? '60px' : undefined}
        onClick={() => {
          history.push({
            pathname: '/superpay',
            search: `?utm_medium=${source}&utm_content=Request SuperPay`,
          });
        }}
      />
    </Inline>
  );
}
