import { Box, Link, Typography } from '@material-ui/core';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { round } from 'lodash-es';
import { usePricingRecommendation } from 'orders/data/PricingSuggestionAPI';
import { useEffect, useMemo, useState } from 'react';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { SourceManager } from 'shared/helpers/SourceManager';
import { RecommendedPriceIcon } from 'shared/icons/RecommendedPriceIcon';
import Order from 'shared/types/order';
import { Step } from 'shared/types/step';
import { joinWords } from 'shared/utils/TextUtils';
import styled from 'styled-components';
import { OrderFormStep, OrderFormValues } from '../form/OrderForm';
import { PricingInsightsDrawer } from './PricingInsightsDrawer';

const BelowText = styled(TextBox)`
  color: ${Color.Yellow500};
`;

const PointerLink = styled(Link)`
  cursor: pointer;
`;

const Container = styled.div`
  background-color: ${Color.Silver200};
  display: inline-flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
`;

const PaymentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

function isCanadianAddress(step?: Step | OrderFormStep) {
  return /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(step?.venue?.zip || '');
}

export function isOrderBelowRecommendedPrice(
  order: Partial<Order> | OrderFormValues,
) {
  if (!order.recommended_price || !order.price) {
    return false;
  }

  if (!isPricingRecommendationAvailable(order) || validateOrder(order)) {
    return false;
  }

  return order.price < order.recommended_price;
}

const allowedStatuses = ['new', 'canceled', 'declined', 'order_canceled'];
function isPricingRecommendationAvailable(
  order: Partial<Order> | OrderFormValues,
) {
  if (order.is_archived) {
    return false;
  }

  if (!order.status || allowedStatuses.includes(order.status)) {
    return true;
  }

  if (order.is_posted_to_loadboard) {
    return true;
  }

  return false;
}

interface Props {
  order: Partial<Order> | OrderFormValues;
  onPriceClick: (value: number) => void;
  recommendedPrice?: number | null;
  source: string;
}

export function PricingRecommendation({
  order,
  onPriceClick,
  recommendedPrice = order.recommended_price,
  source,
}: Props) {
  const [isOpenPricingInsightsDrawer, setIsOpenPricingInsightsDrawer] =
    useState(false);

  if (!isPricingRecommendationAvailable(order) || validateOrder(order)) {
    return null;
  }

  const link = (
    <Link
      display="inline"
      href="#"
      onClick={(event) => {
        event.preventDefault();
        setIsOpenPricingInsightsDrawer(true);
        trackEvent('Shipper Clicked Pricing Insights Link');
      }}
    >
      Pricing Insights
    </Link>
  );

  if (!recommendedPrice) {
    return (
      <Inline space="xxsmall">
        <RecommendedPriceIcon />

        <TextBox color="secondary">
          Recommended Price not available for the chosen location.
        </TextBox>
      </Inline>
    );
  }

  return (
    <SourceManager secondarySource="Recommended Price">
      <Container data-intercom-target="pricing recommendation">
        <Columns space="xxsmall" align="top">
          <Column width="content">
            <Box paddingTop="2px">
              <RecommendedPriceIcon fontSize="small" />
            </Box>
          </Column>

          <Column>
            {order.price == null ? (
              <Stack space="none">
                <TextBox variant="body-semibold">
                  {formatCurrency(recommendedPrice, {
                    maximumFractionDigits: 0,
                  })}{' '}
                  Recommended Price
                </TextBox>

                <Inline space="none">
                  <PointerLink
                    color="primary"
                    onClick={() => {
                      onPriceClick(recommendedPrice);
                      trackEvent('Shipper Clicked Set Recommended Price', {
                        utm_medium: source,
                      });
                    }}
                  >
                    Set to{' '}
                    {formatCurrency(recommendedPrice, {
                      maximumFractionDigits: 0,
                    })}
                  </PointerLink>

                  <TextBox>&nbsp;for faster booking · ‎</TextBox>
                  {link}
                </Inline>
              </Stack>
            ) : order.price > recommendedPrice ? (
              <div>
                <TextBox>
                  {formatCurrency(recommendedPrice, {
                    maximumFractionDigits: 0,
                  })}{' '}
                  is recommended based on · ‎
                </TextBox>
                {link}
              </div>
            ) : round(order.price, 2) === round(recommendedPrice, 2) ? (
              <Stack space="none">
                <TextBox variant="body-semibold">Good Price</TextBox>
                <div>
                  <TextBox display="inline">
                    {formatCurrency(recommendedPrice, {
                      maximumFractionDigits: 0,
                    })}{' '}
                    is Recommended Price · ‎
                  </TextBox>
                  {link}
                </div>
              </Stack>
            ) : (
              <Stack space="none">
                <BelowText variant="body-semibold">
                  Below Recommended Price
                </BelowText>

                <div>
                  <PointerLink
                    display="inline"
                    color="primary"
                    onClick={() => {
                      onPriceClick(recommendedPrice);
                      trackEvent('Shipper Clicked Set Recommended Price', {
                        utm_medium: source,
                      });
                    }}
                  >
                    Set to{' '}
                    {formatCurrency(recommendedPrice, {
                      maximumFractionDigits: 0,
                    })}
                  </PointerLink>

                  <TextBox display="inline">
                    &nbsp;for faster booking · ‎
                  </TextBox>
                  {link}
                </div>
              </Stack>
            )}
          </Column>
        </Columns>

        <PricingInsightsDrawer
          order={order as Partial<Order>}
          open={isOpenPricingInsightsDrawer}
          onClose={() => {
            setIsOpenPricingInsightsDrawer(false);
          }}
        />
      </Container>
    </SourceManager>
  );
}

interface PricingRecommendationCounterOfferProps {
  source: string;
  order: Partial<Order> | OrderFormValues;
  counterOfferPrice: number;
  onPriceClick: (value: number) => void;
}

export function PricingRecommendationCounterOffer({
  source,
  order,
  counterOfferPrice,
  onPriceClick,
}: PricingRecommendationCounterOfferProps) {
  const recommendedPrice = order.recommended_price;

  if (!isPricingRecommendationAvailable(order) || validateOrder(order)) {
    return null;
  }

  if (!recommendedPrice || counterOfferPrice == null) {
    return null;
  }

  if (recommendedPrice <= counterOfferPrice) {
    return null;
  }

  return (
    <div data-intercom-target="pricing recommendation">
      <Stack space="none">
        <BelowText variant="body-semibold">Below Recommended Price</BelowText>

        <div>
          <PointerLink
            display="inline"
            color="primary"
            onClick={() => {
              onPriceClick(recommendedPrice);
              trackEvent('Shipper Clicked Set Recommended Price', {
                utm_medium: source,
              });
            }}
          >
            Set to{' '}
            {formatCurrency(recommendedPrice, {
              maximumFractionDigits: 0,
            })}
          </PointerLink>

          <TextBox display="inline">&nbsp;for faster booking</TextBox>
        </div>
      </Stack>
    </div>
  );
}

const BelowContainer = styled.div`
  width: 100%;
  margin-left: -22px;
  display: inline-flex;
  align-items: center;
`;

interface PricingRecommendationOrderListProps {
  order: Partial<Order> | OrderFormValues;
  onPriceClick: (value: number) => void;
  recommendedPrice?: number | null;
}

export function PricingRecommendationOrderList({
  order,
  onPriceClick,
  recommendedPrice = order.recommended_price,
}: PricingRecommendationOrderListProps) {
  const [isOpenPricingInsightsDrawer, setIsOpenPricingInsightsDrawer] =
    useState(false);

  if (!isPricingRecommendationAvailable(order) || validateOrder(order)) {
    return null;
  }

  if (!isOrderBelowRecommendedPrice(order)) {
    return null;
  }

  if (!recommendedPrice) {
    return null;
  }

  const link = (
    <Link
      variant="caption"
      color="textSecondary"
      href="#"
      onClick={(event) => {
        event.preventDefault();
        setIsOpenPricingInsightsDrawer(true);
        trackEvent('Shipper Clicked Pricing Insights Link', {
          utm_source: 'Order List',
        });
      }}
    >
      Pricing Insights
    </Link>
  );

  return (
    <SourceManager secondarySource="Recommended Price">
      <BelowContainer data-intercom-target="pricing recommendation">
        <Inline verticalAlign="top" noWrap={true}>
          <Box paddingTop="2px">
            <RecommendedPriceIcon fontSize="small" />
          </Box>

          <Stack space="none">
            <BelowText variant="caption">Below Recommended Price</BelowText>

            <Inline space="xxsmall">
              <PointerLink
                color="primary"
                variant="caption"
                onClick={() => {
                  onPriceClick(recommendedPrice);
                  trackEvent('Shipper Clicked Set Recommended Price', {
                    utm_medium: 'Order List',
                  });
                }}
              >
                Set to{' '}
                {formatCurrency(recommendedPrice, {
                  maximumFractionDigits: 0,
                })}
              </PointerLink>

              <Typography variant="caption" color="textSecondary">
                for faster booking ·‎
              </Typography>
              {link}
            </Inline>
          </Stack>
        </Inline>

        <PricingInsightsDrawer
          order={order as Partial<Order>}
          open={isOpenPricingInsightsDrawer}
          onClose={() => {
            setIsOpenPricingInsightsDrawer(false);
          }}
        />
      </BelowContainer>
    </SourceManager>
  );
}

export function validateOrder(order: Partial<Order> | OrderFormValues) {
  const isVehicleMakeMissing = order.vehicles?.some((vehicle) => !vehicle.make);
  const isVehicleModelMissing = order.vehicles?.some(
    (vehicle) => !vehicle.model,
  );
  const isVehicleYearMissing = order.vehicles?.some((vehicle) => !vehicle.year);
  const hasInvalidVehicleType = order.vehicles?.some((vehicle) => {
    if (!vehicle.type) {
      return true;
    }
    return ![
      'suv',
      '2_door_coupe',
      'sedan',
      'truck',
      'van',
      'pickup',
      '4_door_pickup',
    ].includes(vehicle.type);
  });

  // initial empty order
  if (
    (!order.vehicles?.length ||
      (order.vehicles.length === 1 &&
        isVehicleModelMissing &&
        isVehicleMakeMissing &&
        isVehicleYearMissing &&
        hasInvalidVehicleType)) &&
    !order.pickup?.venue?.state &&
    !order.pickup?.venue?.city &&
    !order.delivery?.venue?.state &&
    !order.delivery?.venue?.city
  ) {
    return 'Add Vehicle, Pickup, and Delivery info for the Recommended Price.';
  }

  const requiredFields = [
    !order.vehicles?.length &&
      'Vehicle Year, Type (choose Sedan, Coupe, SUV, Van, or Pickup)',
    isVehicleYearMissing && hasInvalidVehicleType
      ? 'Vehicle Year, Type (choose Sedan, Coupe, SUV, Van, or Pickup)'
      : hasInvalidVehicleType
      ? 'Vehicle Type (choose Sedan, Coupe, SUV, Van, or Pickup)'
      : isVehicleYearMissing
      ? 'Vehicle Year'
      : null,
    !order.pickup?.venue?.state && !order.pickup?.venue?.city
      ? 'Pickup City, State'
      : !order.pickup.venue.state
      ? 'Pickup State'
      : !order.pickup.venue.city
      ? 'Pickup City'
      : null,
    !order.delivery?.venue?.state && !order.delivery?.venue?.city
      ? 'Delivery City, State'
      : !order.delivery.venue.state
      ? 'Delivery State'
      : !order.delivery.venue.city
      ? 'Delivery City'
      : null,
  ].filter(Boolean);

  if (requiredFields.length) {
    return `Add ${joinWords(...requiredFields)} for Recommended Price.`;
  }

  if (isCanadianAddress(order.pickup) || isCanadianAddress(order.delivery)) {
    return "Recommended Price isn't available for Canadian ZIP codes yet.";
  }

  if (order.transport_type === 'DRIVEAWAY') {
    return "Recommended Price isn't available for Driveaway transport type yet. Choose Open or Enclosed.";
  }

  return null;
}

interface PriceRecommendationOrderFormProps {
  order: Partial<Order> | OrderFormValues;
  onPriceClick: (value: number) => void;
  onPriceRecommendation: (value: number | null) => void;
  source: string;
}

export function PriceRecommendationOrderForm({
  order,
  onPriceClick,
  onPriceRecommendation: onPricingRecommendation,
  source,
}: PriceRecommendationOrderFormProps) {
  const validationError = useMemo(() => validateOrder(order), [order]);
  const isEnabled = isPricingRecommendationAvailable(order) && !validationError;
  const {
    data: recommendedPrice,
    isFetching,
    error,
  } = usePricingRecommendation(isEnabled ? order : undefined, {
    keepPreviousData: true,
  });

  useEffect(() => {
    if (!isEnabled || error) {
      onPricingRecommendation(null);
    } else if (recommendedPrice !== undefined) {
      onPricingRecommendation(recommendedPrice);
    }
  }, [isEnabled, recommendedPrice, error]);

  if (!isPricingRecommendationAvailable(order)) {
    return null;
  }

  if (validationError) {
    return (
      <PaymentContainer>
        <RecommendedPriceIcon />
        <TextBox color="secondary" noWrap={false}>
          {validationError}
        </TextBox>
      </PaymentContainer>
    );
  }

  if (isFetching && recommendedPrice) {
    return (
      <Container>
        <Inline verticalAlign="center">
          <RecommendedPriceIcon />
          <TextBox color="secondary">
            New details received! Updating from{' '}
            {formatCurrency(recommendedPrice, { maximumFractionDigits: 0 })}…
          </TextBox>
        </Inline>
      </Container>
    );
  }

  if (isFetching && !recommendedPrice) {
    return (
      <div>
        <Inline verticalAlign="center">
          <RecommendedPriceIcon />
          <TextBox color="secondary">
            Generating your pricing recommendation…
          </TextBox>
        </Inline>
      </div>
    );
  }

  return (
    <PricingRecommendation
      order={order}
      onPriceClick={onPriceClick}
      source={source}
    />
  );
}

interface PricingRecommendationViewOnlyProps {
  order: Partial<Order> | OrderFormValues;
}

export function PricingRecommendationCarrierRequest({
  order,
}: PricingRecommendationViewOnlyProps) {
  const recommendedPrice = order.recommended_price;

  if (!isPricingRecommendationAvailable(order) || validateOrder(order)) {
    return null;
  }

  if (!recommendedPrice) {
    return null;
  }

  return (
    <Container style={{ width: '100%' }}>
      <Inline verticalAlign="center" noWrap={true}>
        <RecommendedPriceIcon />

        <TextBox variant="heading-5">
          {formatCurrency(recommendedPrice, { maximumFractionDigits: 0 })}{' '}
          Recommended Price
        </TextBox>
      </Inline>
    </Container>
  );
}
