import { Link, MenuItem, Typography } from '@material-ui/core';
import {
  FormikContextTypeEnhanced,
  FormikDateField,
  FormikTextField,
} from '@superdispatch/forms';
import { Stack } from '@superdispatch/ui';
import {
  CarrierAutocomplete,
  CarrierAutocompleteValue,
} from 'core/CarrierAutocomplete';
import { FormikProvider } from 'formik';
import { PrivateNetworkCheckboxField } from 'manage-carriers/core/PrivateNetworkCheckboxField';
import { useCallback } from 'react';
import { useSourceManagerLegacy } from 'shared/helpers/SourceManagmentLegacy';
import {
  CarrierBrokerPreferences,
  CarrierBrokerPreferencesEdit,
} from 'shared/types/carrier';
import styled from 'styled-components';
import { useFeatureToggle } from '../../shared/data/FeatureToggle';
import { NetworkDrawerAttachments } from './NetworkDrawerAttachments';
import { NetworkDrawerCarrierStatus } from './NetworkDrawerCarrierStatus';
import { NetworkDrawerCertHolder } from './NetworkDrawerCertHolder';
import { NetworkDrawerInsuranceFile } from './NetworkDrawerInsuranceFile';
import { NetworkDrawerStatus } from './NetworkDrawerStatus';
import { PrivateNetworkStatusChangeWarningBox } from './PrivateNetworkStatusChangeWarningBox';

const carrierStatusOptions = [
  {
    label: 'Approved',
    value: 'APPROVED',
  },
  {
    label: 'Blocklisted',
    value: 'IN_BLACKLIST',
  },
  {
    label: 'Default',
    value: 'DEFAULT',
  },
];

const DrawerContent = styled.div`
  max-width: 600px;
  width: 100%;
`;

interface NetworkDrawerContentProps {
  onClose: () => void;
  brokerRecords: CarrierBrokerPreferences | undefined;
  onCarrierChange: (carrier: CarrierAutocompleteValue) => void;
  carrier: CarrierAutocompleteValue;
  formik: FormikContextTypeEnhanced<CarrierBrokerPreferencesEdit, unknown>;
  isCarrierInternal: boolean;
}

export const NetworkDrawerContent = ({
  brokerRecords,
  onCarrierChange,
  carrier,
  formik,
  onClose,
  isCarrierInternal,
}: NetworkDrawerContentProps) => {
  const renderCarrierStatusLabel = useCallback(
    (value: unknown) => (
      <NetworkDrawerCarrierStatus
        value={value}
        brokerRecords={brokerRecords || null}
      />
    ),
    [brokerRecords],
  );

  useSourceManagerLegacy('Add to Network Drawer');

  const isPrivateNetworkEnabled = useFeatureToggle('private-network.enabled');

  return (
    <DrawerContent>
      <Stack>
        <Typography variant="subtitle2" color="textPrimary" noWrap={true}>
          Search for a Carrier
        </Typography>
        <CarrierAutocomplete
          hideLabel={true}
          withFMCSA={true}
          value={carrier}
          onChange={(value) => onCarrierChange(value)}
        />
      </Stack>
      <br />
      <FormikProvider value={formik}>
        <Stack space="large">
          <Stack space="small">
            <Stack space="xsmall">
              <FormikTextField
                name="status"
                label="Carrier Status"
                fullWidth={true}
                select={true}
                SelectProps={{
                  renderValue: renderCarrierStatusLabel,
                }}
              >
                {carrierStatusOptions.map(({ label, value }, idx) => (
                  <MenuItem key={idx} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </FormikTextField>

              <Link
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                href="https://support.superdispatch.com/en/articles/4165914-approved-carrier-status-for-carriers"
              >
                Learn More about Carrier Status
              </Link>
              {brokerRecords?.is_in_private_network &&
                formik.values.status !== 'APPROVED' &&
                formik.initialValues.status === 'APPROVED' && (
                  <PrivateNetworkStatusChangeWarningBox />
                )}
            </Stack>

            {!isCarrierInternal && formik.values.status === 'APPROVED' && (
              <Stack space="small">
                <NetworkDrawerStatus
                  preferred={
                    !!(formik.values.preferred && brokerRecords?.preferred)
                  }
                  preferredSince={brokerRecords?.preferred_since || null}
                />

                {isPrivateNetworkEnabled && (
                  <PrivateNetworkCheckboxField
                    source="Add Carrier to the Network Drawer"
                    onRequestAccess={onClose}
                  />
                )}
              </Stack>
            )}
          </Stack>

          <Stack space="small">
            <Typography variant="h4">Certificate Holder</Typography>
            <NetworkDrawerCertHolder
              holder={
                !!(
                  formik.values.insurance_certificate_holder &&
                  brokerRecords?.insurance_certificate_holder_since
                )
              }
              holderSince={
                brokerRecords?.insurance_certificate_holder_since || null
              }
            />

            <FormikDateField
              label="Expiration Date"
              name="insurance_expires_at"
              format="DateISO"
              fullWidth={true}
              enableClearable={true}
            />

            <NetworkDrawerInsuranceFile />
          </Stack>

          <NetworkDrawerAttachments />

          <FormikTextField
            name="custom_external_id"
            label="Custom External ID"
            fullWidth={true}
            helperText="This field can be used to store any information and is available through API"
          />
        </Stack>
      </FormikProvider>
    </DrawerContent>
  );
};
