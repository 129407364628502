import { Tab, Tabs } from '@material-ui/core';
import { ChangeEvent, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useOrderCounts } from 'shared/api/OrderCountAPI';
import { useUserState } from 'shared/data/AppUserState';
import { WhoAmI } from 'shared/types/user';

function getTabs(user?: WhoAmI): ListItem[] {
  return [
    { label: 'All Load Boards', status: 'posted_to_lb' },
    { label: 'Super Loadboard', status: 'posted_to_sdlb' },
    user?.shipper.is_private_network_enabled && {
      label: 'Private Loadboard',
      status: 'posted_to_private_loadboard',
    },
    { label: 'Central Dispatch', status: 'posted_to_cd' },
  ].filter(Boolean) as ListItem[];
}

interface ListItem {
  label: string;
  status: string;
}

interface OrdersListTabsProps {
  status: string;
}

export function OrdersListTabs({ status }: OrdersListTabsProps) {
  const history = useHistory();
  const { search } = useLocation();
  const { data: counts } = useOrderCounts();
  const { user } = useUserState();
  const listItems = useMemo(() => getTabs(user), [user]);

  const defaultIndex = listItems.findIndex((tab) => status === tab.status);

  const handleChange = (_: ChangeEvent<unknown>, newValue: number) => {
    const item = listItems[newValue];
    if (item) {
      history.push({
        search,
        pathname: `/orders/${item.status}`,
      });
    }
  };

  return (
    <Tabs onChange={handleChange} value={defaultIndex}>
      {listItems.map((tab, index) => {
        const count = counts?.[tab.status];
        const label = count ? `${tab.label} (${String(count)})` : tab.label;
        return <Tab key={tab.status} value={index} label={label} />;
      })}
    </Tabs>
  );
}
