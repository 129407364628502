import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useOrderCounts } from 'shared/api/OrderCountAPI';
import { useTrackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useSelfServeOnlyProductTour } from 'shared/hooks/useProductTour';
import { isRouteStatus } from 'shared/types/routeStatus';
import { AccessRestrictedDialog } from './core/actions/AccessRestrictedDialog';
import { OrderCreatePage } from './create/OrderCreatePage';
import { DashboardOrdersPage } from './dashboard/DashboardOrdersPage';
import { OrderEditPage } from './edit/OrderEditPage';
import { OrderImportPage } from './import/OrderImportPage';
import { OrdersListPage } from './list/OrdersListPage';
import { OrderRequestsPage } from './order-requests/OrderRequestsPage';
import { OrderPreviewPage } from './preview/OrderPreviewPage';
import { OrderViewPage } from './view/OrderViewPage';

const CombineOrdersPageLazy = lazy(
  () => import('./combine-orders/CombineOrdersPage'),
);

const OrderSplitLoadsPageLazy = lazy(
  () => import('./split-loads/OrderSplitLoadsPage'),
);

const OrderSplitPageLazy = lazy(() => import('./order-split/OrderSplitPage'));

export function OrdersPage() {
  useTrackEvent('[STMS] Viewed Orders Page');
  useOrdersPageTour();

  return (
    <>
      <Switch>
        <Route path="/orders/create">
          <OrderCreatePage />
        </Route>

        <Route
          path="/orders/edit/:guid"
          render={({ match }) => (
            <OrderEditPage orderGUID={match.params.guid} />
          )}
        />

        <Route
          path="/orders/view/:guid"
          render={(props) => (
            <OrderViewPage
              isActive={true}
              orderGUID={props.match.params.guid}
              {...props}
            />
          )}
        />

        <Route
          path="/orders/inactive/view/:guid"
          render={(props) => (
            <OrderViewPage
              isActive={false}
              orderGUID={props.match.params.guid}
              {...props}
            />
          )}
        />

        <Route
          path="/orders/split/:guid"
          render={({ match }) => (
            <OrderSplitPageLazy orderGUID={match.params.guid} />
          )}
        />

        <Route
          path="/orders/split-loads/:guid"
          render={({ match }) => (
            <OrderSplitLoadsPageLazy orderGUID={match.params.guid} />
          )}
        />

        <Route path="/orders/combine">
          <CombineOrdersPageLazy />
        </Route>

        <Route
          path="/orders/preview/:type/:orderID"
          render={({ match }) => (
            <OrderPreviewPage
              type={match.params.type}
              orderID={match.params.orderID}
            />
          )}
        />

        <Route path="/orders/import">
          <OrderImportPage />
        </Route>

        <Route path="/orders/requests">
          <OrderRequestsPage />
        </Route>

        <Route
          path="/overview/orders/:type"
          render={({ match: { params } }) => (
            <DashboardOrdersPage type={params.type} />
          )}
        />

        <Route
          path="/orders/:status?"
          render={({ match: { params } }) => {
            let status;
            if (isRouteStatus(params.status)) {
              status = params.status;
            }
            return <OrdersListPage status={status} />;
          }}
        />
      </Switch>
      <AccessRestrictedDialog />
    </>
  );
}

function useIsFirstOrderPostedToLb() {
  const { data: orderCounts } = useOrderCounts();

  if (!orderCounts) {
    return false;
  }

  const countersThatShouldBeZero: Array<keyof typeof orderCounts> = [
    'new',
    'pending',
    'declined',
    'accepted',
    'picked_up',
    'delivered',
    'invoiced',
    'paid',
    'canceled',
    'requests',
  ];

  return (
    orderCounts.posted_to_lb > 0 &&
    countersThatShouldBeZero.every((key) => orderCounts[key] === 0)
  );
}

function useOrdersPageTour() {
  const { data: orderCounts } = useOrderCounts();

  useSelfServeOnlyProductTour({
    tourKey: 'self_serve_reboot_orders_page',
    enabled: orderCounts?.total === 0,
  });

  useSelfServeOnlyProductTour({
    tourKey: 'self_serve_reboot_orders_page_highlight_requests',
    enabled: useIsFirstOrderPostedToLb(),
  });
}
