import { Link, MenuItem } from '@material-ui/core';
import {
  FormikDateField,
  FormikTextField,
  SuspendedFormikPhoneField,
} from '@superdispatch/forms';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { startCase } from 'lodash-es';
import { useState } from 'react';
import { FormikFileField } from '../../shared/form/FormikFileField';
import { trackVerificationApplicationEvent } from '../data/VerificationAnalyticsEvents';
import { INSURANCE_TYPE } from '../data/VerificationApplicationDTO';
import { InsuranceHelpDrawer } from './InsuranceHelpDrawer';

export function InsuranceForm() {
  const [currentDrawer, setCurrentDrawer] = useState<
    'insurance_help' | undefined
  >();

  return (
    <Stack space="small">
      <InsuranceHelpDrawer
        open={currentDrawer === 'insurance_help'}
        onClose={() => setCurrentDrawer(undefined)}
      />
      <FormikFileField
        label="Certificate of Insurance"
        name="insurance_certificate_url"
        helperText={
          <Link
            color="primary"
            href="#"
            onClick={(event) => {
              event.preventDefault();
              trackVerificationApplicationEvent({
                name: 'Shipper Clicked on Learn More',
                utm_content: 'Certificate of Insurance',
              });
              setCurrentDrawer('insurance_help');
            }}
          >
            Learn more
          </Link>
        }
      />
      <Columns space="small" collapseBelow="tablet">
        <Column>
          <FormikTextField
            label="Insurance type"
            name="company_insurance_type"
            select={true}
            fullWidth={true}
          >
            {INSURANCE_TYPE.map((value) => (
              <MenuItem key={value} value={value}>
                {startCase(value.toLowerCase())}
              </MenuItem>
            ))}
          </FormikTextField>
        </Column>
        <Column>
          <FormikTextField
            label="Insurance/Bond Holder Name"
            name="bond_company_name"
            fullWidth={true}
          />
        </Column>
      </Columns>
      <FormikTextField
        label="Agent Name (Optional)"
        name="bond_agent_name"
        fullWidth={true}
      />
      <FormikDateField
        label="Insurance/Bond Expiration Date"
        name="bond_expiration_date"
        format="DateISO"
        enableClearable={true}
        fullWidth={true}
      />
      <SuspendedFormikPhoneField
        name="bond_agent_phone"
        label="Insurance/Bond Holder Phone Number"
        fullWidth={true}
      />
    </Stack>
  );
}
