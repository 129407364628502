import { Box, Card, Typography } from '@material-ui/core';
import { CheckCircle, Help } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box as SdBox, Button } from '@superdispatch/ui-lab';
import { useHistory } from 'react-router';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { ResponsiveTooltip } from 'shared/ui/ResponsiveTooltip';
import styled from 'styled-components';
import { useSubscriptionPlanPrices } from '../shared/subscription-plans/useSubscriptionPlanPrices';

const StyledRoot = styled(Card)`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(320px, 396px) minmax(274px, 1fr) minmax(
      274px,
      1fr
    );
  grid-template-rows: max-content auto;
`;

const StyledHeaderCell = styled.div`
  background-color: ${Color.Silver200};
`;

const StyledCell = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Color.Silver400};
  border-right: 1px solid ${Color.Silver400};

  &:nth-child(3n) {
    border-right: none;
  }

  &:nth-last-child(-n + 3) {
    border-bottom: none;
  }
`;

const rows = [
  {
    feature: 'Super Loadboard',
    helpText:
      'Marketplace connecting shippers with trusted carriers instantly, powered by Al suggestions to get loads moved faster.',
    isIncludedInExpress: true,
  },
  {
    feature: 'Order Management',
    helpText:
      'Simplify order management: effortlessly create, import, edit, cancel, and send direct offers.',
    isIncludedInExpress: true,
  },
  {
    feature: 'User Management',
    helpText:
      'Easily add, remove, and manage accounts and access based on user roles within the platform.',
    isIncludedInExpress: true,
  },
  {
    feature: 'GPS Load Tracking & ETA',
    helpText: `Track every vehicle's location and status in real-time to ensure timely deliveries.`,
    isIncludedInExpress: true,
  },
  {
    feature: 'Order Status Notifications',
    helpText:
      'Keep track of every load with real-time tracking, ETA updates, and status notifications, ensuring complete visibility throughout the shipping process.',
    isIncludedInExpress: true,
  },
  {
    feature: 'Electronic Documentation',
    helpText:
      'Instant access to Electronic Bills of Lading (eBOL), inspection photos, and invoices, providing you with the documentation you need when you need it.',
    isIncludedInExpress: true,
  },
  {
    feature: 'Pricing Recommendations',
    helpText:
      'Receive a recommended price on new orders to move loads fast at the most competitive rates.',
    isIncludedInExpress: true,
  },
  {
    feature: 'SuperPay',
    helpText:
      'Accelerate carrier payments using our integrated payment solution without the need to exchange bank account details.',
    isIncludedInExpress: true,
  },
  {
    feature: 'Verified Carrier Network',
    helpText:
      'Instant access to thousands of trusted carriers, all rigorously monitored to ensure active authority and cargo insurance coverage.',
    isIncludedInExpress: true,
  },
  {
    feature: 'Carrier Ratings & Reviews',
    helpText:
      'Make informed carrier decisions by leveraging real feedback from previous shippers who have conducted business with them.',
    isIncludedInExpress: true,
  },
  {
    feature: 'Carrier Management',
    helpText:
      'Easily manage carriers, whether they are existing Super Dispatch customers or not, identify preferred carriers, and enable others to book instantly.',
    isIncludedInExpress: false,
  },
  {
    feature: 'Customer Management',
    helpText:
      'Build out your customer database to easily access customer details when creating new orders.',
    isIncludedInExpress: false,
  },
  {
    feature: 'Advanced Order Management',
    helpText:
      'Customize order fields to match your business needs and automate dispatching with instant booking.',
    isIncludedInExpress: false,
  },
  {
    feature: 'Pricing Insights',
    helpText:
      'Access instant pricing insights based on current and historical market trends by reviewing carrier transport rates for all vehicle types.',
    isIncludedInExpress: false,
  },
  {
    feature: 'Billing Management',
    helpText:
      'Effortlessly generate, oversee, bill, and process invoices for both customers and carriers instantly through a single platform.',
    isIncludedInExpress: false,
  },
  {
    feature: 'Performance Dashboard',
    helpText:
      'At-a-glance view of essential business metrics, including aging orders, VINs delivered, as well as delayed and scheduled pickups and deliveries.',
    isIncludedInExpress: false,
  },
  {
    feature: 'Reporting',
    helpText:
      'Get instant access to the order and financial data you need to make informed business decisions.',
    isIncludedInExpress: false,
  },
  {
    feature: 'Automated Pricing Negotiation',
    helpText: `Eliminate negotiation hassles by letting Super Dispatch negotiate on your behalf to secure the lowest carrier rate.`,
    isIncludedInExpress: false,
  },
] as const;

export function ComparePlansTable() {
  const history = useHistory();
  const { prices, isSubscriptionPlansLoading } = useSubscriptionPlanPrices([
    'express',
    'pro-100',
  ]);

  return (
    <StyledRoot>
      <StyledHeaderCell>
        <SdBox
          paddingTop="medium"
          paddingBottom="medium"
          paddingLeft="large"
          paddingRight="large"
        >
          <Stack>
            <Typography variant="h2">Compare Plans</Typography>
            <Typography color="textSecondary">
              Select the plan that perfectly matches your business needs.
            </Typography>
          </Stack>
        </SdBox>
      </StyledHeaderCell>

      <StyledHeaderCell>
        <SdBox padding="medium">
          <Stack>
            <Typography variant="h2">Shipper Express</Typography>

            <Inline space="none" verticalAlign="bottom">
              <Typography variant="h1" style={{ fontWeight: 500 }}>
                {prices?.express !== undefined &&
                  formatCurrency(prices.express, {
                    maximumFractionDigits: 0,
                  })}

                {isSubscriptionPlansLoading && <Skeleton width={78} />}
              </Typography>{' '}
              <Typography
                variant="h4"
                color="textSecondary"
                style={{ marginBottom: 3 }}
              >
                / month
              </Typography>
            </Inline>

            <Button
              fullWidth={true}
              size="medium"
              variant="neutral"
              onClick={() =>
                history.push('/subscription-plans/shipper-express')
              }
            >
              Select Plan
            </Button>
          </Stack>
        </SdBox>
      </StyledHeaderCell>

      <StyledHeaderCell>
        <SdBox padding="medium">
          <Stack>
            <Typography variant="h2">Shipper Pro</Typography>

            <Inline space="none" verticalAlign="bottom">
              <Typography
                variant="h5"
                color="textSecondary"
                style={{ marginBottom: 3 }}
              >
                Starts at &nbsp;
              </Typography>
              <Typography variant="h1" style={{ fontWeight: 500 }}>
                {prices?.['pro-100'] !== undefined &&
                  formatCurrency(prices['pro-100'], {
                    maximumFractionDigits: 0,
                  })}

                {isSubscriptionPlansLoading && <Skeleton width={78} />}
              </Typography>{' '}
              <Typography
                variant="h4"
                color="textSecondary"
                style={{ marginBottom: 3 }}
              >
                / month
              </Typography>
            </Inline>

            <Button
              fullWidth={true}
              size="medium"
              variant="primary"
              onClick={() => history.push('/subscription-plans/shipper-pro')}
            >
              Choose Your Package
            </Button>
          </Stack>
        </SdBox>
      </StyledHeaderCell>

      {rows.map(({ feature, isIncludedInExpress, helpText }) => (
        <>
          <StyledCell>
            <Columns align="center">
              <Column width="fluid">
                <Typography variant="h4">{feature}</Typography>
              </Column>
              <Column width="content">
                <ResponsiveTooltip
                  interactive={true}
                  placement="bottom"
                  title={helpText}
                >
                  <Help fontSize="small" color="action" />
                </ResponsiveTooltip>
              </Column>
            </Columns>
          </StyledCell>

          <StyledCell>{isIncludedInExpress && <CheckmarkContent />}</StyledCell>

          <StyledCell>
            <CheckmarkContent />
          </StyledCell>
        </>
      ))}
    </StyledRoot>
  );
}

function CheckmarkContent() {
  return (
    <Box display="flex" flexGrow={1} justifyContent="center">
      <CheckCircle style={{ color: Color.Green500 }} />
    </Box>
  );
}
