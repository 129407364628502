import { CircularProgress } from '@material-ui/core';
import { Flex } from '@rebass/grid';
import { Suspense } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useUserState } from 'shared/data/AppUserState';
import { useProductTiers } from 'shared/data/TiersUtils';
import { AppNavbar } from 'shared/layout/AppNavbar';

export function AppRoute(props: RouteProps) {
  return (
    <AppNavbar>
      <AppBlankRoute {...props} />
    </AppNavbar>
  );
}

export function AppBlankRoute(props: RouteProps) {
  const { authState } = useUserState();

  if (authState === 'pending') {
    return (
      <Flex flex={1} alignItems="center" justifyContent="center">
        <CircularProgress />
      </Flex>
    );
  }

  return (
    <Suspense
      fallback={
        <Flex flex={1} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Flex>
      }
    >
      <Route {...props} />
    </Suspense>
  );
}

export function ProtectedRoute(props: RouteProps) {
  const { isBasicTier } = useProductTiers();

  if (isBasicTier) {
    return <Redirect to="/orders" />;
  }

  return <Route {...props} />;
}

export function AppProtectedRoute(props: RouteProps) {
  const { isBasicTier } = useProductTiers();

  if (isBasicTier) {
    return <Redirect to="/orders" />;
  }

  return <AppRoute {...props} />;
}
