import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { plainToClass } from 'class-transformer';
import { FormikProvider } from 'formik';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { Carrier } from 'shared/types/carrier';
import { InternalCarrierForm } from '../core/InternalCarrierForm';
import { useCarrierProfileAPI } from '../data/ManageCarriersAPI';

export function CreateInternalCarrierPage() {
  const history = useHistory();
  const { addSnackbar } = useSnackbarStack();
  const { createInternalCarrier } = useCarrierProfileAPI();
  const initialValues = useMemo(() => plainToClass(Carrier, {}), []);

  const form = useFormikEnhanced({
    initialValues,
    onSubmit: (values) => createInternalCarrier(values),
    onSubmitSuccess() {
      trackEvent('[STMS] Created Internal Carrier');
      history.push('/manage-carriers?carrier_type=internal');
    },
    onSubmitFailure(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <FormikProvider value={form}>
      <InternalCarrierForm title="New Internal Carrier" />
    </FormikProvider>
  );
}
