import { DirectionsRenderer, GoogleMap } from '@react-google-maps/api';
import * as React from 'react';
import { useGoogleMapsContext } from 'shared/google-maps/GoogleMapsContext';

interface MapContainerProps {
  width?: string;
  height?: string;
  directions?: google.maps.DirectionsResult | null;
  mapContainerStyle?: React.CSSProperties;
}

export function MapContainer({
  directions,
  mapContainerStyle,
}: MapContainerProps) {
  const { googleMaps } = useGoogleMapsContext();

  if (!googleMaps) {
    return null;
  }

  return (
    <GoogleMap
      zoom={4}
      mapContainerStyle={{
        height: '180px',
        width: '100%',
        ...mapContainerStyle,
      }}
      options={{ gestureHandling: 'cooperative', disableDefaultUI: true }}
    >
      {directions && <DirectionsRenderer directions={directions} />}
    </GoogleMap>
  );
}
