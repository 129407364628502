import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import { useManageUsersAPI } from './data/ManageUsersAPI';

interface Props {
  userGuid: string;
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
}

export function UserReactivateDialog({
  userGuid,
  open,
  onClose,
  onUpdate,
}: Props) {
  const { reactivateUser } = useManageUsersAPI();
  const { addSnackbar } = useSnackbarStack();

  const formik = useFormikEnhanced({
    initialValues: {},
    onSubmit: (_) => reactivateUser(userGuid),
    onSubmitFailure: (error) =>
      addSnackbar(error.message, { variant: 'error' }),
    onSubmitSuccess: () => {
      onClose();
      addSnackbar('User reactivated', { variant: 'success' });
      onUpdate();
    },
  });
  return (
    <ConfirmDialog
      open={open}
      maxWidth="xs"
      fullWidth={true}
      onClose={() => (formik.isSubmitting ? undefined : onClose())}
      title="Reactivate this user?"
      cancelButtonProps={{
        children: 'Cancel',
        disabled: formik.isSubmitting,
        onClick: () => onClose(),
      }}
      confirmButtonProps={{
        children: 'Reactivate',
        isLoading: formik.isSubmitting,
        onClick: () => formik.handleSubmit(),
        autoFocus: false,
      }}
    />
  );
}
