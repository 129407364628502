import { ShipperProfileDTO } from './ShipperProfileDTO';

export const isEmptySingle = (value: unknown) => {
  if (typeof value === 'undefined' || value === null) {
    return true;
  }
  if (typeof value === 'string') {
    return value.length === 0;
  }
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  return false;
};

export const isEmpty = <T>(obj: T, keys: Array<keyof typeof obj>) => {
  return keys.every((key) => isEmptySingle(obj[key]));
};

export function cleanCargoLimits(profile: Partial<ShipperProfileDTO>) {
  const cargoLimits = profile.carrier_insurance_requirements;
  //remove cargo limit if user didn't fill limits
  if (cargoLimits?.length === 1 && cargoLimits[0]?.cargo_limit === null) {
    return {
      ...profile,
      carrier_insurance_requirements: [],
    };
  }

  return profile;
}

export function fillCargoLimits(profile: ShipperProfileDTO) {
  const limits = profile.carrier_insurance_requirements;
  const limitsLength = limits?.length ?? 0;
  return {
    ...profile,
    //append one element if requirements is empty to show first row in form
    carrier_insurance_requirements:
      limitsLength > 0 ? limits : [{ trailer_size: 1, cargo_limit: null }],
  };
}

export function validateCargoLimits(
  values: Pick<ShipperProfileDTO, 'carrier_insurance_requirements'>,
) {
  const length = values.carrier_insurance_requirements?.length || 0;
  const errors = {};

  const errorList = values.carrier_insurance_requirements?.map(
    (limit, index, requirements) => {
      if (
        limit.trailer_size &&
        (limit.trailer_size > 1 || length > 1) &&
        limit.cargo_limit === null
      ) {
        return { cargo_limit: 'Enter Cargo Limit' };
      }

      const currentCargoLimit = limit.cargo_limit || 0;
      const prevCargoLimit = requirements[index - 1]?.cargo_limit || 0;
      if (index > 0 && currentCargoLimit < prevCargoLimit) {
        return {
          cargo_limit:
            'This amount can not be less than the previous entry. Use amount that is greater than the previous entry.',
        };
      }

      return undefined;
    },
  );

  if (errorList?.filter(Boolean).length) {
    Object.assign(errors, {
      carrier_insurance_requirements: errorList,
    });
  }

  return errors;
}
