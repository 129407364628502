import { useFormikEnhanced } from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Form, FormikProvider } from 'formik';
import { useInitialValues } from 'shared/form/AppFormik';
import { camelCaseFormErrorsGetter } from 'shared/helpers/FormHelpers';
import {
  performNavigation,
  useNavigationBlock,
} from 'shared/routing/NavigationBlock';
import { LoadingContainer } from 'shared/ui/LoadingContainer';
import { YourCompanyForm } from '../core/YourCompanyForm';
import {
  useVerificationApplication,
  useVerificationApplicationAPI,
} from '../data/VerificationApplicationAPI';
import { verificationCompanyInformationSchema } from '../data/VerificationApplicationDTO';
import {
  VerificationApplicationStepsBox,
  VerificationApplicationStepsWrapper,
} from './VerificationApplicationLayout';
import { VerificationStepper } from './VerificationStepper';

interface VerificationApplicationCompanyProps {
  onSubmitSuccess: () => void;
}

export function VerificationApplicationCompany({
  onSubmitSuccess,
}: VerificationApplicationCompanyProps) {
  const { addSnackbar } = useSnackbarStack();
  const { data, isLoading } = useVerificationApplication({
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
  const { updateCompanyInformation } = useVerificationApplicationAPI();

  const initialValues = useInitialValues(
    verificationCompanyInformationSchema,
    data,
  );

  const formik = useFormikEnhanced({
    initialValues,
    validationSchema: verificationCompanyInformationSchema,
    getFormErrors: camelCaseFormErrorsGetter,
    onSubmit(values) {
      return updateCompanyInformation(values);
    },
    onSubmitSuccess() {
      performNavigation(onSubmitSuccess);
    },
    onSubmitFailure() {
      addSnackbar(
        'Please ensure all required fields are filled out correctly',
        { variant: 'error' },
      );
    },
  });

  useNavigationBlock(
    formik.status.type !== 'submitted' &&
      (formik.dirty || formik.isSubmitting) &&
      'Changes have not been saved. Leaving will result in unsaved changes being lost.',
  );

  return (
    <FormikProvider value={formik}>
      <VerificationApplicationStepsWrapper step={1}>
        <Stack space="xlarge">
          <VerificationStepper step={1} />
          {isLoading ? (
            <LoadingContainer />
          ) : (
            <Form>
              <VerificationApplicationStepsBox>
                <YourCompanyForm />
              </VerificationApplicationStepsBox>
            </Form>
          )}
        </Stack>
      </VerificationApplicationStepsWrapper>
    </FormikProvider>
  );
}
