import { Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Column, Columns, Stack, useSnackbarStack } from '@superdispatch/ui';
import { FormikProvider } from 'formik';
import { useState } from 'react';
import { useShipperProfileAPI } from '../shipper-profile/data/ShipperProfileAPI';
import { ShipperProfileDTO } from '../shipper-profile/data/ShipperProfileDTO';
import {
  SectionHeader,
  SectionSwitch,
  SuperLoadboardSettingsSection,
} from './core/SuperLoadboardSettingsSection';

export interface SuperLoadboardAutoRepostCancelledProps {
  profile: ShipperProfileDTO;
}

export function SuperLoadboardAutoRepostCancelled({
  profile,
}: SuperLoadboardAutoRepostCancelledProps) {
  const { addSnackbar } = useSnackbarStack();
  const { updateLoadboardSettings } = useShipperProfileAPI();
  const [isChecked, setIsChecked] = useState(false);
  const canUpdateProfile = true;

  const formik = useFormikEnhanced({
    initialValues: profile,
    onSubmit: (values) => {
      return updateLoadboardSettings(values);
    },
    onSubmitSuccess: (_, _updatedValues) => {
      addSnackbar('Settings updated', {
        variant: 'success',
      });
    },
    onSubmitFailure(submitError) {
      addSnackbar(submitError.message, { variant: 'error' });
    },
  });

  return (
    <SuperLoadboardSettingsSection aria-label="Auto Repost Cancelled">
      <FormikProvider value={formik}>
        <Stack space="small">
          <SectionHeader>
            <Columns align="center">
              <Column width="fluid">
                <Typography color="inherit" variant="h4">
                  Repost Cancelled Offers Automatically
                </Typography>
              </Column>
              <Column width="content">
                <SectionSwitch
                  disabled={!canUpdateProfile || formik.isSubmitting}
                  checked={isChecked}
                  onChange={() => {
                    setIsChecked(!isChecked);
                  }}
                />
              </Column>
            </Columns>
          </SectionHeader>
          <Typography color="inherit">
            Speed up your bookings and let us repost cancelled load offers. When
            carriers decline for the reasons, “Unit not Ready”, “Unknown
            Inoperable Vehicle”, or leave a note, we will not automatically
            repost.
          </Typography>
        </Stack>
      </FormikProvider>
    </SuperLoadboardSettingsSection>
  );
}
