import {
  Box,
  Checkbox,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { TableContainerProps as TableContainerPropsType } from '@material-ui/core/TableContainer/TableContainer';
import { useValueObserver } from '@superdispatch/hooks';
import { Button, CheckboxField, Color, Inline } from '@superdispatch/ui';
import { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import Protect from 'shared/data/UserPermissions';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useMap } from 'shared/helpers/ReactHelpers';
import { useQueryParams } from 'shared/helpers/RouteHelpers';
import { CarrierFullInfo } from 'shared/types/carrier';
import { LoadingContainer } from 'shared/ui/LoadingContainer';
import { StyledSorter } from 'shared/ui/Table';
import styled, { css } from 'styled-components';
import {
  ReportCarrierDrawer,
  ReportedCarrier,
} from '../core/ReportCarrierDrawer';
import { CarriersPageParamsDTO } from '../data/CarriersPageParamsDTO';
import { CarrierType, useCarriers } from '../data/ManageCarriersAPI';
import { CarriersListStatus } from './CarriersListStatus';
import { ManageCarriersPagination } from './ManageCarriersPagination';
import { ManageCarriersTableAction } from './ManageCarriersTableAction';
import { PrivateNetworkEmpty } from './PrivateNetworkEmpty';
import { RecentCarriersTableAction } from './RecentCarriersTableAction';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${Color.Silver200};
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
`;

const Label = styled(Typography)<{ emptyMessage: string }>(
  ({ emptyMessage }) => css`
    &:empty {
      color: #59616e;
      font-weight: 400;

      &:after {
        content: '${emptyMessage}';
      }
    }
  `,
);

const StyledTableCell = styled(TableCell)`
  cursor: pointer;
`;

const ActionTableCell = styled(TableCell)`
  padding: 14px 14px 14px 28px;
  background-color: ${Color.Silver200};
`;

interface CarriersTableProps {
  carrierType: CarrierType;
  TableContainerProps?: TableContainerPropsType;
  renderActions?: (
    carrierGuids: string[],
    clearSelection: () => void,
    selectedCarriers: CarrierFullInfo[],
  ) => ReactNode;
}

export function ManageCarriersTable({
  carrierType,
  renderActions,
  TableContainerProps,
}: CarriersTableProps) {
  const history = useHistory();
  const [reportCarrier, setReportCarrier] = useState<ReportedCarrier>();

  const [
    selectedCarriers,
    addSelectedCarrier,
    removeSelectedCarrier,
    clearSelectedCarriers,
  ] = useMap<CarrierFullInfo>();

  const [{ sort, page, page_size, group_guid }, setParams] = useQueryParams(
    CarriersPageParamsDTO,
    {
      page: 0,
      page_size: 20,
      sort: ['name', 'ASC'],
    },
  );

  const { data } = useCarriers({
    page,
    sort,
    size: page_size,
    type: carrierType,
    group_guid,
  });

  function handleSort(key: string, additionalKey?: string) {
    const order =
      sort[0] === key ? (sort[1] === 'ASC' ? 'DESC' : 'ASC') : 'ASC';

    if (additionalKey) {
      setParams({ page: 0, sort: [key, order, additionalKey] });
    } else {
      setParams({ page: 0, sort: [key, order] });
    }
  }

  // reset selection when tab or group or data changed
  useEffect(() => {
    clearSelectedCarriers();
  }, [carrierType, group_guid, clearSelectedCarriers, data]);

  // reset pagination
  useValueObserver(page_size, () => {
    setParams({ page: 0 });
  });

  if (!data) {
    return <LoadingContainer size={50} variant="indeterminate" />;
  }

  if (
    carrierType === 'private-network' &&
    data.pagination.total_objects === 0
  ) {
    return <PrivateNetworkEmpty />;
  }

  return (
    <Wrapper>
      <ReportCarrierDrawer
        carrier={reportCarrier}
        isOpen={!!reportCarrier}
        onClose={() => {
          setReportCarrier(undefined);
        }}
      />

      {carrierType === 'internal' && (
        <div style={{ display: 'flex', alignItems: 'center', margin: 16 }}>
          <span style={{ fontSize: 14, color: '#141C29', marginRight: 16 }}>
            Internal Carrier can not receive load offers and used only for
            record keeping.
          </span>

          <Link
            rel="noopener noreferrer"
            href="https://support.superdispatch.com/en/articles/5336978-creating-an-internal-carrier"
            target="_blank"
            variant="body2"
            style={{ marginRight: 16 }}
          >
            Learn more
          </Link>

          <Button
            color="primary"
            variant="contained"
            onClick={() => history.push('/manage-carriers/create')}
          >
            Create Internal Carrier
          </Button>
        </div>
      )}

      <TableContainer component={Paper} {...TableContainerProps}>
        <Table>
          <TableHead>
            {selectedCarriers.size > 0 ? (
              <TableRow>
                <ActionTableCell colSpan={6}>
                  <Inline>
                    <CheckboxField
                      label={`${selectedCarriers.size} selected`}
                      indeterminate={
                        data.objects.length !== selectedCarriers.size
                      }
                      checked={true}
                      onClick={() => {
                        if (data.objects.length === selectedCarriers.size) {
                          clearSelectedCarriers();
                        } else {
                          data.objects.forEach((carrier) => {
                            addSelectedCarrier(carrier.guid, carrier);
                          });
                        }
                      }}
                    />

                    {renderActions?.(
                      Array.from(selectedCarriers.keys()),
                      clearSelectedCarriers,
                      Array.from(selectedCarriers.values()),
                    )}
                  </Inline>
                </ActionTableCell>
              </TableRow>
            ) : (
              <TableRow>
                <StyledTableCell
                  onClick={() => {
                    handleSort('name');
                  }}
                >
                  <Inline verticalAlign="center">
                    Name
                    <StyledSorter
                      data-sorted={sort[0] === 'name'}
                      data-order={sort[1]}
                    />
                  </Inline>
                </StyledTableCell>
                <TableCell>Status</TableCell>
                {carrierType === 'preferred' && <TableCell>USDOT</TableCell>}
                {carrierType === 'internal' && (
                  <TableCell>Contact Name</TableCell>
                )}
                <TableCell>Email</TableCell>
                <TableCell>Phone numbers</TableCell>
                {carrierType === 'internal' && <TableCell>Fax</TableCell>}
                <StyledTableCell
                  onClick={() => {
                    handleSort('state', 'city');
                    trackEvent(
                      '[STMS] Clicked Sorting by City, State in Manage Carriers',
                    );
                  }}
                >
                  <Inline verticalAlign="center" noWrap={true}>
                    City, State
                    <StyledSorter
                      data-sorted={sort[0] === 'state'}
                      data-order={sort[1]}
                    />
                  </Inline>
                </StyledTableCell>

                {(carrierType === 'internal' || carrierType === 'recent') && (
                  <TableCell />
                )}
              </TableRow>
            )}
          </TableHead>

          <TableBody>
            {data.objects.map((carrier) => (
              <TableRow key={carrier.guid}>
                <TableCell>
                  <Inline verticalAlign="center" noWrap={true}>
                    {!!renderActions && (
                      <Checkbox
                        data-intercom="Add to Private Network Checkbox"
                        checked={selectedCarriers.has(carrier.guid)}
                        onChange={() => {
                          if (selectedCarriers.has(carrier.guid)) {
                            removeSelectedCarrier(carrier.guid);
                          } else {
                            addSelectedCarrier(carrier.guid, carrier);
                          }
                        }}
                      />
                    )}

                    <Box maxWidth="200px">
                      <Link
                        noWrap={true}
                        display="block"
                        title={carrier.name}
                        component={RouterLink}
                        to={`/manage-carriers/${carrier.guid}?utm_medium=Manage Carriers&utm_content=Carriers List`}
                      >
                        {carrier.name}
                      </Link>
                    </Box>
                  </Inline>
                </TableCell>

                <TableCell>
                  <CarriersListStatus carrier={carrier} />
                </TableCell>

                {carrierType === 'preferred' && (
                  <TableCell>
                    <Label emptyMessage="No USDOT number">
                      {carrier.us_dot}
                    </Label>
                  </TableCell>
                )}

                {carrierType === 'internal' && (
                  <TableCell>
                    <Label emptyMessage="No contact name">
                      {carrier.contact_name}
                    </Label>
                  </TableCell>
                )}

                <TableCell>
                  <Box maxWidth="250px">
                    <Label
                      noWrap={true}
                      display="block"
                      emptyMessage="No email"
                      title={carrier.email || undefined}
                    >
                      {carrier.email}
                    </Label>
                  </Box>
                </TableCell>

                <TableCell>
                  <Label emptyMessage="No phone number">
                    {carrier.phone_numbers}
                  </Label>
                </TableCell>

                {carrierType === 'internal' && (
                  <TableCell>
                    <Label emptyMessage="No fax">{carrier.fax}</Label>
                  </TableCell>
                )}

                <TableCell>
                  <Label emptyMessage="No address">
                    {carrier.city &&
                      carrier.state &&
                      `${carrier.city}, ${carrier.state}`}
                  </Label>
                </TableCell>

                {carrierType === 'recent' && (
                  <TableCell>
                    <Protect
                      entity="UPDATE_CARRIER_PROFILE_FOR_BROKER"
                      permission="canExecute"
                    >
                      <RecentCarriersTableAction
                        carrier={carrier}
                        onCarrierReport={() => {
                          setReportCarrier({
                            name: carrier.name,
                            guid: carrier.guid,
                          });
                        }}
                      />
                    </Protect>
                  </TableCell>
                )}
                {carrierType === 'internal' && (
                  <TableCell>
                    <ManageCarriersTableAction carrier={carrier} />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {data.pagination.total_pages > 0 && (
        <ManageCarriersPagination
          pagination={data.pagination}
          onPageChange={(value) => {
            setParams({ page: value });
          }}
          onSizeChange={(size) => {
            setParams({ page: 0, page_size: size });
          }}
        />
      )}
    </Wrapper>
  );
}
