import { Button, CheckboxField, Inline } from '@superdispatch/ui';
import { SidebarContent } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { useCustomerRouteHelpers } from '../CustomersRouteHelpers';
import { CustomerCreateForm } from './CustomerCreateForm';

export function CustomerCreatePage() {
  const { goToCustomerDetails } = useCustomerRouteHelpers();

  const [{ isSubmitting }, setState] = useState({ isSubmitting: false });
  const [showRequiredFields, setShowRequiredFields] = useState(false);

  return (
    <SidebarContent
      title="New Customer"
      openOnMount={true}
      onBack={() => {
        goToCustomerDetails();
      }}
      action={
        <Inline>
          <CheckboxField
            label="Only show required fields"
            checked={showRequiredFields}
            onChange={(_, checked) => setShowRequiredFields(checked)}
          />

          <Button
            color="primary"
            variant="contained"
            type="submit"
            form="customers-create-form"
            isLoading={isSubmitting}
          >
            Save
          </Button>
        </Inline>
      }
    >
      <CustomerCreateForm
        onStateChange={setState}
        showRequiredFieldsOnly={showRequiredFields}
        onSubmitSuccess={(createdCustomer) => {
          goToCustomerDetails(createdCustomer.id);
        }}
      />
    </SidebarContent>
  );
}
