import { Box, Typography } from '@material-ui/core';
import { SidebarContent } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { ExecutePermission } from 'shared/data/UserPermissions';
import { CustomerDTO } from 'shared/dto/CustomerDTO';
import { APIErrorMessage } from 'shared/errors/APIErrorMessage';
import { useQueryParams } from 'shared/helpers/RouteHelpers';
import styled, { css } from 'styled-components';
import { useCustomerRouteHelpers } from '../CustomersRouteHelpers';
import { useCustomer } from '../data/CustomerAPI';
import { CustomerPageParamsDTO } from '../data/CustomersPageParamsDTO';
import { CustomerDeleteConfirmationForm } from './CustomerDeleteConfirmationForm';
import { CustomerDetails } from './CustomerDetails';
import { CustomerDetailsLoading } from './CustomerDetailsLoading';

const Wrapper = styled.div(({ theme }) => {
  return css`
    padding: 16px;

    ${theme.breakpoints.down('sm')} {
      padding-right: 0;
      padding-left: 0;
    }
  `;
});

interface Props {
  customers: CustomerDTO[];
}

export function CustomerDetailsPage({ customers }: Props) {
  const { goToCustomerDetails } = useCustomerRouteHelpers();
  const [{ current_customer_id, current_customer_guid }, setParams] =
    useQueryParams(CustomerPageParamsDTO);

  const {
    error,
    isLoading,
    data: customer,
  } = useCustomer({
    id: current_customer_id,
    guid: current_customer_guid,
  });

  useEffect(() => {
    if (current_customer_guid && customer) {
      setParams({
        current_customer_guid: undefined,
        current_customer_id: customer.id,
      });
    }
  }, [setParams, current_customer_guid, customer]);

  useEffect(() => {
    if (!customer && !isLoading && customers[0]) {
      goToCustomerDetails(customers[0].id, true);
    }
  }, [goToCustomerDetails, customer, customers, isLoading]);

  if (error) {
    return (
      <Box flex="1" display="flex" alignItems="center" justifyContent="center">
        <Typography variant="h2">
          <APIErrorMessage error={error} />
        </Typography>
      </Box>
    );
  }

  if (!customer && !isLoading) {
    return (
      <Box flex="1" display="flex" alignItems="center" justifyContent="center">
        <Typography variant="h2">No customer selected</Typography>
      </Box>
    );
  }

  if (!customer) {
    return <CustomerDetailsLoading />;
  }

  return (
    <SidebarContent
      dense={true}
      title={customer.name}
      action={
        <ExecutePermission entity="CUSTOMER_MANAGEMENT">
          <CustomerDeleteConfirmationForm
            id={current_customer_id}
            onDeleteSuccess={() => {
              goToCustomerDetails();
            }}
          />
        </ExecutePermission>
      }
    >
      <Wrapper>
        <CustomerDetails customer={customer} />
      </Wrapper>
    </SidebarContent>
  );
}
