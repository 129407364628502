import { Redirect, Route, RouteProps, useLocation } from 'react-router';
import { usePermission } from 'shared/data/UserPermissions';
import { useSuperPaySettings } from './data/SuperPayAPI';
import { OnBoardingStatus } from './data/SuperPayDTO';

export const ROUTES_SUPERPAY = {
  main: '/superpay',
  businessDetails: '/superpay/business-details',
  owners: '/superpay/owners',
  bankAccount: '/superpay/bank-account',
  bankDetails: '/superpay/bank-details',
  bankVerification: '/superpay/bank-details/verify-bank-account',
};

const getPath = (
  status?: OnBoardingStatus | null,
  canManageSuperPay = true,
): string[] => {
  if (!canManageSuperPay) {
    switch (status) {
      case 'PENDING':
      case 'COMPLETED':
        return [ROUTES_SUPERPAY.bankDetails];
      default:
        return [ROUTES_SUPERPAY.main];
    }
  }

  switch (status) {
    case 'STARTED':
      return [ROUTES_SUPERPAY.owners];
    case 'OWNERS_PROVIDED':
      return [ROUTES_SUPERPAY.bankAccount];
    case 'PENDING':
    case 'COMPLETED':
      return [ROUTES_SUPERPAY.bankDetails];
    default:
      return [ROUTES_SUPERPAY.main, ROUTES_SUPERPAY.businessDetails];
  }
};

export function SuperPayRoute({ children, ...props }: RouteProps) {
  const { search } = useLocation();
  const { path } = props;
  const { data, isLoading } = useSuperPaySettings();
  const canManageSuperPay = usePermission('MANAGE_SUPERPAY', 'canExecute');
  const pathsFromStatus = getPath(data?.on_boarding_status, canManageSuperPay);
  const isAvailable = pathsFromStatus.some((statusPath) => statusPath === path);

  return (
    <Route
      {...props}
      render={() =>
        isLoading ? null : !isAvailable && pathsFromStatus[0] ? (
          <Redirect
            to={{
              pathname: pathsFromStatus[0],
              search,
            }}
          />
        ) : (
          children
        )
      }
    />
  );
}
