import { SidebarContent } from '@superdispatch/ui-lab';
import styled from 'styled-components';
import { TerminalsDropZone } from './list/TerminalsDropZone';
import { useTerminalRouteHelpers } from './TerminalsRouteHelpers';

const Wrapper = styled.div`
  padding-top: 96px;
  display: flex;
  justify-content: center;
`;

export function TerminalsImportPage() {
  const { goToTerminalDetails } = useTerminalRouteHelpers();

  return (
    <SidebarContent
      title="Import Terminals"
      openOnMount={true}
      onBack={() => goToTerminalDetails()}
    >
      <Wrapper>
        <TerminalsDropZone />
      </Wrapper>
    </SidebarContent>
  );
}
